import {LatLng, divIcon} from 'leaflet'
import {
  Circle,
  FeatureGroup,
  LayersControl,
  MapContainer,
  Marker,
  Popup,
  SVGOverlay,
  TileLayer,
  useMapEvents,
} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import {QueryResponseProvider, useQueryResponse} from './core/MapQueryResponseProvider'
import {useMemo} from 'react'
import {FormattedNumber, IntlProvider} from 'react-intl'

const bounds = [
  new LatLng(-6.937332868878443, 107.55615234375001).toBounds(100000),
  new LatLng(-8.581021215641842, 115.22460937500001).toBounds(100000),
  new LatLng(-6.18424616128059, 106.78710937500001).toBounds(100000),
  new LatLng(3.5572827265412794, 98.61328125).toBounds(100000),
]

const svgIcon = ({color}) =>
  divIcon({
    html: `
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill="${color}">
      <path d="M480-480q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Zm0 400Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Z"/>
    </svg>
    `,
    iconSize: [10, 10],
    // iconAnchor: [5, 0],
  })

const Clicker = () => {
  const map = useMapEvents({
    click(e) {
      console.log(e)
      // console.log(`${e.latlng.lat}, ${e.latlng.lng}`)
    },
  })
  return null
}

const Maps = () => {
  const {response} = useQueryResponse()

  const data = useMemo(() => {
    return response?.response?.data
  }, [response])

  return (
    <>
      <div className='row'>
        <div className='col-12' style={{height: '500px'}}>
          <MapContainer center={[-1.143, 118.318]} zoom={5} className='w-100'>
            <LayersControl position='topright'>
              <LayersControl.BaseLayer checked name='OpenStreetMap'>
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name='Google Maps'>
                <TileLayer
                  attribution='Google'
                  url='https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}'
                />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name='Google Earth'>
                <TileLayer
                  attribution='Google'
                  url='http://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}'
                />
              </LayersControl.BaseLayer>
            </LayersControl>

            {/* <Clicker /> */}
            {(data || []).map((bound) => (
              <Marker
                key={bound.outlet_guid}
                position={[
                  parseFloat(bound.outlet_latitude ?? '0.0'),
                  parseFloat(bound.outlet_longitude ?? ''),
                ]}
                icon={svgIcon({color: bound.sales_rate?.color?.hex ?? ''}) as any}
              >
                <Popup>
                  <div
                    className='row text-start'
                    style={{minWidth: '400px', position: 'relative', overflow: 'hidden'}}
                  >
                    <div className='col-12 mb-3'>
                      <h3>{bound?.outlet_name}</h3>
                    </div>
                    <span className='col-4'>Total Revenue</span>
                    <span className='col-8'>
                      <>
                        Rp.{' '}
                        <IntlProvider locale='id'>
                          <FormattedNumber value={bound?.total_sales || 0} />
                        </IntlProvider>{' '}
                      </>
                    </span>
                    <span className='col-4'>Total Treatment</span>
                    <span className='col-8'>{bound.total_quantity}</span>
                    <span className='col-4'>Revenue Target</span>
                    <span className='col-8'>
                      <>
                        Rp.{' '}
                        <IntlProvider locale='id'>
                          <FormattedNumber value={bound?.sales_target || 0} />
                        </IntlProvider>{' '}
                      </>
                    </span>
                    <span className='col-4'>Treatment Target</span>
                    <span className='col-8'>{bound.quantity_target}</span>
                  </div>
                </Popup>
              </Marker>
            ))}
          </MapContainer>
        </div>
      </div>
    </>
  )
}

const MapsWrapper = () => {
  return (
    <QueryResponseProvider>
      <Maps />
    </QueryResponseProvider>
  )
}

export default MapsWrapper
