import {toAbsoluteUrl} from '@/_metronic/helpers'
import clsx from 'clsx'
import {FC, FocusEventHandler, ReactNode} from 'react'
import SVG from 'react-inlinesvg'
import Select, {GroupBase, Options, OptionsOrGroups, components} from 'react-select'
import Creatable from 'react-select/creatable'
import {CrossIcon} from 'react-select/dist/declarations/src/components/indicators'

type Props = {
  options?: OptionsOrGroups<unknown, GroupBase<unknown>> | undefined
  onChange?: any
  placeholder?: ReactNode
  isMulti?: any
  isCreatable?: boolean
  onCreateOption?: (inputValue: string) => void | undefined
  value?: any
  isOptionDisabled?: (option: any, selectValue: Options<any>) => boolean
  isClearable?: boolean
  isDisabled?: boolean
  onBlur?: FocusEventHandler<HTMLInputElement>
  isValid?: boolean
  errorMessage?: string
  defaultValue?: any
  className?: string | undefined
  icon?: string
}

const DropdownIconSelect: FC<Props> = ({
  options,
  onChange,
  placeholder,
  isMulti,
  isCreatable = false,
  onCreateOption,
  value,
  isOptionDisabled,
  isClearable,
  isDisabled,
  onBlur,
  isValid,
  errorMessage,
  defaultValue,
  className,
  icon,
}) => {
  if (isCreatable) {
    return (
      <>
        <div className='col-md-12 position-relative container-fluid'>
          {icon && (
            <SVG
              src={toAbsoluteUrl(icon)}
              className='position-absolute translate-middle-y top-50 start-0 me-3 ms-3'
            />
          )}
          <Creatable
            components={{
              IndicatorSeparator: null,
              Control: (props) => (
                <components.Control
                  {...props}
                  className={clsx(props.className, 'custom-dropdown-control')}
                />
              ),
              Input: (props) => (
                <components.Input
                  {...props}
                  className={clsx(props.className, 'custom-dropdown-input')}
                />
              ),
            }}
            // onBlur={onBlur}
            isMulti={isMulti}
            options={options}
            onChange={onChange}
            onCreateOption={onCreateOption}
            placeholder={
              <span className='custom-dropdown-placeholder fw-semibold'>{placeholder}</span>
            }
            value={value}
            isOptionDisabled={isOptionDisabled}
            isClearable={isClearable}
            isDisabled={isDisabled}
            className={className}
          />
        </div>
      </>
    )
  }

  return (
    <>
      <div className='col-md-12 position-relative custom-dropdown-control-no-border'>
        {/* {icon && (
          <SVG
            src={toAbsoluteUrl(icon)}
            className='position-absolute translate-middle-y top-50 start-0 me-3 ms-3'
          />
        )} */}
        <Select
          styles={{
            valueContainer: (provided) => ({
              ...provided,
              paddingLeft: 36,
            }),
          }}
          components={{
            IndicatorSeparator: null,
            Control: (props) => (
              <components.Control
                {...props}
                className={clsx(props.className, 'custom-dropdown-control')}
              />
            ),
            Input: (props) => (
              <components.Input
                {...props}
                className={clsx(props.className, 'custom-dropdown-input')}
              />
            ),
            ValueContainer: (props) => (
              <components.ValueContainer
                {...props}
                children={
                  <>
                    {props.children && (
                      <SVG
                        src={toAbsoluteUrl(icon || '')}
                        className='position-absolute translate-middle-y top-50 start-0 me-3 ms-3'
                      />
                    )}{' '}
                    {props.children}
                  </>
                }
              />
            ),
          }}
          className={className}
          isMulti={isMulti}
          defaultValue={defaultValue}
          options={options}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={
            <span className='custom-dropdown-placeholder fw-semibold'>{placeholder}</span>
          }
          value={value}
          isClearable={isClearable}
          isDisabled={isDisabled}
        />
      </div>
      {!isValid && errorMessage && <span className='text-danger mt-2'>{errorMessage}</span>}
    </>
  )
}

export {DropdownIconSelect}
