import {axios} from '@/_metronic/helpers/network/Axios'
import {AxiosResponse} from 'axios'
import {CreateRatingResponse} from './_models'

const BOOKING_URL = '/booking-services'
const CREATE_RATING_URL = `${BOOKING_URL}/booking/rating`

const createRating = (body: any): Promise<CreateRatingResponse> => {
  return axios
    .post(CREATE_RATING_URL, body)
    .then((d: AxiosResponse<CreateRatingResponse>) => d.data)
}

export {createRating}
