import {DOTS, usePagination} from '@/app/utils/hooks/usePagination'
import {FC} from 'react'
import {Pagination} from 'react-bootstrap'

type Props = {
  totalCount: number
  siblingCount?: number
  currentPage: number
  pageSize: number
  onPageChange: (page: number) => void
}

const CustomPagination: FC<Props> = ({
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  onPageChange,
}) => {
  const paginationRange = usePagination({currentPage, totalCount, siblingCount, pageSize})
  let lastPage = paginationRange?.[paginationRange.length - 1]
  let firstPage = paginationRange?.[0]

  return (
    <Pagination>
      <>
        <Pagination.Prev
          disabled={currentPage === firstPage}
          onClick={() => onPageChange(currentPage - 1)}
        />
        {paginationRange?.map((pageNum: any) => {
          if (pageNum === DOTS) {
            return <Pagination.Ellipsis />
          }

          return (
            <Pagination.Item active={pageNum === currentPage} onClick={() => onPageChange(pageNum)}>
              {pageNum}
            </Pagination.Item>
          )
        })}
        <Pagination.Next
          disabled={currentPage === lastPage}
          onClick={() => onPageChange(currentPage + 1)}
        />
      </>
    </Pagination>
  )
}

export default CustomPagination
