import { WithChildren } from '@/_metronic/helpers'
import { createContext, FC, useContext, useState } from 'react'

type FilterReportBookingTypeContextProps = {
  showFilterReportBookingTypeModal: boolean
  filterReportBookingTypeFormValue: any
  updateFilterReportBookingTypeFormValue: (formValue: any) => void
  handleShowModal: () => void
  closeModal: () => void
}

const filterReportBookingTypeContextInitial: FilterReportBookingTypeContextProps = {
  showFilterReportBookingTypeModal: false,
  filterReportBookingTypeFormValue: null,
  updateFilterReportBookingTypeFormValue: () => {},
  handleShowModal: () => {},
  closeModal: () => {},
}

const FilterReportBookingTypeContext = createContext<FilterReportBookingTypeContextProps>(
  filterReportBookingTypeContextInitial
)

const FilterReportBookingTypeProvider: FC<WithChildren> = ({children}) => {
  const [showFilterReportBookingTypeModal, setShowFilterReportBookingTypeModal] =
    useState<boolean>(false)
  const [filterReportBookingTypeFormValue, setFilterReportBookingTypeFormValue] =
    useState<any>(null)

  const updateFilterReportBookingTypeFormValue = (newValue) => {
    setFilterReportBookingTypeFormValue(newValue)
  }

  const handleShowModal = () => {
    setShowFilterReportBookingTypeModal(true)
  }

  const closeModal = () => {
    setShowFilterReportBookingTypeModal(false)
  }

  return (
    <FilterReportBookingTypeContext.Provider
      value={{
        showFilterReportBookingTypeModal,
        filterReportBookingTypeFormValue,
        updateFilterReportBookingTypeFormValue,
        handleShowModal,
        closeModal,
      }}
    >
      {children}
    </FilterReportBookingTypeContext.Provider>
  )
}

const useFilterReportBookingType = () => useContext(FilterReportBookingTypeContext)

export { FilterReportBookingTypeProvider, useFilterReportBookingType }

