import {BaseResponse} from '@/_metronic/helpers/crud-helper/base-list-response'
import {BaseRequest} from '@/_metronic/helpers/crud-helper/base-request'

export type ProductPrice = {
  sku?: string
  price?: Price[]
  stock?: Stock[]
  width?: number
  height?: number
  length?: number
  weight?: number
  barcode?: string
  product?: Product
  variant?: Variant[]
  created_at?: Date
  created_by?: string
  updated_at?: null
  updated_by?: null
  qty?: number
}

export type Price = {
  id?: string
  hpp?: number
  price?: number
  outlet?: Outlet
  discount?: number
  hpp_before?: number
  sell_price?: number
  price_before?: number
  discount_type?: string
  minimal_stock?: number
}

export type Outlet = {
  id?: string
  area?: string
  name?: string
  address?: string
  outlet_code?: string
}

export type Product = {
  id?: string
  name?: string
  tags?: string[]
  category?: Category
  collection?: Collection[]
  images_url?: any[]
  description?: string
  code_product?: string
  image_thumbnail_url?: string
  brand_partnership_id?: null
}

export type Category = {
  id?: string
  name?: string
  created_at?: Date
  created_by?: string
  updated_at?: Date
  updated_by?: string
  description?: string
  order_number?: number
  code_category?: string
  image_thumbnail_url?: string
}

export type Collection = {
  id?: string
  name?: string
  id_mapping?: number
  description?: string
  code_collection?: string
  image_thumbnail_url?: null
}

export type Stock = {
  outlet?: Outlet
  current_stock?: number
  product_variant_sku?: string
}

export type Variant = {
  type?: string
  variant?: string
  product_variant_id?: string
}

// Product Variant Mobile
export type ProductVariantMobile = {
  sku?: string
  price?: Price[]
  stock?: Stock[]
  qty?: number
  width?: number
  height?: number
  length?: number
  weight?: number
  barcode?: string
  product?: Product
  variant?: Variant[]
  created_at?: Date
  created_by?: string
  updated_at?: null
  updated_by?: null
}

export type FilterProductPrice = {
  set_product_variant_sku?: boolean
  product_variant_sku?: string
  set_outlet_id?: boolean
  outlet_id?: string
}

export type ProductTreatmentRequest = {
  filter?: FilterProductTreatment
  is_active_price?: boolean
  is_all_data?: boolean
  limit?: number
  page?: number
  order?: string
  sort?: any
}

export type FilterProductTreatment = {
  set_product_id?: boolean
  product_id?: string
  set_outlet_id?: boolean
  outlet_id?: string
  set_sku?: boolean
  sku?: string
  set_barcode?: boolean
  barcode?: string
  set_type?: boolean
  type?: string
  set_variant?: boolean
  variant?: string
  set_category_id?: boolean
  category_id?: string
  set_name?: boolean
  name?: string
  set_is_offline?: boolean
  is_offline?: boolean
  set_is_online?: boolean
  is_online?: boolean
}

export type FilterProductVariantMobile = {
  set_product_id?: boolean
  product_id?: string
  set_outlet_id?: boolean
  outlet_id?: string
  set_sku?: boolean
  sku?: string
  set_barcode?: boolean
  barcode?: string
  set_type?: boolean
  type?: string
  set_variant?: boolean
  variant?: string
  set_is_online?: boolean
  is_online?: boolean
  set_product_category_id?: boolean
  product_category_id?: string
}

interface BaseRequestVariantMobile<T> extends BaseRequest<T> {
  is_all_data?: boolean
  is_active_price?: boolean
}

export const initialFilter: BaseRequest<FilterProductPrice> = {
  filter: {
    set_product_variant_sku: false,
    product_variant_sku: '',
    set_outlet_id: false,
    outlet_id: '',
  },
  limit: 10,
  page: 1,
  order: 'created_at',
  sort: 'DESC',
}

export type ProductVariantMobileResponse = BaseResponse<Array<ProductVariantMobile>>
export type FilterVariantMobile = BaseRequestVariantMobile<FilterProductVariantMobile>

export const initialFilterProductVariantMobile: FilterVariantMobile = {
  filter: {
    set_product_id: false,
    product_id: '',
    set_outlet_id: false,
    outlet_id: '67acde85-e1d1-49ba-92a0-44cafa32f12',
    set_sku: false,
    sku: '',
    set_barcode: false,
    barcode: '',
    set_type: false,
    type: '',
    set_variant: false,
    variant: '',
    set_is_online: true,
    is_online: true,
    set_product_category_id: false,
    product_category_id: '',
  },
  is_all_data: true,
  is_active_price: true,
  limit: 10,
  page: 1,
  order: 'created_at',
  sort: 'ASC',
}
