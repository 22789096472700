// @ts-nocheck
import {Column} from 'react-table'
import {UserCustomHeader} from './UserCustomHeader'
import {User} from '../../core/_models'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Brand' className='min-w-125px' />,
    id: 'brand',
    accessor: 'brand',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Sub Brand' className='min-w-125px' />
    ),
    id: 'sub_brand',
    accessor: 'sub_brand',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Outlet' className='min-w-125px' />
    ),
    id: 'outlet',
    accessor: 'outlet',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Period' className='min-w-125px' />
    ),
    accessor: 'period',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Year' className='min-w-125px' />
    ),
    accessor: 'year',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Revenue' className='min-w-125px' />
    ),
    accessor: 'revenue',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Taxes' className='min-w-125px' />
    ),
    accessor: 'taxes',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Services' className='min-w-125px' />
    ),
    accessor: 'services',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Revenue after Taxes Services' className='min-w-125px' />
    ),
    accessor: 'revenue_after_taxes',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Revenue Share to Alaya' className='min-w-125px' />
    ),
    accessor: 'revenue_share_alaya',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Revenue Share to Hotel' className='min-w-125px' />
    ),
    accessor: 'revenue_share_hotel',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Service Charge to Alaya' className='min-w-125px' />
    ),
    accessor: 'service_charge_alaya',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Service Charge to Hotel' className='min-w-125px' />
    ),
    accessor: 'service_charge_hotel',
  },
]

export {usersColumns}
