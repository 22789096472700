import { WithChildren } from '@/_metronic/helpers'
import { createContext, FC, useContext, useState } from 'react'

type FilterReportRevenueSharingContextProps = {
  showFilterReportRevenueSharingModal: boolean
  filterReportRevenueSharingFormValue: any
  updateFilterReportRevenueSharingFormValue: (formValue: any) => void
  handleShowModal: () => void
  closeModal: () => void
}

const filterReportRevenueSharingContextInitial: FilterReportRevenueSharingContextProps = {
  showFilterReportRevenueSharingModal: false,
  filterReportRevenueSharingFormValue: null,
  updateFilterReportRevenueSharingFormValue: () => {},
  handleShowModal: () => {},
  closeModal: () => {},
}

const FilterReportRevenueSharingContext = createContext<FilterReportRevenueSharingContextProps>(
  filterReportRevenueSharingContextInitial
)

const FilterReportRevenueSharingProvider: FC<WithChildren> = ({children}) => {
  const [showFilterReportRevenueSharingModal, setShowFilterReportRevenueSharingModal] =
    useState<boolean>(false)
  const [filterReportRevenueSharingFormValue, setFilterReportRevenueSharingFormValue] =
    useState<any>(null)

  const updateFilterReportRevenueSharingFormValue = (newValue) => {
    setFilterReportRevenueSharingFormValue(newValue)
  }

  const handleShowModal = () => {
    setShowFilterReportRevenueSharingModal(true)
  }

  const closeModal = () => {
    setShowFilterReportRevenueSharingModal(false)
  }

  return (
    <FilterReportRevenueSharingContext.Provider
      value={{
        showFilterReportRevenueSharingModal,
        filterReportRevenueSharingFormValue,
        updateFilterReportRevenueSharingFormValue,
        handleShowModal,
        closeModal,
      }}
    >
      {children}
    </FilterReportRevenueSharingContext.Provider>
  )
}

const useFilterReportRevenueSharing = () => useContext(FilterReportRevenueSharingContext)

export { FilterReportRevenueSharingProvider, useFilterReportRevenueSharing }

