import { axios } from '@/_metronic/helpers/network/Axios'
import { AxiosResponse } from 'axios'
import { FilterReportSalesBudget, ReportSalesBudgetQueryResponse } from './_models'

const REPORT_API_URL = '/report-services'
const GET_REPORT_SALES_VS_BUDGET_LIST = '/dashboard/sales-vs-budget'

const getReportSalesBudget = (
  body: FilterReportSalesBudget
): Promise<ReportSalesBudgetQueryResponse> => {
  return axios
    .post(`${REPORT_API_URL}${GET_REPORT_SALES_VS_BUDGET_LIST}`, body)
    .then((d: AxiosResponse<ReportSalesBudgetQueryResponse>) => d.data)
}

export { getReportSalesBudget }

