import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {PartnerEditWrapper} from './partners-edit/PartnerEdit'
import {PartnerListWrapper} from './partners-list/PartnerList'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Hotel',
    path: '/apps/partner-organization/partner/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const addUsersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Hotel',
    path: '/apps/partner-organization/partner/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Hotel List',
    path: '/apps/partner-organization/partner/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const PartnerOrganizationPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/partner/list'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Hotel List</PageTitle>
              <PartnerListWrapper />
            </>
          }
        />
        <Route
          path='/partner/add'
          element={
            <>
              <PageTitle breadcrumbs={addUsersBreadcrumbs}>Add Hotel</PageTitle>
              <PartnerEditWrapper />
            </>
          }
        />
        <Route
          path='/partner/edit/:id'
          element={
            <>
              <PageTitle breadcrumbs={addUsersBreadcrumbs}>Edit Hotel</PageTitle>
              <PartnerEditWrapper />
            </>
          }
        />
        <Route
          path='/partner/detail/:id'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Detail Hotel</PageTitle>
              <PartnerEditWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/partner-organization/partner/list' />} />
    </Routes>
  )
}

export default PartnerOrganizationPage
