/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon} from '../../../../../_metronic/helpers'

type Props = {
  className: string
  color: string
  svgIcon: string
  iconColor: string
  title: string
  titleColor?: string
  description: string
  descriptionColor?: string
}

const InventoryAsset: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor,
}) => {
  return (
    <a href='#' className={`card shadow-wit bg-${color} hoverable ${className}`}>
      <div className='card-body d-flex flex-row gap-3'>
        <KTIcon iconName={svgIcon} className={`text-${iconColor} fs-2x ms-n1`} />

        <div>
          <div className={`text-${titleColor} fw-bold fs-3 mb-2`}>{title}</div>

          <div className={`fw-semibold text-${descriptionColor}`}>
            {description}
            {/* <span className='ms-4 badge badge-light-success fs-base'>
            <i className='ki-duotone ki-black-up fs-7 text-success ms-n1'></i> 4.5%
          </span> */}
          </div>
        </div>
      </div>
    </a>
  )
}

export {InventoryAsset}
