import {QUERIES, WithChildren, WITPaginationState} from '@/_metronic/helpers'
import {BaseResponse} from '@/_metronic/helpers/crud-helper/base-list-response'
import {createContext, FC, useContext, useEffect, useMemo, useState} from 'react'
import {useQuery} from 'react-query'
import {FilterReportSalesBudget, initialFilter} from '../../dashboard-forecast/core/_models'
import {useQueryRequestMonthly} from '../../dashboard-forecast/core/QueryRequestMonthlyProvider'
import {DashboardSalesBudgetMap} from './_model'
import {getReportSalesBudgetMap} from './_requests'

/**
 * Begin: Initial Values
 */
export const initialQueryResponse = {refetch: () => {}, isLoading: false, request: initialFilter}

/**
 * Begin: Response Context
 *
 * It used for context response wrapper, so the response can
 * called everywhere, as long as it wrapped inside the context
 */
type ProductQueryResponseContextProps = {
  response?: BaseResponse<Array<DashboardSalesBudgetMap>> | undefined
  refetch: () => void
  isLoading: boolean
  request: FilterReportSalesBudget
}

function createResponseContext<T>(initialState: ProductQueryResponseContextProps) {
  return createContext(initialState)
}

const QueryResponseContext =
  createResponseContext<Array<DashboardSalesBudgetMap>>(initialQueryResponse)

/**
 * Begin: The Provider
 */
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useQueryRequestMonthly()
  const [request, setRequest] = useState<FilterReportSalesBudget>(state)
  const updatedQuery = useMemo(() => state, [state])

  useEffect(() => {
    if (request !== updatedQuery) {
      setRequest(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.REPORT_SALES_VS_BUDGET_MAP}-${JSON.stringify(request)}`,
    () => {
      return getReportSalesBudgetMap(request)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, request}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  if (!response) {
    return null
  }

  return response?.response?.data || null
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: WITPaginationState = {
    page: 1,
    pageSize: 10,
    totalCount: 1,
  }

  const {response} = useQueryResponse()
  if (!response || !response.response) {
    return defaultPaginationState
  }
  const res = response.response
  return {
    page: res.current_page,
    pageSize: res.limit,
    totalCount: res.total_data,
  } as WITPaginationState
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
}
