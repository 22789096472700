import { DetailResponseProvider } from '../core/DetailResponseProvider'
import { ListViewProvider } from '../core/ListViewProvider'
import { QueryRequestProvider } from '../core/QueryRequestProvider'
import { QueryResponseProvider } from '../core/QueryResponseProvider'
import { PartnerEditBody } from './components/body/PartnerEditBody'

const PartnerEdit = () => {
  return (
    <>
      <PartnerEditBody/>
    </>
  )
}

const PartnerEditWrapper = () => (
  <DetailResponseProvider>
    <PartnerEdit />
  </DetailResponseProvider>
)

export { PartnerEditWrapper }
