import { BaseRequest } from '@/_metronic/helpers/crud-helper/base-request'
import { FilterProvince, ProvinceResponse } from './_models'
import { axios } from '@/_metronic/helpers/network/Axios'
import { AxiosResponse } from 'axios'

const OUTLET_API_URL = '/outlet-services'
const GET_PROVINCE = '/region/province'

const getProvince = (body: BaseRequest<FilterProvince>): Promise<ProvinceResponse> => {
  return axios
    .post(`${OUTLET_API_URL}${GET_PROVINCE}`, body)
    .then((d: AxiosResponse<ProvinceResponse>) => d.data)
}

export { getProvince }

