import { BaseRequest } from '@/_metronic/helpers/crud-helper/base-request'
import { axios } from '@/_metronic/helpers/network/Axios'
import { AxiosResponse } from 'axios'
import { CityResponse, FilterCity } from './_models'

const OUTLET_API_URL = '/outlet-services'
const GET_CITY = '/region/city'

const getCity = (body: BaseRequest<FilterCity>): Promise<CityResponse> => {
  return axios
    .post(`${OUTLET_API_URL}${GET_CITY}`, body)
    .then((d: AxiosResponse<CityResponse>) => d.data)
}

export { getCity }

