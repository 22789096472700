import { Link } from 'react-router-dom'
import { KTIcon } from '../../../../../../_metronic/helpers'
import { PartnersListFilter } from './PartnersListFilter'

const PartnersListToolbar = () => {

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <PartnersListFilter />

      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTIcon iconName='exit-up' className='fs-2' />
        Export
      </button> */}
      {/* end::Export */}

      {/* begin::Add user */}
      <Link to='/apps/partner-organization/partner/add' className='link-primary'>
        <button type='button' className='btn btn-primary'>
          <KTIcon iconName='plus' className='fs-2' />
          Add Hotel
        </button>
      </Link>
      {/* end::Add user */}
    </div>
  )
}

export { PartnersListToolbar }
