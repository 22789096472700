const QUERIES = {
  USERS_LIST: 'users-list',
  PRODUCT_LIST: 'products-list',
  SUPPLIER_LIST: 'supplier-list',
  REPORT_TREND_ANALYSIS: 'report-trend-analysis-list',
  REPORT_SALES_VS_BUDGET: 'report-sales-vs-budget-list',
  REPORT_SALES_VS_BUDGET_MAP: 'report-sales-vs-budget-map',
  REVENUE_STREAM_OUTLET: 'revenue-stream-outlet-list',
  REVENUE_STREAM_CITY: 'revenue-stream-city-list',
  REVENUE_STREAM_AREA: 'revenue-stream-area-list',
  REPORT_RUSH_HOUR: 'report-rush-hour-list',
  TRANSFER_LIST: 'transfer-list',
  STORE_REQUEST_TRANSFER_LIST: 'store-request-transfer-list',
  STORE_REQUEST_LIST: 'store-request-list',
  STORE_REQUEST_RECEIVE_LIST: 'store-request-receive-list',
  STORE_REQUEST_APPROVAL_LIST: 'store-request-approval-list',
  TRANSACTION_HISTORY_LIST: 'transaction-history-list',
  PRODUCT_CATEGORY_LIST: 'product-categories-list',
  PRODUCT_COLLECTION_LIST: 'product-categories-list',
  RAW_MATERIAL_LIST: 'raw-material-list',
  EMPLOYEE_LIST: 'employee-list',
  CONTRACT_EMPLOYEE_LIST: 'contract-employee-list',
  OUTLET_LIST: 'outlets-list',
  OUTLET_CONTRACT_LIST: 'outlet-contract-list',
  ASSISTANCE_THERAPIST_LIST: 'assistance-therapist-list',
  ATTENDANCE_REPORT_MONTHLY: 'attendance-report-monthly',
  ATTENDANCE_REPORT_DAILY: 'attendance-report-daily',
  CUSTOMER_LIST: 'customer-list',
  PARTNER_ORGANIZER_LIST: 'partner-organizer-list',
  FORECAST_TARGET_SALES: 'forecast-target-sales-list',
  CMS_LIST: 'cms-list',
  ORDER_LIST: 'order-list',
  MAPPING_OUTLET_PIC_LIST: 'mapping-outlet-list',
  APPROVAL_CONFIGURATION_LIST: 'mapping-outlet-list',
  RAW_MATERIAL_STOCK_LIST: 'raw-material-stock-list',
  PURCHASE_ORDER_LIST: 'purchase-order-list',
  PRODUCT_PRICE_LIST: 'product-price-list',
  RAW_MATERIAL_PRICE_LIST: 'raw-material-price-list',
  PRODUCT_STOCK_LIST: 'product-stock-list',
  PURCHASE_ORDER_APPROVAL_LIST: 'purchase-order-approval-list',
  RECEIVE_PURCHASE_ORDER_LIST: 'receive-purchase-order-list',
  PAYMENT_CHANNEL_LIST: 'payment-channel-list',
  CHART_OF_ACCOUNT_LIST: 'payment-channel-list',
  RATING_LIST: 'rating-list',
  RAW_MATERIAL_STOCK_OPNAME_LIST: 'raw-material-stock-opname',
  SALES_INVOICE_LIST: 'sales-invoice',
  SIDEBAR_LIST: 'sidebar-list',
  IAM_LIST: 'iam-list',
  REPORT_BY_PRODUCT_LIST: 'report-by-product',
  REPORT_BY_PAYMENT_CHANNEL: 'report-by-payment-channel',
  REPORT_BY_OUTLET: 'report-by-outlet',
  REPORT_KPI_EMPLOYEE: 'report-kpi-employee',
  KPI_REVENUE_STREAM: 'kpi-revenue-stream',
  MEMBERSHIP_LIST: 'membership-list',
  VOUCHER_CATEGORY_LIST: 'voucher-category-list',
  RESET_POINT_LIST: 'reset-point-list',
  REWARD_POINT_LIST: 'reward-point-list',
  MEMBERSHIP_TYPE_LIST: 'membership-type-list',
  REDEEM_POINT_LIST: 'redeem-point-list',
  VOUCHER_LIST: 'redeem-list',
  REPORT_BILLING_CLIENT: 'report-billing-client',
  PURCHASE_ORDER_RAW_MATERIAL_LIST: 'purchase-order-raw-material-list',
  ACCOUNT_RECEIVABLE_AGING_SUMMARY: 'account-receivable-aging-summary',
  ACCOUNT_RECEIVABLE_AGING_DETAIL: 'account-receivable-aging-detail',
  INITIAL_RAW_MATERIAL_STOCK_LIST: 'initial-raw-material-stock-list',
  OUTLET_CATEGORY_LIST: 'outlet-category-list',
  REPORT_BY_TREATMENT_LIST: 'report-by-treatment',
  REPORT_BY_GROUP_LIST: 'report-by-group',
}

const EMPLOYEE_JOB_ID = '499db2c1-bca9-46a1-b921-34a036bc0fe6'
const SUPERVISOR_JOB_ID = 'b59e8f98-c046-4d69-8afc-8dc3d8cbb265'
const BRAND_ID = '8e66a575-98c1-49f8-8376-369b70c951dd'

const DATE_FORMAT = {
  BASE: 'yyyy-MM-DD[T]HH:mm:ss[Z]',
}

export { QUERIES, EMPLOYEE_JOB_ID, SUPERVISOR_JOB_ID, BRAND_ID, DATE_FORMAT }
