import {AxiosResponse} from 'axios'
import {axios} from '@/_metronic/helpers/network/Axios'
import {BaseRequest} from '@/_metronic/helpers/crud-helper/base-request'
import { FilterMasterData, MasterData, MasterDataResponse } from './_models'
import { BaseResponse } from '@/_metronic/helpers/crud-helper/base-list-response'

const OUTLET_API_URL = '/outlet-services'
const GET_MASTER_DATA_LIST = '/masterdata-value/list'
const GET_MASTER_DATA_BY_ID = '/masterdata-value'

const getMasterDataDropdown = (
  body: BaseRequest<FilterMasterData>
): Promise<MasterDataResponse> => {
  return axios
    .post(`${OUTLET_API_URL}${GET_MASTER_DATA_LIST}`, body)
    .then((d: AxiosResponse<MasterDataResponse>) => d.data)
}

const createMasterData = (body: MasterData): Promise<BaseResponse<any>> => {
  return axios
    .post(`${OUTLET_API_URL}${GET_MASTER_DATA_BY_ID}/create`, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

export {getMasterDataDropdown, createMasterData}