import { QUERIES, WithChildren, WITPaginationState } from '@/_metronic/helpers'
import { BaseResponse } from '@/_metronic/helpers/crud-helper/base-list-response'
import { createContext, FC, useContext, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { FilterTop, initialFilter, RevenueStreamCity } from './_models'
import { getRevenueTopCity } from './_requests'
import { useTopQueryRequest } from './QueryRequestTopProvider'

/**
 * Begin: Initial Values
 */
export const initialQueryResponse = {refetch: () => {}, isLoading: false, request: initialFilter}

/**
 * Begin: Response Context
 *
 * It used for context response wrapper, so the response can
 * called everywhere, as long as it wrapped inside the context
 */
type ProductQueryResponseContextProps = {
  response?: BaseResponse<Array<RevenueStreamCity>> | undefined
  refetch: () => void
  isLoading: boolean
  request: FilterTop
}

function createResponseContext<T>(initialState: ProductQueryResponseContextProps) {
  return createContext(initialState)
}

const QueryResponseContext = createResponseContext<Array<RevenueStreamCity>>(initialQueryResponse)

/**
 * Begin: The Provider
 */
const CityQueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useTopQueryRequest()
  const [request, setRequest] = useState<FilterTop>(state)
  const updatedQuery = useMemo(() => state, [state])

  useEffect(() => {
    if (request !== updatedQuery) {
      setRequest(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.REVENUE_STREAM_CITY}-${JSON.stringify(request)}`,
    () => {
      return getRevenueTopCity(request)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, request}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useCityQueryResponse = () => useContext(QueryResponseContext)

const useCityQueryResponseData = () => {
  const {response} = useCityQueryResponse()
  if (!response) {
    return null
  }

  return response?.response?.data || null
}

const useCityQueryResponsePagination = () => {
  const defaultPaginationState: WITPaginationState = {
    page: 1,
    pageSize: 10,
    totalCount: 1,
  }

  const {response} = useCityQueryResponse()
  if (!response || !response.response) {
    return defaultPaginationState
  }
  const res = response.response
  return {
    page: res.current_page,
    pageSize: res.limit,
    totalCount: res.total_data,
  } as WITPaginationState
}

const useCityQueryResponseLoading = (): boolean => {
  const {isLoading} = useCityQueryResponse()
  return isLoading
}

export {
  CityQueryResponseProvider,
  useCityQueryResponse,
  useCityQueryResponseData,
  useCityQueryResponseLoading,
  useCityQueryResponsePagination
}

