import { QUERIES, WithChildren, WITPaginationState } from '@/_metronic/helpers'
import { BaseResponse } from '@/_metronic/helpers/crud-helper/base-list-response'
import { createContext, FC, useContext, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { FilterReportSalesBudget, initialFilter, ReportSalesBudget } from './_models'
import { getReportSalesBudget } from './_requests'
import { useQueryRequestDaily } from './QueryRequestDailyProvider'

/**
 * Begin: Initial Values
 */
export const initialQueryResponseDaily = {refetch: () => {}, isLoading: false, request: initialFilter}

/**
 * Begin: Response Context
 *
 * It used for context response wrapper, so the response can
 * called everywhere, as long as it wrapped inside the context
 */
type ProductQueryResponseDailyContextProps = {
  response?: BaseResponse<ReportSalesBudget> | undefined
  refetch: () => void
  isLoading: boolean
  request: FilterReportSalesBudget
}

function createResponseContext<T>(initialState: ProductQueryResponseDailyContextProps) {
  return createContext(initialState)
}

const QueryResponseDailyContext = createResponseContext<ReportSalesBudget>(initialQueryResponseDaily)

/**
 * Begin: The Provider
 */
const QueryResponseDailyProvider: FC<WithChildren> = ({children}) => {
  const {state} = useQueryRequestDaily()
  const [request, setRequest] = useState<FilterReportSalesBudget>(state)
  const updatedQuery = useMemo(() => state, [state])

  useEffect(() => {
    if (request !== updatedQuery) {
      setRequest(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.REPORT_SALES_VS_BUDGET}-${JSON.stringify(request)}`,
    () => {
      return getReportSalesBudget(request)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <QueryResponseDailyContext.Provider value={{isLoading: isFetching, refetch, response, request}}>
      {children}
    </QueryResponseDailyContext.Provider>
  )
}

const useQueryResponseDaily = () => useContext(QueryResponseDailyContext)

const useQueryResponseDailyData = () => {
  const {response} = useQueryResponseDaily()
  if (!response) {
    return null
  }

  return response?.response?.data || null
}

const useQueryResponseDailyPagination = () => {
  const defaultPaginationState: WITPaginationState = {
    page: 1,
    pageSize: 10,
    totalCount: 1,
  }

  const {response} = useQueryResponseDaily()
  if (!response || !response.response) {
    return defaultPaginationState
  }
  const res = response.response
  return {
    page: res.current_page,
    pageSize: res.limit,
    totalCount: res.total_data,
  } as WITPaginationState
}

const useQueryResponseDailyLoading = (): boolean => {
  const {isLoading} = useQueryResponseDaily()
  return isLoading
}

export {
  QueryResponseDailyProvider,
  useQueryResponseDaily,
  useQueryResponseDailyData,
  useQueryResponseDailyLoading,
  useQueryResponseDailyPagination
}

