import {RESP} from '@/_metronic/helpers/network/network-consts'
import {InputGroup} from '@/_metronic/partials/content/input-group/InputGroup'
import {isAxiosError} from 'axios'
import {FormikValues, useFormik} from 'formik'
import {FC, useEffect} from 'react'
import {Spinner} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {resetPasswordSubmit} from '../../core/_requests'
import {useBookingFormState} from '../core/FormStateProvider'
import {useSelectTreatmentModal} from '../core/_ModalProvider'
import {ForgotPasswordSuccessModal} from './ForgotPasswordSuccessModal'

const forgotPasswordFormSchemes = Yup.object().shape({
  new_password: Yup.string().required('Kata sandi tidak boleh kosong'),
  confirm_new_password: Yup.string().required('Kata sandi baru tidak boleh kosong'),
})

const forgotPasswordFormInitialValues = {
  new_password: '',
  confirm_new_password: '',
}

type Props = {
  handleSubmit: (body: any) => void
}

const ForgotPasswordForm: FC<Props> = ({handleSubmit}) => {
  const {showModal, closeModal, show, showSuccess, showSuccessModal, closeSuccessModal} =
    useSelectTreatmentModal()
  const {isLoading, setIsLoading} = useBookingFormState()
  const navigate = useNavigate()
  const {id} = useParams()

  const onSubmit = async (values: FormikValues) => {
    const body = {
      password: values.new_password,
      password_confirmation: values.confirm_new_password,
    }
    setIsLoading(true)
    try {
      const res = await resetPasswordSubmit(id, body)
      if (res?.data?.response?.code === RESP.SUCCESS) {
        showSuccessModal()
        return
      }

      toast.error(res?.data?.response?.message_id)
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error?.response?.data?.response?.message_id)
        return
      }

      toast.error('Server error. Please try again later')
    } finally {
      setIsLoading(false)
    }
  }

  const onClickShowTreatment = () => {
    showModal()
  }

  useEffect(() => {
    if (showSuccess) {
      handleReset(forgotPasswordFormInitialValues)
    }
  }, [showSuccess])

  const onClickShowSuccess = () => {
    showSuccessModal()
  }

  useEffect(() => {
    if (!showSuccessModal) {
      handleReset(forgotPasswordFormInitialValues)
    }
  }, [showSuccessModal])

  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    handleReset,
    handleSubmit: handleFormSubmit,
    isValid,
  } = useFormik({
    initialValues: forgotPasswordFormInitialValues,
    validationSchema: forgotPasswordFormSchemes,
    onSubmit,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
  })

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <div className='col-md-12'>
          <div className='mb-5'>
            <InputGroup
              label='Kata Sandi Baru'
              type='password'
              placeholder='Kata Sandi Baru'
              isValid={Boolean(values.new_password)}
              errorMessage={
                !Boolean(values.new_password) && touched?.new_password
                  ? errors?.new_password?.toString()
                  : ''
              }
              value={values.new_password}
              onChange={handleChange('new_password')}
              onBlur={handleBlur('new_password')}
              // icon='/media/svg/orders/user.svg'
            />
          </div>
        </div>
        <div className='col-md-12'>
          <div className='mb-5'>
            <InputGroup
              label='Ulangi Kata Sandi Baru'
              type='password'
              placeholder='Ulangi Kata Sandi Baru'
              isValid={Boolean(values.confirm_new_password)}
              errorMessage={
                !Boolean(values.confirm_new_password) && touched?.confirm_new_password
                  ? errors?.confirm_new_password?.toString()
                  : ''
              }
              value={values.confirm_new_password}
              onChange={handleChange('confirm_new_password')}
              onBlur={handleBlur('confirm_new_password')}
              // icon='/media/svg/orders/phone.svg'
            />
          </div>
        </div>
        <div className='col-md-12'>
          <button
            className='btn btn-md btn-info w-100'
            disabled={!isValid || isLoading}
            // onClick={onClickShowSuccess}
          >
            {isLoading ? <Spinner color='white' size='sm' animation='border' /> : 'Simpan'}
          </button>
        </div>
        <ForgotPasswordSuccessModal
          onLogin={() => {
            navigate('/auth')
            closeSuccessModal()
          }}
        />
      </form>
    </>
  )
}

export default ForgotPasswordForm
