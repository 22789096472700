import { axios } from '@/_metronic/helpers/network/Axios'
import { AxiosResponse } from 'axios'
import { FilterReportBookingType, ReportBookingTypeQueryResponse } from './_models'

const REPORT_API_URL = '/report-services'
const GET_REPORT_BOOKING_TYPE_LIST = '/dashboard/booking-type'

const getReportBookingType = (
  body: FilterReportBookingType
): Promise<ReportBookingTypeQueryResponse> => {
  return axios
    .post(`${REPORT_API_URL}${GET_REPORT_BOOKING_TYPE_LIST}`, body)
    .then((d: AxiosResponse<ReportBookingTypeQueryResponse>) => d.data)
}

export { getReportBookingType }

