import { KTIcon } from '@/_metronic/helpers/components/KTIcon'
import { useEffect, useState } from 'react'
import { useFilterReportSalesBudget } from '../../core/FilterReportSalesBudgetProvider'
import { useQueryRequestMonthly } from '../../core/QueryRequestMonthlyProvider'
import { initialFilter } from '../../core/_models'

const ReportSalesBudgetMonthlyListToolbar = () => {
  const {handleShowMonthlyModal} = useFilterReportSalesBudget()
  const {updateFilterReportSalesBudgetFormMonthlyValue, filterReportSalesBudgetFormMonthlyValue} =
    useFilterReportSalesBudget()
  const {updateState, state} = useQueryRequestMonthly()
  const [filterValue, setFilterValue] = useState(initialFilter)
  const currentDate = new Date()

  const filterData = () => {
    state.set_brand_id = state.brand_id && state.brand_id.length > 0
    state.set_sub_brand_id = state.sub_brand_id && state.sub_brand_id.length > 0
    state.set_region_id = state.region_id && state.region_id.length > 0
    state.set_city_id = state.city_id && state.city_id.length > 0
    state.set_area = state.area && state.area.length > 0
    state.set_outlet_id = state.outlet_id && state.outlet_id.length > 0

    updateState(state)
    updateFilterReportSalesBudgetFormMonthlyValue({
      ...filterReportSalesBudgetFormMonthlyValue,
      brand: filterReportSalesBudgetFormMonthlyValue?.brand?.filter((brand) =>
        Boolean(state?.brand_id?.find((item) => item === brand.value))
      ),
      sub_brand: filterReportSalesBudgetFormMonthlyValue?.sub_brand?.filter((sub_brand) =>
        Boolean(state?.sub_brand_id?.find((item) => item === sub_brand.value))
      ),
      outlet: filterReportSalesBudgetFormMonthlyValue?.outlet?.filter((outlet) =>
        Boolean(state?.outlet_id?.find((item) => item === outlet.value))
      ),
      area: filterReportSalesBudgetFormMonthlyValue?.area?.filter((area) =>
        Boolean(state?.area?.find((item) => item === area.value))
      ),
      region: filterReportSalesBudgetFormMonthlyValue?.region?.filter((region) =>
        Boolean(state?.region_id?.find((item) => item === region.value))
      ),
      city: filterReportSalesBudgetFormMonthlyValue?.city?.filter((city) =>
        Boolean(state?.city_id?.find((item) => item === city.value))
      ),
      month: filterReportSalesBudgetFormMonthlyValue?.month?.filter((month) =>
        Boolean(state?.month?.find((item) => item === month.value))
      ),
    })
  }

  const resetData = () => {
    setFilterValue({
      ...filterValue,
      year: currentDate.getFullYear().toString(),
    })
    updateFilterReportSalesBudgetFormMonthlyValue(null)
    updateFilterReportSalesBudgetFormMonthlyValue({
      year: {value: currentDate.getFullYear(), label: currentDate.getFullYear()},
    })
    updateState(initialFilter)
  }

  useEffect(() => {
    let monthList: string[] = []
    if (filterReportSalesBudgetFormMonthlyValue?.month) {
      filterReportSalesBudgetFormMonthlyValue.month.forEach((element) => {
        monthList.push(element.value)
      })
    }
    let areaList: string[] = []
    if (filterReportSalesBudgetFormMonthlyValue?.area) {
      filterReportSalesBudgetFormMonthlyValue.area.forEach((element) => {
        areaList.push(element.value)
      })
    }
    let outletList: string[] = []
    if (filterReportSalesBudgetFormMonthlyValue?.outlet) {
      filterReportSalesBudgetFormMonthlyValue.outlet.forEach((element) => {
        outletList.push(element.value)
      })
    }
    let brandList: string[] = []
    if (filterReportSalesBudgetFormMonthlyValue?.brand) {
      filterReportSalesBudgetFormMonthlyValue.brand.forEach((element) => {
        brandList.push(element.value)
      })
    }
    let subBrandList: string[] = []
    if (filterReportSalesBudgetFormMonthlyValue?.sub_brand) {
      filterReportSalesBudgetFormMonthlyValue.sub_brand.forEach((element) => {
        subBrandList.push(element.value)
      })
    }
    let regionList: string[] = []
    if (filterReportSalesBudgetFormMonthlyValue?.region) {
      filterReportSalesBudgetFormMonthlyValue.region.forEach((element) => {
        regionList.push(element.value)
      })
    }
    let cityList: string[] = []
    if (filterReportSalesBudgetFormMonthlyValue?.city) {
      filterReportSalesBudgetFormMonthlyValue.city.forEach((element) => {
        cityList.push(element.value)
      })
    }

    setFilterValue({
      ...filterValue,
      set_area: Boolean(filterReportSalesBudgetFormMonthlyValue?.area),
      area: areaList,
      set_outlet_id: Boolean(filterReportSalesBudgetFormMonthlyValue?.outlet),
      outlet_id: outletList,
      set_brand_id: Boolean(filterReportSalesBudgetFormMonthlyValue?.brand),
      brand_id: brandList,
      set_sub_brand_id: Boolean(filterReportSalesBudgetFormMonthlyValue?.sub_brand),
      sub_brand_id: subBrandList,
      set_region_id: Boolean(filterReportSalesBudgetFormMonthlyValue?.region),
      region_id: regionList,
      set_city_id: Boolean(filterReportSalesBudgetFormMonthlyValue?.city),
      city_id: cityList,
      month: monthList,
      year: filterReportSalesBudgetFormMonthlyValue?.year
        ? filterReportSalesBudgetFormMonthlyValue?.year?.value?.toString()
        : currentDate.getFullYear().toString(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterReportSalesBudgetFormMonthlyValue])

  return (
    <div className='d-flex justify-content-end py-3 me-8'>
      <div className='d-flex'>
        <div>
          {state?.brand_id?.map((brand_id, index) => {
            return (
              <span className='badge badge-pill badge-light mx-3 my-2'>
                Brand :{' '}
                {
                  filterReportSalesBudgetFormMonthlyValue?.brand?.find((item) => item.value === brand_id)
                    ?.label
                }
                <div
                  className='cursor-pointer'
                  onClick={() => {
                    const updatedItems = state?.brand_id
                      ?.slice(0, index)
                      .concat(state?.brand_id.slice(index + 1))
                    state.brand_id = updatedItems
                    state.set_brand_id = (updatedItems ?? []).length > 0
                    setFilterValue({
                      ...filterValue,
                      brand_id: state.brand_id,
                      set_brand_id: state.set_brand_id,
                    })
                    filterData()
                  }}
                >
                  <span className='ki-solid ki-cross fs-2x' />
                </div>
              </span>
            )
          })}
          {state?.sub_brand_id?.map((sub_brand, index) => {
            return (
              <span className='badge badge-pill badge-light mx-3 my-2'>
                Sub Brand :{' '}
                {
                  filterReportSalesBudgetFormMonthlyValue?.sub_brand?.find(
                    (item) => item.value === sub_brand
                  )?.label
                }
                <div
                  className='cursor-pointer'
                  onClick={() => {
                    const updatedItems = state?.sub_brand_id
                      ?.slice(0, index)
                      .concat(state?.sub_brand_id.slice(index + 1))
                    state.sub_brand_id = updatedItems
                    state.set_sub_brand_id = (updatedItems ?? []).length > 0
                    setFilterValue({
                      ...filterValue,
                      sub_brand_id: state.sub_brand_id,
                      set_sub_brand_id: state.set_sub_brand_id,
                    })
                    filterData()
                  }}
                >
                  <span className='ki-solid ki-cross fs-2x' />
                </div>
              </span>
            )
          })}
          {state?.area?.map((area, index) => {
            return (
              <span className='badge badge-pill badge-light mx-3 my-2'>
                Area :{' '}
                {filterReportSalesBudgetFormMonthlyValue?.area?.find((item) => item.value === area)?.label}
                <div
                  className='cursor-pointer'
                  onClick={() => {
                    const updatedItems = state?.area
                      ?.slice(0, index)
                      .concat(state?.area.slice(index + 1))
                    state.area = updatedItems
                    state.set_area = (updatedItems ?? []).length > 0
                    setFilterValue({...filterValue, area: state.area, set_area: state.set_area})
                    filterData()
                  }}
                >
                  <span className='ki-solid ki-cross fs-2x' />
                </div>
              </span>
            )
          })}
          {state?.region_id?.map((region, index) => {
            return (
              <span className='badge badge-pill badge-light mx-3 my-2'>
                Region :{' '}
                {
                  filterReportSalesBudgetFormMonthlyValue?.region?.find((item) => item.value === region)
                    ?.label
                }
                <div
                  className='cursor-pointer'
                  onClick={() => {
                    const updatedItems = state?.region_id
                      ?.slice(0, index)
                      .concat(state?.region_id.slice(index + 1))
                    state.region_id = updatedItems
                    state.set_region_id = (updatedItems ?? []).length > 0
                    setFilterValue({
                      ...filterValue,
                      region_id: state.region_id,
                      set_region_id: state.set_region_id,
                    })
                    filterData()
                  }}
                >
                  <span className='ki-solid ki-cross fs-2x' />
                </div>
              </span>
            )
          })}
          {state?.city_id?.map((city, index) => {
            return (
              <span className='badge badge-pill badge-light mx-3 my-2'>
                City :{' '}
                {filterReportSalesBudgetFormMonthlyValue?.city?.find((item) => item.value === city)?.label}
                <div
                  className='cursor-pointer'
                  onClick={() => {
                    const updatedItems = state?.city_id
                      ?.slice(0, index)
                      .concat(state?.city_id.slice(index + 1))
                    state.city_id = updatedItems
                    state.set_city_id = (updatedItems ?? []).length > 0
                    setFilterValue({
                      ...filterValue,
                      city_id: state.city_id,
                      set_city_id: state.set_city_id,
                    })
                    filterData()
                  }}
                >
                  <span className='ki-solid ki-cross fs-2x' />
                </div>
              </span>
            )
          })}
          {state?.outlet_id?.map((outlet, index) => {
            return (
              <span className='badge badge-pill badge-light mx-3 my-2'>
                Outlet :{' '}
                {
                  filterReportSalesBudgetFormMonthlyValue?.outlet?.find((item) => item.value === outlet)
                    ?.label
                }
                <div
                  className='cursor-pointer'
                  onClick={() => {
                    const updatedItems = state?.outlet_id
                      ?.slice(0, index)
                      .concat(state?.outlet_id.slice(index + 1))
                    state.outlet_id = updatedItems
                    state.set_outlet_id = (updatedItems ?? []).length > 0
                    setFilterValue({
                      ...filterValue,
                      outlet_id: state.outlet_id,
                      set_outlet_id: state.set_outlet_id,
                    })
                    filterData()
                  }}
                >
                  <span className='ki-solid ki-cross fs-2x' />
                </div>
              </span>
            )
          })}
          {state?.month?.map((month, index) => {
            return (
              <span className='badge badge-pill badge-light mx-3 my-2'>
                Month :{' '}
                {
                  filterReportSalesBudgetFormMonthlyValue?.month?.find((item) => item.value === month)
                    ?.label
                }
                <div
                  className='cursor-pointer'
                  onClick={() => {
                    const updatedItems = state?.month
                      ?.slice(0, index)
                      .concat(state?.month.slice(index + 1))
                    state.month = updatedItems
                    setFilterValue({...filterValue, month: state.month})
                    filterData()
                  }}
                >
                  <span className='ki-solid ki-cross fs-2x' />
                </div>
              </span>
            )
          })}
          {state?.year && (
            <span className='badge badge-pill badge-light mx-3 my-2 py-4'>Year : {state?.year}</span>
          )}
        </div>
        {((state.brand_id! ?? []).length > 0 ||
          (state.sub_brand_id! ?? []).length > 0 ||
          (state.area! ?? []).length > 0 ||
          (state.region_id! ?? []).length > 0 ||
          (state.city_id! ?? []).length > 0 ||
          (state.month! ?? []).length > 0 ||
          (state.outlet_id! ?? []).length > 0) && (
          <div
            className='col-auto me-5 d-flex align-items-center h6 text-muted cursor-pointer'
            onClick={() => resetData()}
          >
            <u>Clear All</u>
          </div>
        )}
        <div className='col-auto d-flex align-items-center'>
          <button
            type='button'
            className='btn btn-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            onClick={() => handleShowMonthlyModal()}
          >
            <KTIcon iconName='filter' className='fs-2' />
            Filter
          </button>
        </div>
      </div>
    </div>
  )
}

export { ReportSalesBudgetMonthlyListToolbar }

