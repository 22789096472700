import { FC } from 'react'
import { ReportSalesBudgetDailyListToolbar } from './ReportSalesBudgetDailyListToolbar'
import { ReportSalesBudgetMonthlyListToolbar } from './ReportSalesBudgetMonthlyListToolbar'

type Props = {
  monthly: boolean
}

const ReportSalesBudgetListHeader : FC<Props> = ({monthly})  => {
  return (
    <div className='border-0'>
      <div className='card-toolbar'>
        {monthly? <ReportSalesBudgetMonthlyListToolbar /> : <ReportSalesBudgetDailyListToolbar />}
      </div>
    </div>
  )
}

export { ReportSalesBudgetListHeader }

