import { WithChildren } from '@/_metronic/helpers'
import { createContext, FC, useContext, useState } from 'react'

type FilterReportRushHourContextProps = {
  showFilterReportRushHourModal: boolean
  filterReportRushHourFormValue: any
  updateFilterReportRushHourFormValue: (formValue: any) => void
  handleShowModal: () => void
  closeModal: () => void
}

const filterReportRushHourContextInitial: FilterReportRushHourContextProps = {
  showFilterReportRushHourModal: false,
  filterReportRushHourFormValue: null,
  updateFilterReportRushHourFormValue: () => {},
  handleShowModal: () => {},
  closeModal: () => {},
}

const FilterReportRushHourContext = createContext<FilterReportRushHourContextProps>(
  filterReportRushHourContextInitial
)

const FilterReportRushHourProvider: FC<WithChildren> = ({children}) => {
  const [showFilterReportRushHourModal, setShowFilterReportRushHourModal] = useState<boolean>(false)
  const [filterReportRushHourFormValue, setFilterReportRushHourFormValue] = useState<any>(null)

  const updateFilterReportRushHourFormValue = (newValue) => {
    setFilterReportRushHourFormValue(newValue)
  }

  const handleShowModal = () => {
    setShowFilterReportRushHourModal(true)
  }

  const closeModal = () => {
    setShowFilterReportRushHourModal(false)
  }

  return (
    <FilterReportRushHourContext.Provider
      value={{
        showFilterReportRushHourModal,
        filterReportRushHourFormValue,
        updateFilterReportRushHourFormValue,
        handleShowModal,
        closeModal,
      }}
    >
      {children}
    </FilterReportRushHourContext.Provider>
  )
}

const useFilterReportRushHour = () => useContext(FilterReportRushHourContext)

export { FilterReportRushHourProvider, useFilterReportRushHour }

