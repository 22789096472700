import {ID, Response} from '../../../../../_metronic/helpers'
export type IRevenueList = {
  brand: string;
  sub_brand: string;
  outlet: string;
  period: string;
  year: string;
  revenue: string;
  taxes: string;
  services: string;
  revenue_after_taxes: string;
  revenue_share_alaya: string;
  revenue_share_hotel: string;
  service_charge_alaya: string;
  service_charge_hotel: string;
}

export type UsersQueryResponse = Response<Array<IRevenueList>>

export const IRevenueListDummyState: IRevenueList[] = [
  {
    brand: "Alaya",
    sub_brand: "Alaya",
    outlet: "Hotel Vio Bandung",
    period: "January",
    year: "2023",
    revenue: "1.500.000",
    taxes: "150.000",
    services: "100.000",
    revenue_after_taxes: "1.250.000",
    revenue_share_alaya: "1.000.000",
    revenue_share_hotel: "250.000",
    service_charge_alaya: "100.000",
    service_charge_hotel: "150.000",
  },
  {
    brand: "Alaya",
    sub_brand: "Alaya",
    outlet: "Hotel Vio Bandung",
    period: "January",
    year: "2023",
    revenue: "1.500.000",
    taxes: "150.000",
    services: "100.000",
    revenue_after_taxes: "1.250.000",
    revenue_share_alaya: "1.000.000",
    revenue_share_hotel: "250.000",
    service_charge_alaya: "100.000",
    service_charge_hotel: "150.000",
  },
  {
    brand: "Alaya",
    sub_brand: "Alaya",
    outlet: "Hotel Vio Bandung",
    period: "January",
    year: "2023",
    revenue: "1.500.000",
    taxes: "150.000",
    services: "100.000",
    revenue_after_taxes: "1.250.000",
    revenue_share_alaya: "1.000.000",
    revenue_share_hotel: "250.000",
    service_charge_alaya: "100.000",
    service_charge_hotel: "150.000",
  },
  {
    brand: "Alaya",
    sub_brand: "Alaya",
    outlet: "Hotel Vio Bandung",
    period: "January",
    year: "2023",
    revenue: "1.500.000",
    taxes: "150.000",
    services: "100.000",
    revenue_after_taxes: "1.250.000",
    revenue_share_alaya: "1.000.000",
    revenue_share_hotel: "250.000",
    service_charge_alaya: "100.000",
    service_charge_hotel: "150.000",
  },
  {
    brand: "Alaya",
    sub_brand: "Alaya",
    outlet: "Hotel Vio Bandung",
    period: "January",
    year: "2023",
    revenue: "1.500.000",
    taxes: "150.000",
    services: "100.000",
    revenue_after_taxes: "1.250.000",
    revenue_share_alaya: "1.000.000",
    revenue_share_hotel: "250.000",
    service_charge_alaya: "100.000",
    service_charge_hotel: "150.000",
  },
  {
    brand: "Alaya",
    sub_brand: "Alaya",
    outlet: "Hotel Vio Bandung",
    period: "January",
    year: "2023",
    revenue: "1.500.000",
    taxes: "150.000",
    services: "100.000",
    revenue_after_taxes: "1.250.000",
    revenue_share_alaya: "1.000.000",
    revenue_share_hotel: "250.000",
    service_charge_alaya: "100.000",
    service_charge_hotel: "150.000",
  },
]
