/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTCard } from '@/_metronic/helpers/components/KTCard'
import { FC, useState } from 'react'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import ForecastOverviewMonthly from './ForecastOverviewMonthly'
import { FilterReportSalesBudgetModal } from './components/filter/FilterReportSalesBudgetModal'
import { ReportSalesBudgetListHeader } from './components/filter/ReportSalesBudgetListHeader'
import {
  FilterReportSalesBudgetProvider,
  useFilterReportSalesBudget,
} from './core/FilterReportSalesBudgetProvider'
import { ForecastListWrapper } from './forecast-list/ForecastList'
import { DashboardForecastBody } from './components/body/DashboardForecastBody'
import { QueryRequestMonthlyProvider } from './core/QueryRequestMonthlyProvider'
import { QueryRequestDailyProvider } from './core/QueryRequestDailyProvider'
import { QueryResponseMonthlyProvider } from './core/QueryResponseMonthlyProvider'
import { QueryResponseDailyProvider } from './core/QueryResponseDailyProvider'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Report',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Sales',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DashboardForecastPage: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Dashboard Revenue vs Budget</PageTitle>
      {/* <div className="bg-white px-8">
        <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
          <li className="nav-item mt-2">
            <a onClick={() => setActiveTab("overview")} className={`cursor-pointer nav-link text-active-primary ms-0 me-10 py-5 ${activeTab === 'overview' ? "active" : ""}`}>
              Overview
            </a>
          </li>
          <li className="nav-item mt-2">
            <a onClick={() => setActiveTab("detail")} className={`cursor-pointer nav-link text-active-primary ms-0 me-10 py-5 ${activeTab === 'detail' ? "active" : ""}`}>
              Detail
            </a>
          </li>
        </ul>
      </div> */}

      {/* {activeTab === 'overview' ? <ForecastOverview /> : <ForecastListWrapper />} */}
      {/* <FilterReportSalesBudgetModal
        show={showFilterReportSalesBudgetModal}
        closeModal={() => closeModal()}
      /> */}

      <DashboardForecastBody />
    </>
  )
}

const DashboardForecastWrapper: FC = () => {
  return (
    <>
      <QueryRequestMonthlyProvider>
        <QueryRequestDailyProvider>
          <QueryResponseMonthlyProvider>
            <QueryResponseDailyProvider>
              <FilterReportSalesBudgetProvider>
                <DashboardForecastPage />
              </FilterReportSalesBudgetProvider>
            </QueryResponseDailyProvider>
          </QueryResponseMonthlyProvider>
        </QueryRequestDailyProvider>
      </QueryRequestMonthlyProvider>
    </>
  )
}

export { DashboardForecastWrapper }

