import { FC } from 'react'
import { Modal } from 'react-bootstrap'
import { FilterReportSalesBudgetMonthlyForm } from './FilterReportSalesBudgetMonthlyForm'
import { FilterReportSalesBudgetModalHeader } from './FilterReportSalesBudgetModalHeader'
import { FilterReportSalesBudgetDailyForm } from './FilterReportSalesBudgetDailyForm'

type Props = {
  show: boolean
  monthly: boolean
  closeModal: () => void
}

const FilterReportSalesBudgetModal: FC<Props> = ({show, monthly, closeModal}) => {
  return (
    <>
      <Modal className='modal fade' dialogClassName='modal-xl' show={show} onHide={closeModal}>
        <div className='modal-content'>
          <FilterReportSalesBudgetModalHeader monthly={monthly} closeModal={closeModal} />
          <div className='modal-body scroll-y'>
            {monthly? <FilterReportSalesBudgetMonthlyForm closeModal={closeModal} /> : 
                      <FilterReportSalesBudgetDailyForm closeModal={closeModal} />
            }
          </div>
        </div>
      </Modal>
    </>
  )
}

export { FilterReportSalesBudgetModal }

