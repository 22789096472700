import { WithChildren } from '@/_metronic/helpers'
import { createContext, FC, useContext, useState } from 'react'
import { FilterTop, initialFilter } from './_models'

type ProductQueryRequestTopContextProps = {
  state: FilterTop
  updateState: (updates: Partial<FilterTop>) => void
}

const initialQueryRequest = {
  state: initialFilter,
  updateState: () => {},
}

const QueryRequestContext = createContext<ProductQueryRequestTopContextProps>(initialQueryRequest)

const TopQueryRequestProvider: FC<WithChildren> = ({children}) => {
  const [state, setState] = useState<FilterTop>(initialQueryRequest.state)

  const updateState = (updates: Partial<FilterTop>) => {
    const updatedState = {...state, ...updates} as FilterTop
    setState(updatedState)
  }

  return (
    <QueryRequestContext.Provider value={{state, updateState}}>
      {children}
    </QueryRequestContext.Provider>
  )
}

const useTopQueryRequest = () => useContext(QueryRequestContext)
export { TopQueryRequestProvider, useTopQueryRequest }

