export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname: string, url: string) {
  const current = getCurrentUrl(pathname)
  if (!current || !url) {
    return false
  }

  if (current === url) {
    return true
  }

  if (current.indexOf(url) > -1) {
    return true
  }

  return false
}

export const findMenu = (data: any, value: any) => {
  return data.reduce((r: any, e: any) => {
    const object = {...e}
    const result = findMenu(e.sub_menu || [], value)
    if (result.length) object.sub_menu = result
    if (e.url_path?.toLowerCase().includes(value?.toLowerCase()) || result.length) r.push(object)
    return r
  }, [])
}

export const findSingleMenu = (menu, searchTerm) => {
  const results: any = []

  function searchRecursive(item, term) {
    if (item.url_path.toLowerCase().includes(term.toLowerCase())) {
      results.push(item)
    }

    if (item.sub_menu && item.sub_menu.length > 0) {
      for (const subItem of item.sub_menu) {
        searchRecursive(subItem, term)
      }
    }
  }

  for (const menuItem of menu) {
    searchRecursive(menuItem, searchTerm)
  }

  return results
}
