import { FC } from "react"
import { GeneralInformationForm } from "../tab/GeneralInformationForm"
import { PersoninChargeForm } from "../tab/PersoninChargeForm"
import { useLocation } from "react-router-dom"

type Props = {
}

const PartnerEditBody : FC<Props> = () => {

  const location = useLocation()

  return (
    <div id='kt_content_container' className='flex-lg-row-fluid mb-10 mb-xl-0'>
          {/*begin::Form*/}
          <form
            id='kt_ecommerce_add_product_form'
            className='form d-flex flex-column flex-lg-row'
            data-kt-redirect='/metronic8/demo8/../demo8/apps/ecommerce/catalog/products.html'
          >
            {/*begin::Main column*/}
            <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
              {/*begin:::Tabs*/}
              <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
                {/*begin:::Tab item*/}
                <li className='nav-item'>
                  <a
                    className='nav-link text-active-primary pb-4 active'
                    data-bs-toggle='tab'
                    href='#kt_ecommerce_add_product_general'
                  >
                    General
                  </a>
                </li>
                {/*end:::Tab item*/}
                {/*begin:::Tab item*/}
                {!location?.pathname?.includes('/add') && <li className='nav-item'>
                  <a
                    className='nav-link text-active-primary pb-4'
                    data-bs-toggle='tab'
                    href='#kt_ecommerce_add_product_advanced'
                  >
                    Person in Charge
                  </a>
                </li>}
                {/*end:::Tab item*/}
              </ul>
              {/*end:::Tabs*/}
              {/*begin::Tab content*/}
              <div className='tab-content'>
                {/*begin::Tab pane*/}
                <div
                  className='tab-pane fade show active'
                  id='kt_ecommerce_add_product_general'
                >
                 <GeneralInformationForm/>
                </div>
                {/*end::Tab pane*/}
                {/*begin::Tab pane*/}
                {!location?.pathname?.includes('/add') && <div
                  className='tab-pane fade'
                  id='kt_ecommerce_add_product_advanced'
                >
                  <PersoninChargeForm/> 
                </div>}
                {/*end::Tab pane*/}
              </div>
              {/*end::Tab content*/}
            </div>
            {/*end::Main column*/}
          </form>
          {/*end::Form*/}
        </div>
  )
}

export {PartnerEditBody}
