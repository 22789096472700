import { ReportBookingTypeListToolbar } from './ReportBookingTypeListToolbar'

const ReportBookingTypeListHeader = () => {
  return (
    <div className='border-0'>
      <div className='card-toolbar'>
        <ReportBookingTypeListToolbar />
      </div>
    </div>
  )
}

export { ReportBookingTypeListHeader }

