import {axios} from '@/_metronic/helpers/network/Axios'
import {AxiosResponse} from 'axios'
import {FilterReportSalesBudget} from '../../dashboard-forecast/core/_models'
import {DashboardSalesBudgetMapResponse} from './_model'

const REPORT_API_URL = '/report-services'
const GET_REPORT_SALES_VS_BUDGET_MAP = '/dashboard/sales-vs-budget/map'

const getReportSalesBudgetMap = (
  body: FilterReportSalesBudget
): Promise<DashboardSalesBudgetMapResponse> => {
  return axios
    .post(`${REPORT_API_URL}${GET_REPORT_SALES_VS_BUDGET_MAP}`, body)
    .then((d: AxiosResponse<DashboardSalesBudgetMapResponse>) => d.data)
}

export {getReportSalesBudgetMap}
