import { axios } from '@/_metronic/helpers/network/Axios'
import { AxiosResponse } from 'axios'
import { FilterReportRevenueSharing, ReportRevenueSharingQueryResponse } from './_models'

const REPORT_API_URL = '/report-services'
const GET_REPORT_REVENUE_SAHRING_LIST = '/dashboard/revenue-sharing'

const getReportRevenueSharing = (
  body: FilterReportRevenueSharing
): Promise<ReportRevenueSharingQueryResponse> => {
  return axios
    .post(`${REPORT_API_URL}${GET_REPORT_REVENUE_SAHRING_LIST}`, body)
    .then((d: AxiosResponse<ReportRevenueSharingQueryResponse>) => d.data)
}

export { getReportRevenueSharing }

