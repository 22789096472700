import { BaseRequest } from '@/_metronic/helpers/crud-helper/base-request'
import {
  DropdownSelectAsyncPaginate,
  OptionType,
} from '@/_metronic/partials/content/dropdown-select/DropdownSelectAsyncPaginate'
import { FilterProvince } from '@/app/core/commons/province/_models'
import { getProvince } from '@/app/core/commons/province/_requests'
import { useAuth } from '@/app/modules/auth'
import { FC, useEffect, useState } from 'react'
import { MultiValue } from 'react-select'

type Props = {
  currentValue: OptionType | MultiValue<OptionType> | null
  onValueChange: any
}

const FilterDropdownRegion: FC<Props> = ({currentValue, onValueChange}) => {
  const [_, onChange] = useState<OptionType | MultiValue<OptionType> | null>(currentValue || null)
  const {currentUser} = useAuth()

  const loadOptionsBrands = async (search: string, page: number) => {
    try {
      const body = {
        filter: {
          set_provinsi_id: false,
          provinsi_id: '',
          set_name: search.length > 0 ? true : false,
          name: search,
        },
        page: page,
        limit: 10,
        sort: 'ASC',
        order: 'name',
      } as BaseRequest<FilterProvince>

      const resp = await getProvince(body)
      if (resp?.response?.data) {
        const {data} = resp.response
        return {
          options: data?.map((x) => ({label: x.name, value: x.provinsi_id})),
          hasMore: page < (resp?.response?.total_page || 0),
        }
      }

      return {options: [], hasMore: false, additional: page}
    } catch (error) {
      return {
        options: [],
        hasMore: false,
      }
    }
  }

  const loadOptions: any = async (q, _, additional) => {
    const {page} = additional!
    const {options: responseOptions, hasMore} = await loadOptionsBrands(q, page)

    return {
      options: responseOptions,
      hasMore,
      additional: {
        page: page + 1,
      },
    }
  }

  useEffect(() => {
    onValueChange(currentValue)
  }, [currentValue])

  return (
    <DropdownSelectAsyncPaginate
      key={'region'}
      placeholder='All Region'
      loadOptions={loadOptions}
      onChange={(newValues) => onValueChange(newValues)}
      value={currentValue}
      isClearable
      isMulti
    />
  )
}

export { FilterDropdownRegion }

