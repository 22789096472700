/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {PartnerOrganization} from '../../../core/_models'

type Props = {
  partnerOrganization: PartnerOrganization
}

const PartnerPersonInChargeCell: FC<Props> = ({partnerOrganization}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <span className='text-gray-800 mb-1'>
        {partnerOrganization.pic_partner_organization?.surname}{' '}
        {partnerOrganization.pic_partner_organization?.full_name}
      </span>
      <span className='fst-italic text-muted'>
        {partnerOrganization.pic_partner_organization?.job_title}
      </span>
    </div>
  </div>
)

export {PartnerPersonInChargeCell}
