import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {PartnersListLoading} from '../components/loading/PartnersListLoading'
import {PartnersListPagination} from '../components/pagination/PartnersListPagination'
import {KTCardBody} from '../../../../../_metronic/helpers'
import {partnerOrganizerColumns} from './columns/_columns'
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
} from '../../core/QueryResponseProvider'
import {PartnerOrganization} from '../../core/_models'

const PartnersTable = () => {
  const partnerOrganizer = useQueryResponseData()
  const response = useQueryResponse()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => partnerOrganizer, [partnerOrganizer])
  const columns = useMemo(() => partnerOrganizerColumns, [])
  const current_page = useMemo(
    () => response.response?.response?.current_page,
    [response.response?.response?.current_page]
  )
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
    current_page,
  })

  return (
    <KTCardBody className='py-4 shadow-wit-2'>
      <div className='table-responsive'>
        <table
          id='kt_table_partnerOrganizer'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<PartnerOrganization>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-dark mb-1 fs-6' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<PartnerOrganization>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <PartnersListPagination />
      {isLoading && <PartnersListLoading />}
    </KTCardBody>
  )
}

export {PartnersTable}
