import { WithChildren } from '@/_metronic/helpers'
import { createContext, FC, useContext, useState } from 'react'
import { FilterReportSalesBudget, initialFilterDaily } from './_models'

type ProductQueryRequestDailyContextProps = {
  state: FilterReportSalesBudget
  updateState: (updates: Partial<FilterReportSalesBudget>) => void
}

const initialQueryRequestDaily = {
  state: initialFilterDaily,
  updateState: () => {},
}

const QueryRequestDailyContext = createContext<ProductQueryRequestDailyContextProps>(initialQueryRequestDaily)

const QueryRequestDailyProvider: FC<WithChildren> = ({children}) => {
  const [state, setState] = useState<FilterReportSalesBudget>(initialQueryRequestDaily.state)

  const updateState = (updates: Partial<FilterReportSalesBudget>) => {
    const updatedState = {...state, ...updates} as FilterReportSalesBudget
    setState(updatedState)
  }

  return (
    <QueryRequestDailyContext.Provider value={{state, updateState}}>
      {children}
    </QueryRequestDailyContext.Provider>
  )
}

const useQueryRequestDaily = () => useContext(QueryRequestDailyContext)
export { QueryRequestDailyProvider, useQueryRequestDaily }

