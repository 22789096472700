import {axios} from '@/_metronic/helpers/network/Axios'
import {AxiosResponse} from 'axios'
import {FilterRatingByTherapistRequest, ListRatingByTherapistResponse} from './_models'

const BOOKING_URL = '/booking-services'
const RATING_THERAPIST_LIST_URL = `${BOOKING_URL}/booking/rating/employee`

const getRatingByTherapist = (
  body: FilterRatingByTherapistRequest
): Promise<ListRatingByTherapistResponse> => {
  return axios
    .post(RATING_THERAPIST_LIST_URL, body)
    .then((d: AxiosResponse<ListRatingByTherapistResponse>) => d.data)
}

export {getRatingByTherapist}
