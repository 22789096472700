import React from 'react'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
import {useIntl} from 'react-intl'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' />
      <MenuItem title='Transaction History' to='/apps/transaction-history' />
      <MenuItem title='Transaction Report' to='/apps/report-transaction' />
      <MenuItem title='Closing Shift History' to='/apps/closing-shift-history' />
    </>
  )
}
