import {BaseResponse} from '@/_metronic/helpers/crud-helper/base-list-response'
import {BaseRequest} from '@/_metronic/helpers/crud-helper/base-request'
import {axios} from '@/_metronic/helpers/network/Axios'
import {AxiosResponse} from 'axios'
import {
  FilterProductPrice,
  FilterVariantMobile,
  ProductPrice,
  ProductTreatmentRequest,
  ProductVariantMobileResponse,
} from './_models'

const PRODUCT_URL = '/product-services'
const PRODUCT_PRICE_URL = `${PRODUCT_URL}/product/price/list`
const PRODUCT_PRICE_VARIANT_URL = `${PRODUCT_URL}/product/variant/mobile/list`
const ORDER_URL = '/booking-services'
const ORDER_BOOKING_URL = `${ORDER_URL}/booking/`
const PRODUCT_VARIANT_MOBILE_URL = `${PRODUCT_URL}/product/variant/mobile/list`
const AUTH_URL = '/authorization'
const REQUEST_FORGOT_URL = `${AUTH_URL}/authentication/forgot-password-request`

export const getProductPrice = (
  body: BaseRequest<FilterProductPrice>
): Promise<BaseResponse<Array<ProductPrice>>> => {
  return axios
    .post<BaseResponse<Array<ProductPrice>>>(PRODUCT_PRICE_URL, body)
    .then((d: AxiosResponse<BaseResponse<Array<ProductPrice>>>) => d.data)
}

export const getProductTreatments = (
  body: ProductTreatmentRequest
): Promise<BaseResponse<Array<ProductPrice>>> => {
  return axios
    .post<BaseResponse<Array<ProductPrice>>>(PRODUCT_PRICE_VARIANT_URL, body)
    .then((d) => d.data)
}

export const createOrder = (body: any): Promise<BaseResponse<any>> => {
  return axios
    .post<BaseResponse<any>>(ORDER_BOOKING_URL, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

export const getProductVariantMobile = (
  body: FilterVariantMobile
): Promise<ProductVariantMobileResponse> => {
  return axios
    .post<ProductVariantMobileResponse>(PRODUCT_VARIANT_MOBILE_URL, body)
    .then((d: AxiosResponse<ProductVariantMobileResponse>) => d.data)
}

export const requestResetPassword = (body: any): Promise<BaseResponse<any>> => {
  return axios.post(REQUEST_FORGOT_URL, body).then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}
