import { ReportRevenueSharingListToolbar } from './ReportRevenueSharingListToolbar'

const ReportRevenueSharingListHeader = () => {
  return (
    <div className='border-0'>
      <div className='card-toolbar'>
        <ReportRevenueSharingListToolbar />
      </div>
    </div>
  )
}

export { ReportRevenueSharingListHeader }

