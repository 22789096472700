import { BaseRequest } from '@/_metronic/helpers/crud-helper/base-request'
import { FilterCms, CmsQueryResponse, Cms } from './_models'
import {AxiosResponse} from 'axios'
import {axios} from '@/_metronic/helpers/network/Axios'
import { BaseResponse } from '@/_metronic/helpers/crud-helper/base-list-response'

const OUTET_API_URL = '/outlet-services'
const GET_CMS = '/cms/list'
const GET_CMS_BY_ID_URL = '/cms'

const getCmss = (
  body: BaseRequest<FilterCms>
): Promise<CmsQueryResponse> => {
  return axios
    .post(`${OUTET_API_URL}${GET_CMS}`, body)
    .then((d: AxiosResponse<CmsQueryResponse>) => d.data)
}

const getCmsById = (id: string | undefined): Promise<BaseResponse<Cms>> => {
  return axios
    .get(`${OUTET_API_URL}${GET_CMS_BY_ID_URL}/detail/${id}`)
    .then((d: AxiosResponse<BaseResponse<Cms>>) => d.data)
}

const createCms = (body: Cms): Promise<BaseResponse<any>> => {
  return axios
    .post(`${OUTET_API_URL}${GET_CMS_BY_ID_URL}`, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const updateCms = (id: string, body: Cms): Promise<BaseResponse<any>> => {
  return axios
    .put(`${OUTET_API_URL}${GET_CMS_BY_ID_URL}/${id}`, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const deleteCms = (id: string): Promise<BaseResponse<any>> => {
  return axios
    .delete(`${OUTET_API_URL}${GET_CMS_BY_ID_URL}/${id}`)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

export {getCmss, createCms, getCmsById, updateCms, deleteCms}