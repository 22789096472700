
import ApexCharts, { ApexOptions } from 'apexcharts'
import React, { useEffect, useRef } from 'react'
import { getCSS, getCSSVariableValue } from '../../../../../_metronic/assets/ts/_utils'
import { useThemeMode } from '../../../../../_metronic/partials'
import { TrendCategory } from '../../core/_models'

type Props = {
  className: string
  title: string
  color: string
  height?: number
  series?: any
  categories?: any
  isCurrency?: boolean
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.')
}

const CategoryTreatmentBarChart: React.FC<Props> = ({
  className,
  color = 'primary',
  height = 600,
  title = '',
  series = [],
  categories = [],
  isCurrency = true, }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()
  const refreshMode = () => {
    if (!chartRef.current) {
      return
    }

    // const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(((categories ?? []).length * 54.55), color, series, categories, isCurrency))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, series])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3'>{title}</span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body mb-17' style={{ overflowY: 'auto', height: '400px', paddingTop: 0 }}>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{ height: '100%' }}></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export { CategoryTreatmentBarChart }

function getChartOptions(
  height: number,
  color: string,
  series: any,
  categories,
  isCurrency: boolean
): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue(`--bs-${color}`)
  const secondaryColor = getCSSVariableValue(`--bs-gray-300`)

  return {
    series: series,
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height ?? 300,
      // height: 350,
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: '30%',
        barHeight: '50%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
        formatter: function (val) {
          return isCurrency ? 'Rp. ' + numberWithCommas(val) : '' + numberWithCommas(val)
        },
      },
    },
    yaxis: {
      labels: {
        align: 'left',
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return isCurrency ? 'Rp. ' + numberWithCommas(val) : '' + numberWithCommas(val)
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}
