import {BarChart} from '@/_metronic/partials/content/chart/BarChart'
import {useEffect, useState} from 'react'
import {InventoryAsset} from '../apps/dashboard/components/InventoryAsset'
import TopCities from './components/TopCities'
import TopOutlets from './components/TopOutlets'
import {useQueryResponse, useQueryResponseData, useQueryResponseLoading} from './core/QueryResponseProvider'

const Overview = () => {

  const isLoading = useQueryResponseLoading()
  const response = useQueryResponse()
  const data = useQueryResponseData()
  //Revenue Sharing
  const [arrayOfSeriesRevenueSharingRevenueOutlet, setArrayOfSeriesRevenueSharingRevenueOutlet] =
    useState<number[]>([])
  const [arrayOfSeriesRevenueSharingRevenuePartner, setArrayOfSeriesRevenueSharingRevenuePartner] =
    useState<number[]>([])
  const [arrayOfSeriesRevenueSharingServiceOutlet, setArrayOfSeriesRevenueSharingServiceOutlet] =
    useState<number[]>([])
  const [arrayOfSeriesRevenueSharingServicePartner, setArrayOfSeriesRevenueSharingServicePartner] =
    useState<number[]>([])
  const [arrayOfLabelsRevenueSharing, setArrayOfLabelsRevenueSharing] = useState<string[]>([])

  // WIB
  const [arrayOfSeriesWibServiceOutlet, setArrayOfSeriesWibServiceOutlet] = useState<number[]>([])
  const [arrayOfSeriesWibServicePartner, setArrayOfSeriesWibServicePartner] = useState<number[]>([])
  const [arrayOfLabelsWib, setArrayOfLabelsWib] = useState<string[]>([])

  // WITA
  const [arrayOfSeriesWitaServiceOutlet, setArrayOfSeriesWitaServiceOutlet] = useState<number[]>([])
  const [arrayOfSeriesWitaServicePartner, setArrayOfSeriesWitaServicePartner] = useState<number[]>(
    []
  )
  const [arrayOfLabelsWita, setArrayOfLabelsWita] = useState<string[]>([])

  // WIT
  const [arrayOfSeriesWitServiceOutlet, setArrayOfSeriesWitServiceOutlet] = useState<number[]>([])
  const [arrayOfSeriesWitServicePartner, setArrayOfSeriesWitServicePartner] = useState<number[]>([])
  const [arrayOfLabelsWit, setArrayOfLabelsWit] = useState<string[]>([])

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.')
  }

  useEffect(() => {
    if (data) {
      //Revenue Sharing
      setArrayOfSeriesRevenueSharingRevenueOutlet([])
      setArrayOfSeriesRevenueSharingRevenuePartner([])
      setArrayOfSeriesRevenueSharingServiceOutlet([])
      setArrayOfSeriesRevenueSharingServicePartner([])
      setArrayOfLabelsRevenueSharing([])
      if (data?.revenue_sharing) {
        const arrayOfLabelsRevenueSharingString = data?.revenue_sharing?.map((obj) =>
          obj.periode ? obj.periode : ''
        )
        setArrayOfLabelsRevenueSharing(arrayOfLabelsRevenueSharingString)
        const arrayOfSeriesRevenueSharingRevenueOutletString = data?.revenue_sharing?.map((obj) =>
          obj.revenue_share_outlet ? obj.revenue_share_outlet : 0
        )
        setArrayOfSeriesRevenueSharingRevenueOutlet(arrayOfSeriesRevenueSharingRevenueOutletString)
        const arrayOfSeriesRevenueSharingServiceOutletString = data?.revenue_sharing?.map((obj) =>
          obj.service_share_outlet ? obj.service_share_outlet : 0
        )
        setArrayOfSeriesRevenueSharingServiceOutlet(arrayOfSeriesRevenueSharingServiceOutletString)
        const arrayOfSeriesRevenueSharingServicePartnerString = data?.revenue_sharing?.map((obj) =>
          obj.service_share_partner ? obj.service_share_partner : 0
        )
        setArrayOfSeriesRevenueSharingServicePartner(
          arrayOfSeriesRevenueSharingServicePartnerString
        )

        const arrayOfSeriesRevenueSharingRevenuePartnerString = data?.revenue_sharing?.map(
          (obj, index) => {
            return obj.revenue_share_partner ? obj.revenue_share_partner : 0
          }
        )
        setArrayOfSeriesRevenueSharingRevenuePartner(
          arrayOfSeriesRevenueSharingRevenuePartnerString
        )

        //WIB
        setArrayOfSeriesWibServiceOutlet([])
        setArrayOfSeriesWibServicePartner([])
        setArrayOfLabelsWib([])
        if (data?.revenue_sharing_wib) {
          const arrayOfLabelsWibString = data?.revenue_sharing_wib?.map((obj) =>
            obj.periode ? obj.periode : ''
          )
          setArrayOfLabelsWib(arrayOfLabelsWibString)
          const arrayOfSeriesWibServiceOutletString = data?.revenue_sharing_wib?.map((obj) =>
            obj.service_share_outlet ? obj.service_share_outlet : 0
          )
          setArrayOfSeriesWibServiceOutlet(arrayOfSeriesWibServiceOutletString)
          const arrayOfSeriesWibServicePartnerString = data?.revenue_sharing_wib?.map((obj) =>
            obj.service_share_partner ? obj.service_share_partner : 0
          )
          setArrayOfSeriesWibServicePartner(arrayOfSeriesWibServicePartnerString)
        }

        //WITA
        setArrayOfSeriesWitaServiceOutlet([])
        setArrayOfSeriesWitaServicePartner([])
        setArrayOfLabelsWita([])
        if (data?.revenue_sharing_wita) {
          const arrayOfLabelsWitaString = data?.revenue_sharing_wita?.map((obj) =>
            obj.periode ? obj.periode : ''
          )
          setArrayOfLabelsWita(arrayOfLabelsWitaString)
          const arrayOfSeriesWitaServiceOutletString = data?.revenue_sharing_wita?.map((obj) =>
            obj.service_share_outlet ? obj.service_share_outlet : 0
          )
          setArrayOfSeriesWitaServiceOutlet(arrayOfSeriesWitaServiceOutletString)
          const arrayOfSeriesWitaServicePartnerString = data?.revenue_sharing_wita?.map((obj) =>
            obj.service_share_partner ? obj.service_share_partner : 0
          )
          setArrayOfSeriesWitaServicePartner(arrayOfSeriesWitaServicePartnerString)
        }

        //WIT
        setArrayOfSeriesWitServiceOutlet([])
        setArrayOfSeriesWitServicePartner([])
        setArrayOfLabelsWit([])
        if (data?.revenue_sharing_wit) {
          const arrayOfLabelsWitString = data?.revenue_sharing_wit?.map((obj) =>
            obj.periode ? obj.periode : ''
          )
          setArrayOfLabelsWit(arrayOfLabelsWitString)
          const arrayOfSeriesWitServiceOutletString = data?.revenue_sharing_wit?.map((obj) =>
            obj.service_share_outlet ? obj.service_share_outlet : 0
          )
          setArrayOfSeriesWitServiceOutlet(arrayOfSeriesWitServiceOutletString)
          const arrayOfSeriesWitServicePartnerString = data?.revenue_sharing_wit?.map((obj) =>
            obj.service_share_partner ? obj.service_share_partner : 0
          )
          setArrayOfSeriesWitServicePartner(arrayOfSeriesWitServicePartnerString)
        }
      }
    }
  }, [data])

  return (
    <>
      <div className='mt-8'>
        <h4>Summary Information</h4>
        <div className='row'>
          <div className='col'>
            <InventoryAsset
              className='card-xl mb-xl-8'
              // className='card-xl-stretch mb-xl-8'
              svgIcon='chart-simple'
              color='white'
              iconColor='primary'
              title={
                data && data?.card && data?.card?.revenue
                  ? 'Rp ' + numberWithCommas(data?.card?.revenue)
                  : 'Rp. 0'
              }
              description='Revenue'
            />
          </div>
          <div className='col'>
            <InventoryAsset
              className='card-xl mb-xl-8'
              // className='card-xl-stretch mb-xl-8'
              svgIcon='bank'
              color='white'
              iconColor='primary'
              title={
                data && data?.card && data?.card?.tax
                  ? 'Rp ' + numberWithCommas(data?.card?.tax)
                  : 'Rp. 0'
              }
              description='Taxes'
            />
          </div>
          <div className='col'>
            <InventoryAsset
              className='card-xl mb-xl-8'
              // className='card-xl-stretch mb-xl-8'
              svgIcon='briefcase'
              color='white'
              iconColor='primary'
              title={
                data && data?.card && data?.card?.service
                  ? 'Rp ' + numberWithCommas(data?.card?.service)
                  : 'Rp. 0'
              }
              description='Services'
            />
          </div>
          <div className='col'>
            <InventoryAsset
              className='card-xl mb-xl-8'
              // className='card-xl-stretch mb-xl-8'
              svgIcon='chart-pie-simple'
              color='white'
              iconColor='primary'
              title={
                data && data?.card && data?.card?.revenue_after_tax_service
                  ? 'Rp ' + numberWithCommas(data?.card?.revenue_after_tax_service)
                  : 'Rp. 0'
              }
              description='Revenue After Taxes Services'
            />
          </div>
        </div>
        <div className='d-flex row mt-5'>
          <div className='col'>
            <InventoryAsset
              className='card-xl mb-xl-8'
              // className='card-xl-stretch mb-xl-8'
              svgIcon='chart-simple'
              color='white'
              iconColor='primary'
              title={
                data && data?.card && data?.card_share?.revenue_share_outlet
                  ? 'Rp ' + numberWithCommas(data?.card_share?.revenue_share_outlet)
                  : 'Rp. 0'
              }
              description='Revenue Sharing to Outlet'
            />
          </div>
          <div className='col'>
            <InventoryAsset
              className='card-xl mb-xl-8'
              // className='card-xl-stretch mb-xl-8'
              svgIcon='bank'
              color='white'
              iconColor='primary'
              title={
                data && data?.card && data?.card_share?.revenue_share_partner
                  ? 'Rp ' + numberWithCommas(data?.card_share?.revenue_share_partner)
                  : 'Rp. 0'
              }
              description='Revenue Sharing to Hotel'
            />
          </div>
          <div className='col'>
            <InventoryAsset
              className='card-xl mb-xl-8'
              // className='card-xl-stretch mb-xl-8'
              svgIcon='briefcase'
              color='white'
              iconColor='primary'
              title={
                data && data?.card && data?.card_share?.service_share_outlet
                  ? 'Rp ' + numberWithCommas(data?.card_share?.service_share_outlet)
                  : 'Rp. 0'
              }
              description='Service Charge to Outlet'
            />
          </div>
          <div className='col'>
            <InventoryAsset
              className='card-xl mb-xl-8'
              // className='card-xl-stretch mb-xl-8'
              svgIcon='chart-pie-simple'
              color='white'
              iconColor='primary'
              title={
                data && data?.card && data?.card_share?.service_share_partner
                  ? 'Rp ' + numberWithCommas(data?.card_share?.service_share_partner)
                  : 'Rp. 0'
              }
              description='Service Charge to Hotel'
            />
          </div>
        </div>
      </div>

      <div className='col-xl-12 g-5 g-xl-8 pt-10'>
        <BarChart
          className='area-wib shadow-wit'
          color='success'
          title='Revenue Sharing History'
          subtitle=''
          height='400px'
          series={[
            {
              name: 'Outlet',
              data: arrayOfSeriesRevenueSharingRevenueOutlet,
            },
            {
              name: 'Hotel',
              data: arrayOfSeriesRevenueSharingRevenuePartner,
            },
          ]}
          categories={arrayOfLabelsRevenueSharing}
          total={0}
          isTotal={false}
          data={data?.revenue_sharing?.map((x) => ({
            month: x.periode,
            revenue_share_outlet: x.revenue_share_outlet,
            revenue_share_partner: x.revenue_share_partner,
          }))}
          headers={['Periode', 'Revenue Sharing Outlet', 'Revenue Sharing Partner']}
          formatCurrency={['revenue_share_outlet', 'revenue_share_partner']}
          isLoading={isLoading || (response.response?.response as any) === 'no result data'}
        />
        <BarChart
          className='mt-10 area-wib shadow-wit'
          color='primary'
          title='Service Sharing History'
          subtitle=''
          height='400px'
          series={[
            {
              name: 'Outlet',
              data: arrayOfSeriesRevenueSharingServiceOutlet,
            },
            {
              name: 'Hotel',
              data: arrayOfSeriesRevenueSharingServicePartner,
            },
          ]}
          categories={arrayOfLabelsRevenueSharing}
          total={0}
          isTotal={false}
          data={data?.revenue_sharing?.map((x) => ({
            month: x.periode,
            service_share_outlet: x.service_share_outlet,
            service_share_partner: x.service_share_partner,
          }))}
          headers={['Periode', 'Service Sharing Outlet', 'Service Sharing Partner']}
          formatCurrency={['service_share_outlet', 'service_share_partner']}
          isLoading={isLoading || (response.response?.response as any) === 'no result data'}
        />
        <BarChart
          className='mt-10 area-wib shadow-wit'
          color='info'
          title='Revenue & Service Sharing'
          subtitle='Area WIB'
          height='400px'
          series={[
            {
              name: 'Outlet',
              data: arrayOfSeriesWibServiceOutlet,
            },
            {
              name: 'Hotel',
              data: arrayOfSeriesWibServicePartner,
            },
          ]}
          categories={arrayOfLabelsWib}
          total={0}
          isTotal={false}
          data={data?.revenue_sharing_wib?.map((x) => ({
            month: x.periode,
            revenue_share_outlet: x.revenue_share_outlet,
            revenue_share_partner: x.revenue_share_partner,
            service_share_outlet: x.service_share_outlet,
            service_share_partner: x.service_share_partner,
          }))}
          headers={[
            'Periode',
            'Revenue Sharing Outlet',
            'Revenue Sharing Partner',
            'Service Sharing Outlet',
            'Service Sharing Partner',
          ]}
          formatCurrency={[
            'revenue_share_outlet',
            'revenue_share_partner',
            'service_share_outlet',
            'service_share_partner',
          ]}
          isLoading={isLoading || (response.response?.response as any) === 'no result data'}
        />
        <BarChart
          className='area-wib mt-10 shadow-wit'
          color='warning'
          title='Revenue & Service Sharing'
          subtitle='Area WITA'
          height='400px'
          series={[
            {
              name: 'Outlet',
              data: arrayOfSeriesWitaServiceOutlet,
            },
            {
              name: 'Hotel',
              data: arrayOfSeriesWitaServicePartner,
            },
          ]}
          categories={arrayOfLabelsWita}
          total={0}
          isTotal={false}
          data={data?.revenue_sharing_wita?.map((x) => ({
            month: x.periode,
            revenue_share_outlet: x.revenue_share_outlet,
            revenue_share_partner: x.revenue_share_partner,
            service_share_outlet: x.service_share_outlet,
            service_share_partner: x.service_share_partner,
          }))}
          headers={[
            'Periode',
            'Revenue Sharing Outlet',
            'Revenue Sharing Partner',
            'Service Sharing Outlet',
            'Service Sharing Partner',
          ]}
          formatCurrency={[
            'revenue_share_outlet',
            'revenue_share_partner',
            'service_share_outlet',
            'service_share_partner',
          ]}
          isLoading={isLoading || (response.response?.response as any) === 'no result data'}
        />
        <BarChart
          className='area-wib mt-10 shadow-wit'
          color='primary'
          title='Revenue & Service Sharing'
          subtitle='Area WIT'
          height='400px'
          series={[
            {
              name: 'Outlet',
              data: arrayOfSeriesWitServiceOutlet,
            },
            {
              name: 'Hotel',
              data: arrayOfSeriesWitServicePartner,
            },
          ]}
          categories={arrayOfLabelsWit}
          total={0}
          isTotal={false}
          data={data?.revenue_sharing_wit?.map((x) => ({
            month: x.periode,
            revenue_share_outlet: x.revenue_share_outlet,
            revenue_share_partner: x.revenue_share_partner,
            service_share_outlet: x.service_share_outlet,
            service_share_partner: x.service_share_partner,
          }))}
          headers={[
            'Periode',
            'Revenue Sharing Outlet',
            'Revenue Sharing Partner',
            'Service Sharing Outlet',
            'Service Sharing Partner',
          ]}
          formatCurrency={[
            'revenue_share_outlet',
            'revenue_share_partner',
            'service_share_outlet',
            'service_share_partner',
          ]}
          isLoading={isLoading || (response.response?.response as any) === 'no result data'}
        />
      </div>

      <div className='row g-5 g-xl-8 pt-10'>
        <div className='col-xl-6'>
          <TopOutlets data={data && data?.revenue_top_outlet && data?.revenue_top_outlet} />
        </div>
        <div className='col-xl-6'>
          <TopCities data={data && data?.revenue_top_city && data?.revenue_top_city} />
        </div>
      </div>
    </>
  )
}

export default Overview
