import {WithChildren} from '@/_metronic/helpers'
import {createContext, FC, useContext, useState} from 'react'
import {Outlet} from '../../outlet/core/_models'
import {OutletWithStatus} from './_models'

type BookOrderContextProps = {
  outlet: OutletWithStatus | null | undefined
  updateOutlet: (outlet: OutletWithStatus) => void
  date: any
  setDate: (date: any) => void
}

const BookOrderContext = createContext<BookOrderContextProps>({
  outlet: null,
  updateOutlet: () => {},
  date: null,
  setDate: () => {},
})

const BookOrderProvider: FC<WithChildren> = ({children}) => {
  const [outlet, setOutlet] = useState<OutletWithStatus | undefined | null>(null)
  const [date, setDate] = useState<any>(null)
  const updateOutlet = (outlet: OutletWithStatus) => {
    setOutlet(outlet)
  }
  return (
    <BookOrderContext.Provider
      value={{
        outlet,
        updateOutlet,
        date,
        setDate,
      }}
    >
      {children}
    </BookOrderContext.Provider>
  )
}

const useBookOrder = () => useContext(BookOrderContext)

export {BookOrderProvider, useBookOrder}
