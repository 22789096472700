import {isNotEmpty, QUERIES} from '@/_metronic/helpers'
import {InputGroup} from '@/_metronic/partials/content/input-group/InputGroup'
import {useFormik} from 'formik'
import {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import * as Yup from 'yup'
import {DropdownSelect} from '@/_metronic/partials/content/dropdown-select/DropdownSelect'
import {
  createPartnerOrganization,
  getPartnerOrganizationById,
  updatePartnerOrganization,
} from '../../../core/_requests'

const partnerOrganizationScheme = Yup.object().shape({
  organization_name: Yup.string().required('Organization name tidak boleh kosong'),
  name: Yup.string().required('Hotel name tidak boleh kosong'),
  address: Yup.string().required('Address tidak boleh kosong'),
  phone_number: Yup.string().required('Phone number tidak boleh kosong'),
  email: Yup.string().required('Email tidak boleh kosong'),
  brand_cluster: Yup.string().required('Brand cluster tidak boleh kosong'),
})

const partnerOrganizationInitialValue = {
  organization_name: '',
  name: '',
  address: '',
  phone_number: '',
  email: '',
  brand_cluster: '',
}

const optionsBrandCluster = [
  {value: '1', label: '1'},
  {value: '2', label: '2'},
  {value: '3', label: '3'},
  {value: '4', label: '4'},
  {value: '5', label: '5'},
]

const GeneralInformationForm = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const {id} = useParams()
  const enabledQuery: boolean = isNotEmpty(id)

  const [isReadOnly, setIsReadOnly] = useState<boolean>(false)

  const {data: response} = useQuery(
    `${QUERIES.PARTNER_ORGANIZER_LIST}-partner-organizer-${id}`,
    () => {
      return getPartnerOrganizationById(id)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
    }
  )

  const {handleChange, handleBlur, values, errors, handleSubmit, setValues} = useFormik({
    validationSchema: partnerOrganizationScheme,
    initialValues: partnerOrganizationInitialValue,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      console.log('dasda')
      if (!id) {
        createPartnerOrganization(values)
          .then((data) => {
            navigate('/apps/partner-organization/partner/list')
          })
          .catch((err) => console.error(err))
        return
      }

      updatePartnerOrganization(id, values)
        .then((data) => {
          navigate('/apps/partner-organization/partner/list')
        })
        .catch((err) => console.error(err))
    },
  })

  useEffect(() => {
    if (response) {
      const data = response?.response?.data
      setValues({
        organization_name: data?.organization_name || '',
        name: data?.name || '',
        address: data?.address || '',
        phone_number: data?.phone_number || '',
        email: data?.email || '',
        brand_cluster: data?.brand_cluster || '',
      })
    }
  }, [response])

  useEffect(() => {
    if (location?.pathname?.includes('/detail')) {
      setIsReadOnly(true)
    }
  }, [location])

  return (
    <form className='form d-flex flex-column flex-lg-row' onSubmit={handleSubmit}>
      <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
        <div className='card card-flush py-4'>
          <div className='card-header'>
            <div className='card-title'>
              <h2>General</h2>
            </div>
          </div>
          <div className='card-body pt-0'>
            <div className='row'>
              <div className='col-6 mb-10'>
                <InputGroup
                  label='Organization Name'
                  isValid={!Boolean(errors?.organization_name)}
                  errorMessage={errors?.organization_name}
                  value={values.organization_name}
                  onChange={handleChange('organization_name')}
                  onBlur={handleBlur('organization_name')}
                  disabled={isReadOnly}
                />
              </div>
              <div className='col-6 mb-10'>
                <InputGroup
                  label='Hotel Name'
                  isValid={!Boolean(errors?.name)}
                  errorMessage={errors?.name}
                  value={values.name}
                  onChange={handleChange('name')}
                  onBlur={handleBlur('name')}
                  disabled={isReadOnly}
                />
              </div>
              <div className='col-6 mb-10'>
                <InputGroup
                  label='Address'
                  isValid={!Boolean(errors?.address)}
                  errorMessage={errors?.address}
                  value={values.address}
                  onChange={handleChange('address')}
                  onBlur={handleBlur('address')}
                  disabled={isReadOnly}
                />
              </div>
              <div className='col-6 mb-10'>
                <InputGroup
                  label='Phone Number'
                  isValid={!Boolean(errors?.phone_number)}
                  errorMessage={errors?.phone_number}
                  value={values.phone_number}
                  onChange={handleChange('phone_number')}
                  onBlur={handleBlur('phone_number')}
                  disabled={isReadOnly}
                />
              </div>
              <div className='col-6 mb-10'>
                <InputGroup
                  label='Email'
                  isValid={!Boolean(errors?.email)}
                  errorMessage={errors?.email}
                  value={values.email}
                  onChange={handleChange('email')}
                  onBlur={handleBlur('email')}
                  disabled={isReadOnly}
                />
              </div>
              <div className='col-6 mb-10'>
                <label className='form-label required'>Brand Cluster</label>
                <DropdownSelect
                  options={optionsBrandCluster}
                  isValid={!Boolean(errors?.brand_cluster)}
                  errorMessage={errors?.brand_cluster}
                  placeholder='Select Brand Cluster'
                  isDisabled={isReadOnly}
                  value={
                    values.brand_cluster
                      ? {label: values.brand_cluster, value: values.brand_cluster}
                      : null
                  }
                  onChange={(e: any) => setValues({...values, brand_cluster: e.label})}
                />
              </div>
            </div>
          </div>
        </div>
        {!isReadOnly && (
          <div className='d-flex justify-content-end'>
            <Link to='/apps/partner-organization/partner/list'>
              <a href='#' className='btn btn-light me-5'>
                Cancel
              </a>
            </Link>
            <button type='button' className='btn btn-primary' onClick={() => handleSubmit()}>
              Save Changes
            </button>
          </div>
        )}
      </div>
    </form>
  )
}

export {GeneralInformationForm}
