import { axios } from '@/_metronic/helpers/network/Axios'
import { AxiosResponse } from 'axios'
import { FilterReportTrendAnalysis, ReportTrendAnalysisQueryResponse } from './_models'

const REPORT_API_URL = '/report-services'
const GET_REPORT_TREND_ANALYSIS_LIST = '/dashboard/trend-analysis'

const getReportTrendAnalysis = (
  body: FilterReportTrendAnalysis
): Promise<ReportTrendAnalysisQueryResponse> => {
  return axios
    .post(`${REPORT_API_URL}${GET_REPORT_TREND_ANALYSIS_LIST}`, body)
    .then((d: AxiosResponse<ReportTrendAnalysisQueryResponse>) => d.data)
}

export { getReportTrendAnalysis }

