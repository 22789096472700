export const optionsMonth = [
  { value: '1', label: 'Januari' },
  { value: '2', label: 'Februari' },
  { value: '3', label: 'Maret' },
  { value: '4', label: 'April' },
  { value: '5', label: 'Mei' },
  { value: '6', label: 'Juni' },
  { value: '7', label: 'Juli' },
  { value: '8', label: 'Agustus' },
  { value: '9', label: 'September' },
  { value: '10', label: 'Oktober' },
  { value: '11', label: 'November' },
  { value: '12', label: 'Desember' },
]

export const optionsMonthEn = [
  { value: '1', label: 'January' },
  { value: '2', label: 'February' },
  { value: '3', label: 'March' },
  { value: '4', label: 'April' },
  { value: '5', label: 'May' },
  { value: '6', label: 'June' },
  { value: '7', label: 'July' },
  { value: '8', label: 'August' },
  { value: '9', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' },
]

export const optionsYear = [
  { value: '2023', label: '2023' },
  { value: '2022', label: '2022' },
  { value: '2021', label: '2021' },
  { value: '2020', label: '2020' },
]

export const optionsOutlet = [
  { value: 'outlet_a', label: 'Outlet A' },
  { value: 'outlet_b', label: 'Outlet B' },
  { value: 'outlet_c', label: 'Outlet C' },
  { value: 'outlet_d', label: 'Outlet D' },
]

export const optionsBrand = [
  { value: 'brand_a', label: 'Brand A' },
  { value: 'brand_b', label: 'Brand B' },
  { value: 'brand_c', label: 'Brand C' },
  { value: 'brand_d', label: 'Brand D' },
]

export const optionsSubBrand = [
  { value: 'subBrand_a', label: 'Sub Brand A' },
  { value: 'subBrand_b', label: 'Sub Brand B' },
  { value: 'subBrand_c', label: 'Sub Brand C' },
  { value: 'subBrand_d', label: 'Sub Brand D' },
]

export const optionsPeriod = [
  { value: 'annualy', label: 'Annualy' },
  { value: 'monthly', label: 'Monthly' },
  { value: 'quarterly', label: 'Quarterly' },
  { value: 'semesterly', label: 'Semesterly' },
]

export const optionEmployee = [
  { value: 'Arie', label: 'Arie' },
  { value: 'Yogie', label: 'Yogie' },
  { value: 'Banu Kurniawan', label: 'Banu Kurniawan' },
  { value: 'Banu Rusman', label: 'Banu Rusman' },
]

const currentYear = new Date().getFullYear()
const years = Array.from({length: 5}, (_, index) => {
  const year = currentYear - 4 + index
  return {
    label: year.toString(),
    value: year
  }
})
export const optionYearsNow = years