/* eslint-disable jsx-a11y/anchor-is-valid */
import {useQueryRequest} from '@/app/modules/rating-by-therapist/core/QueryRequestProvider'
import {useQueryResponseData} from '@/app/modules/rating-by-therapist/core/QueryResponseProvider'
import {formatRupiah} from '@/app/utils'
import React, {useEffect} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'

const topTherapist = [
  {
    name: 'Vera Indriyani',
    outlet: 'RA Premiere Simatupang',
    sales: 50,
    jumlahOrder: 'Rp. 24.400.000',
  },
  {
    name: 'Fitri Harjati',
    outlet: 'Aston Simatupang',
    sales: 48,
    jumlahOrder: 'Rp. 20.760.000',
  },
  {
    name: 'Virna Ria Zalda',
    outlet: 'Ra Suite Simatupang',
    sales: 23,
    jumlahOrder: 'Rp. 11.900.000',
  },
  {
    name: 'Lisdawati',
    outlet: 'Mercure Jakarta Simatupang',
    sales: 20,
    jumlahOrder: 'Rp. 10.220.000',
  },
  {
    name: 'Lina',
    outlet: 'Aston Simatupang',
    sales: 15,
    jumlahOrder: 'Rp. 7.630.000',
  },
]

type Props = {
  className: string
}

const TopEmployees: React.FC<Props> = ({className}) => {
  const {updateState} = useQueryRequest()
  const data = useQueryResponseData()

  useEffect(() => {
    updateState({
      limit: 5,
      order: 'avg_limit',
      sort: 'DESC',
    })
  }, [])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Top 5 Therapist</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        ></div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body shadow-wit-2 py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-30px'>No</th>
                <th className='min-w-150px'>Name</th>
                <th className='min-w-100px'>Outlet</th>
                <th className='min-w-100px'>Total Treatment</th>
                <th className='min-w-100px'>Revenue</th>
                <th className='min-w-100px text-end'>Rating</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody className='text-dark mb-1 fs-6'>
              {data.map((therapist, index) => (
                <tr key={index}>
                  <td>
                    <a href='#' className='text-dark fw-semibold d-block fs-6'>
                      {index + 1}
                    </a>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-45px me-5'>
                        <img src={toAbsoluteUrl('/media/logos/logo-square.png')} alt='' />
                      </div>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                          {therapist.employee?.name}
                        </a>
                      </div>
                    </div>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                      {therapist.outlet?.name}
                    </a>
                  </td>
                  <td className='text-end'>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='d-flex flex-stack mb-2'>
                        <span className='text-dark me-2 fs-7 fw-semibold'>
                          {therapist.total_qty}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <label className='text-dark fw-bold text-hover-primary d-block fs-6'>
                      Rp. {formatRupiah(therapist.total_grandtotal as number)}
                    </label>
                  </td>
                  <td>
                    <td className='float-end text-end border-0'>
                      <div className='rating'>
                        <div
                          className={`rating-label ${
                            (therapist.avg_rating as number) >= 1 ? 'checked' : ''
                          }`}
                        >
                          <i className='ki-duotone ki-star fs-6'></i>{' '}
                        </div>
                        <div
                          className={`rating-label ${
                            (therapist.avg_rating as number) >= 2 ? 'checked' : ''
                          }`}
                        >
                          <i className='ki-duotone ki-star fs-6'></i>{' '}
                        </div>
                        <div
                          className={`rating-label ${
                            (therapist.avg_rating as number) >= 3 ? 'checked' : ''
                          }`}
                        >
                          <i className='ki-duotone ki-star fs-6'></i>{' '}
                        </div>
                        <div
                          className={`rating-label ${
                            (therapist.avg_rating as number) >= 4 ? 'checked' : ''
                          }`}
                        >
                          <i className='ki-duotone ki-star fs-6'></i>{' '}
                        </div>
                        <div
                          className={`rating-label ${
                            (therapist.avg_rating as number) >= 5 ? 'checked' : ''
                          }`}
                        >
                          <i className='ki-duotone ki-star fs-6'></i>{' '}
                        </div>
                      </div>

                      <span className='text-gray-400 fw-semibold d-block fs-7 mt-1'>
                        Rating: {therapist.avg_rating}
                      </span>
                    </td>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {TopEmployees}
