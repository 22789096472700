const capitalizeFirst = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const capitalizeEachWord = (str: string) => {
  return str.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
}

const formatRupiah = (num: number): string => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

const removeDots = (text: string | undefined) => {
  return text ? text?.replaceAll('.', '') : ''
}

const formatIntl = ({ num, fraction = 9, showSign = false }): string => {
  let value = Intl.NumberFormat('in-ID', {
    style: 'currency',
    currency: 'IDR',
    maximumFractionDigits: fraction,
  }).format(num)

  if (!showSign) {
    value = value.replace('Rp', '').trim()
  }

  return value
}

export { capitalizeFirst, formatRupiah, removeDots, capitalizeEachWord, formatIntl }
