import {createContext, FC, useContext, useState} from 'react'
import {WithChildren} from '@/_metronic/helpers'
import {BaseRequest} from '@/_metronic/helpers/crud-helper/base-request'
import {FilterRatingByTherapist, initialFilterRatingByTherapist} from './_models'

type ProductQueryRequestContextProps = {
  state: BaseRequest<FilterRatingByTherapist>
  updateState: (updates: Partial<BaseRequest<FilterRatingByTherapist>>) => void
}

const initialQueryRequest = {
  state: initialFilterRatingByTherapist,
  updateState: () => {},
}

const QueryRequestContext = createContext<ProductQueryRequestContextProps>(initialQueryRequest)

const QueryRequestProvider: FC<WithChildren> = ({children}) => {
  const [state, setState] = useState<BaseRequest<FilterRatingByTherapist>>(
    initialQueryRequest.state
  )

  const updateState = (updates: Partial<BaseRequest<FilterRatingByTherapist>>) => {
    const updatedState = {...state, ...updates} as BaseRequest<FilterRatingByTherapist>
    setState(updatedState)
  }

  return (
    <QueryRequestContext.Provider value={{state, updateState}}>
      {children}
    </QueryRequestContext.Provider>
  )
}

const useQueryRequest = () => useContext(QueryRequestContext)
export {QueryRequestProvider, useQueryRequest}
