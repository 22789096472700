import { BaseResponse } from '@/_metronic/helpers/crud-helper/base-list-response'

export interface FilterReportBookingType {
  set_sub_brand_id?: boolean
  sub_brand_id?: string[]
  set_brand_id?: boolean
  brand_id?: string[]
  set_area?: boolean
  area?: string[]
  set_region_id?: boolean
  region_id?: string[]
  set_city_id?: boolean
  city_id?: string[]
  set_outlet_id?: boolean
  outlet_id?: string[]
  set_month?: boolean
  month?: string[]
  set_year?: boolean
  year?: string | null
}

export interface ReportBookingType {
  periode?: string;
  month?: number;
  total_walk_in?: number;
  percentage_total_walk_in?: number;
  total_guest_hotel?: number;
  percentage_total_guest_hotel?: number;
  total_cabin?: number;
  percentage_total_cabin?: number;
  total_in_room?: number;
  percentage_total_in_room?: number;
  total_guest_hotel_cabin?: number;
  percentage_total_guest_hotel_cabin?: number;
  total_guest_hotel_in_room?: number;
  percentage_total_guest_hotel_in_room?: number;
}

export type ReportBookingTypeQueryResponse = BaseResponse<Array<ReportBookingType>>

const currentDate = new Date()
export const initialFilter: FilterReportBookingType = {
  set_sub_brand_id: false,
  sub_brand_id: [],
  set_brand_id: false,
  brand_id: [],
  set_area: false,
  area: [],
  set_region_id: false,
  region_id: [],
  set_city_id: false,
  city_id: [],
  set_outlet_id: false,
  outlet_id: [],
  set_year: true,
  year: currentDate.getFullYear().toString(),
  set_month: false,
  month: [],
}
