import {BarChart} from '@/_metronic/partials/content/chart/BarChart'
import {useEffect, useState} from 'react'
import {TopEmployees} from './components/TopEmployee'
import {TopTreatment} from './components/TopTreatment'
import {CategoryAnalysisPieChart} from './components/chart/CategoryAnalysisPieChart'
import {TreatmentAnalysisPieChart} from './components/chart/TreatmentAnalysisPieChart'
import {FilterReportTrendAnalysisModal} from './components/filter/FilterReportTrendAnalysisModal'
import {useFilterReportTrendAnalysis} from './core/FilterReportTrendAnalysisProvider'
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
} from './core/QueryResponseProvider'
import {CategoryTreatmentBarChart} from './components/chart/CategoryTreatmentBarChart'
import {forIn} from 'lodash'

const TrendAnalysisOverview = () => {
  const data = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const response = useQueryResponse()
  const [arrayOfSeriesSales, setArrayOfSeriesSales] = useState<number[]>([])
  const [arrayOfLabelsSales, setArrayOfLabelsSales] = useState<string[]>([])
  const [arrayOfSeriesTransaction, setArrayOfSeriesTransaction] = useState<number[]>([])
  const [arrayOfLabelsTransaction, setArrayOfLabelsTransaction] = useState<string[]>([])
  const [arrayOfSeriesTreatment, setArrayOfSeriesTreatment] = useState<number[]>([])
  const [arrayOfLabelsTreatment, setArrayOfLabelsTreatment] = useState<string[]>([])
  const [arrayOfSeriesscategory, setArrayOfSeriesCategory] = useState<number[]>([])
  const [arrayOfLabelscategory, setArrayOfLabelsCategory] = useState<string[]>([])
  const [arrayOfSeriesTrendTreatment, setArrayOfSeriesTrendTreatment] = useState<number[]>([])
  const [arrayOfLabelsTrendTreatment, setArrayOfLabelsTrendTreatment] = useState<string[]>([])
  const [heightTreatment, setHeightTreatment] = useState<number>(0)
  const {closeModal, showFilterReportTrendAnalysisModal} = useFilterReportTrendAnalysis()

  useEffect(() => {
    if (data) {
      setArrayOfSeriesSales([])
      setArrayOfLabelsSales([])
      setArrayOfSeriesTransaction([])
      setArrayOfLabelsTransaction([])
      setArrayOfSeriesTreatment([])
      setArrayOfLabelsTreatment([])
      setArrayOfSeriesCategory([])
      setArrayOfLabelsCategory([])
      setArrayOfSeriesTrendTreatment([])
      setArrayOfLabelsTrendTreatment([])
      if (data?.trend_transaction !== undefined) {
        // Sales
        const arrayOfSeriesSalesString = data?.trend_transaction?.map((obj) =>
          obj.total_sales !== undefined ? obj.total_sales : 0
        )
        setArrayOfSeriesSales(arrayOfSeriesSalesString)

        const arrayOfLabelsSalesString = data?.trend_transaction?.map((obj) =>
          obj.month !== undefined ? obj.month : ''
        )
        setArrayOfLabelsSales(arrayOfLabelsSalesString)

        // Transaction
        const arrayOfSeriesTransactionString = data?.trend_transaction?.map((obj) =>
          obj.total_transaction !== undefined ? obj.total_transaction : 0
        )
        setArrayOfSeriesTransaction(arrayOfSeriesTransactionString)

        const arrayOfLabelsTransactionString = data?.trend_transaction?.map((obj) =>
          obj.month !== undefined ? obj.month : ''
        )
        setArrayOfLabelsTransaction(arrayOfLabelsTransactionString)
      }

      // Treatment
      if (data && data?.trend_product) {
        const arrayOfSeriesTreatmentString = data?.trend_product?.map((obj) =>
          obj.total_qty !== undefined ? obj.total_qty : 0
        )
        setArrayOfSeriesTreatment(arrayOfSeriesTreatmentString)

        const arrayOfLabelsTreatmentString = data?.trend_product?.map((obj) =>
          obj.month !== undefined ? obj.month : ''
        )
        setArrayOfLabelsTreatment(arrayOfLabelsTreatmentString)
      }

      // Category
      if (data && data?.trend_category) {
        data?.trend_category.sort((a, b) =>
          (a.category_name ?? '').localeCompare(b.category_name ?? '')
        )
        const arrayOfSeriesCategoryString = data?.trend_category?.map((obj) =>
          obj.total_sales !== undefined ? obj.total_sales : 0
        )
        setArrayOfSeriesCategory(arrayOfSeriesCategoryString)

        const arrayOfLabelsCategoryString = data?.trend_category?.map(
          (obj) => obj.category_name ?? ''
        )

        setArrayOfLabelsCategory(arrayOfLabelsCategoryString)
      }

      // Trend Treatment
      if (data && data?.trend_treatment) {
        data?.trend_treatment.sort((a, b) =>
          (a.product_name ?? '').localeCompare(b.product_name ?? '')
        )
        const arrayOfSeriesTrendTreatmentString = data?.trend_treatment?.map((obj) =>
          obj.total_sales !== undefined ? obj.total_sales : 0
        )
        setArrayOfSeriesTrendTreatment(arrayOfSeriesTrendTreatmentString)

        const arrayOfLabelsTrendTreatmentString = data?.trend_treatment?.map(
          (obj) => obj.product_name ?? ''
        )

        const heights = data?.trend_treatment?.map((obj) => 54.54)

        const total = heights.reduce((prev, next) => prev + next)

        setHeightTreatment(total)

        setArrayOfLabelsTrendTreatment(arrayOfLabelsTrendTreatmentString)
      }
    }
  }, [data])

  return (
    <>
      <div className='mt-8 mb-7'>
        <div className='col-md-12 mb-12'>
          <BarChart
            className='shadow-wit'
            color='info'
            title='Revenue'
            subtitle=''
            height='400px'
            series={[
              {
                name: 'Transaction',
                data: arrayOfSeriesSales,
              },
            ]}
            categories={arrayOfLabelsSales}
            total={data && data.summary_trend?.sales ? data.summary_trend?.sales : 0}
            data={data?.trend_transaction?.map((x) => ({
              month: x.month,
              total_sales: x.total_sales,
            }))}
            headers={['Bulan', 'Total Revenue']}
            formatCurrency={['total_sales']}
            layout='horizontal'
            isLoading={isLoading || (response.response?.response as any) === 'no result data'}
          />
        </div>
        <div className='col-md-12 mb-12'>
          <BarChart
            className='shadow-wit'
            color='success'
            title='Transaction'
            subtitle=''
            height='400px'
            series={[
              {
                name: 'Transaction',
                data: arrayOfSeriesTransaction,
              },
            ]}
            categories={arrayOfLabelsTransaction}
            total={data && data.summary_trend?.transaction ? data.summary_trend?.transaction : 0}
            isCurrency={false}
            data={data?.trend_transaction?.map((x) => ({
              month: x.month,
              total_transaction: x.total_transaction,
            }))}
            headers={['Bulan', 'Total Transaction']}
            layout='horizontal'
            isLoading={isLoading || (response.response?.response as any) === 'no result data'}
          />
        </div>
        <div className='col-md-12 mb-12'>
          <BarChart
            className='shadow-wit'
            color='primary'
            title='Treatment'
            subtitle=''
            height='400px'
            series={[
              {
                name: 'Treatment',
                data: arrayOfSeriesTreatment,
              },
            ]}
            categories={arrayOfLabelsTreatment}
            total={data && data.summary_trend?.product ? data.summary_trend?.product : 0}
            isCurrency={false}
            data={data?.trend_product?.map((x) => ({
              month: x.month,
              total_qty: x.total_qty,
            }))}
            headers={['Bulan', 'Total Treatment']}
            layout='horizontal'
            isLoading={isLoading || (response.response?.response as any) === 'no result data'}
          />
        </div>
        <div className='row mb-12' style={{height: '559.64px'}}>
          <div className='col-md-6'>
            <TopTreatment className='shadow-wit-2 mb-10 h-100' data={data?.top_five_product} />
          </div>
          <div className='col-md-6'>
            <TopEmployees className='shadow-wit-2 h-100' data={data?.top_five_therapist} />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <CategoryTreatmentBarChart
              className='shadow-wit mb-10'
              color='info'
              title='Category'
              isCurrency={true}
              series={[
                {
                  name: 'Category',
                  data: arrayOfSeriesscategory,
                },
              ]}
              categories={arrayOfLabelscategory}
            />
          </div>
          <div className='col-md-12'>
            <CategoryTreatmentBarChart
              className='shadow-wit mb-10'
              color='info'
              title='Treatment'
              height={heightTreatment}
              isCurrency={true}
              series={[
                {
                  name: 'Treatment',
                  data: arrayOfSeriesTrendTreatment,
                },
              ]}
              categories={arrayOfLabelsTrendTreatment}
            />
          </div>
        </div>
        {/* <div className='row mt-15'>
          <div className='col-md-4'>
            <CategoryAnalysisPieChart className='shadow-wit' />
          </div>
          <div className='col-md-8'>
            <TopTreatment className='shadow-wit-2' data={data?.top_five_product} />
          </div>
        </div> */}
        {/* <div className='row mt-15'>
          <div className='col-md-4'>
            <TreatmentAnalysisPieChart className='shadow-wit' />
          </div>
          <div className='col-md-8'>
            <TopEmployees className='shadow-wit-2' data={data?.top_five_therapist} />
          </div>
        </div> */}
      </div>
      <FilterReportTrendAnalysisModal
        show={showFilterReportTrendAnalysisModal}
        closeModal={() => closeModal()}
      />
    </>
  )
}

export {TrendAnalysisOverview}
