import { BaseResponse } from '@/_metronic/helpers/crud-helper/base-list-response'
import moment from 'moment'

export interface FilterReportSalesBudget {
  set_sub_brand_id?: boolean
  sub_brand_id?: string[]
  set_brand_id?: boolean
  brand_id?: string[]
  set_area?: boolean
  area?: string[]
  set_region_id?: boolean
  region_id?: string[]
  set_city_id?: boolean
  city_id?: string[]
  set_outlet_id?: boolean
  outlet_id?: string[]
  set_month?: boolean
  month?: string[]
  set_year?: boolean
  year?: string | null
  set_date?: boolean
  start_date?: string | null
  end_date?: string | null
}

export interface ReportSalesBudget {
  sales_achievement?: SalesAchievement
  treatment_achievement?: TreatmentAchievement
  monthly_sales_vs_budget?: MonthlySalesVsBudget[]
  daily_sales_vs_budget?: DailySalesVsBudget[]
}

export interface MonthlySalesVsBudget {
  month?: number
  month_value?: string
  year?: number
  actual_sales?: number
  sales_target?: number
  actual_treatment?: number
  treatment_target?: number
}

export interface DailySalesVsBudget {
  day?: number;
  day_value?: string;
  month?: number;
  month_value?: string;
  actual_sales?: number;
  sales_target?: number;
  actual_treatment?: number;
  treatment_target?: number;
}

export interface SalesAchievement {
  actual_sales?: number
  sales_target?: number
  completion_rate?: CompletionRate
}

export interface CompletionRate {
  origin?: number | null
  formatted?: string
}

export interface TreatmentAchievement {
  actual_treatment?: number
  treatment_target?: number
  completion_rate?: CompletionRate
}

export type ReportSalesBudgetQueryResponse = BaseResponse<ReportSalesBudget>

const currentDate = new Date()
export const initialFilter: FilterReportSalesBudget = {
  set_sub_brand_id: false,
  sub_brand_id: [],
  set_brand_id: false,
  brand_id: [],
  set_area: false,
  area: [],
  set_region_id: false,
  region_id: [],
  set_city_id: false,
  city_id: [],
  set_outlet_id: false,
  outlet_id: [],
  set_year: true,
  year: currentDate.getFullYear().toString(),
  set_month: false,
  month: [],
  set_date: false,
  start_date: '',
  end_date: ''
}
export const initialFilterDaily: FilterReportSalesBudget = {
  set_sub_brand_id: false,
  sub_brand_id: [],
  set_brand_id: false,
  brand_id: [],
  set_area: false,
  area: [],
  set_region_id: false,
  region_id: [],
  set_city_id: false,
  city_id: [],
  set_outlet_id: false,
  outlet_id: [],
  set_year: false,
  year: currentDate.getFullYear().toString(),
  set_month: false,
  month: [],
  set_date: true,
  start_date: moment().startOf("month").format('yyyy-MM-DD'),
  end_date: moment().endOf("month").format('yyyy-MM-DD')
}
