import { ReportRushHourListToolbar } from './ReportRushHourListToolbar'

const ReportRushHourListHeader = () => {
  return (
    <div className='border-0'>
      <div className='card-toolbar'>
        <ReportRushHourListToolbar />
      </div>
    </div>
  )
}

export { ReportRushHourListHeader }

