/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { ID, KTIcon, QUERIES } from '../../../../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { deletePartnerOrganization } from '../../../core/_requests'
import { useListView } from '../../../core/ListViewProvider'
import { useQueryResponse } from '../../../core/QueryResponseProvider'
import { useSwal } from '@/app/core/SwalProvider'

type Props = {
  id: string
}

const PartnerActionsCell: FC<Props> = ({ id }) => {
  const {request} = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const {swal} = useSwal()

  const deleteItem = useMutation(() => deletePartnerOrganization(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.PARTNER_ORGANIZER_LIST}-${JSON.stringify(request)}`])
    },
  })

  return (
    <>
       <div className='d-flex justify-content-end flex-shrink-0'>
      <Link to={`/apps/partner-organization/partner/detail/${id}`} className='link-primary'>
        <a href='#' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-5'>
          <KTIcon iconName='eye' className='fs-3 text-info'/>
        </a>
      </Link>
      <Link to={`/apps/partner-organization/partner/edit/${id}`} className='link-primary'>
        <a href='#' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-5'>
          <KTIcon iconName='pencil' className='fs-3 text-primary' />
        </a>
      </Link>
      <button
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
        type='button'
        onClick={() => {
          swal
            ?.fire({
              html: 'Apakah anda yakin ingin menghapus data ini?',
              icon: 'warning',
              buttonsStyling: false,
              confirmButtonText: 'Ya, hapus!',
              cancelButtonText: 'Tidak, batal',
              showCancelButton: true,
              customClass: {
                confirmButton: 'btn fw-bold btn-danger',
                cancelButton: 'btn fw-bold btn-active-light-primary',
              },
            })
            .then(async (value) => {
              if (value.isConfirmed) {
                await deleteItem.mutateAsync()
                swal.fire({
                  title: 'Data berhasil dihapus',
                  icon: 'success',
                  buttonsStyling: false,
                  confirmButtonText: 'Ok, mengerti!',
                  customClass: {
                    confirmButton: 'btn fw-bold btn-primary',
                  },
                })
              }
            })
        }}
      >
        <KTIcon iconName='trash' className='fs-3 text-danger' />
      </button>
    </div>
    </>
  )
}

export { PartnerActionsCell }
