import {AuthModel, GenerateTokenRequest} from './_models'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import DeviceDetector from 'device-detector-js'
import {RESP} from '@/_metronic/helpers/network/network-consts'
import {generateToken} from './_requests'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const TOKEN = 'kt-auth-token'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

const getToken = (): string | null => {
  if (!localStorage) {
    return null
  }

  return localStorage.getItem(TOKEN)
}

const setToken = (token: string) => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.setItem(TOKEN, token)
  } catch (error) {
    console.error('AUTH TOKEN SAVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  // axios.interceptors.request.use(
  //   (config: {headers: {Authorization: string}}) => {
  //     const auth = getAuth()
  //     if (auth && auth.api_token) {
  //       config.headers.Authorization = `Bearer ${auth.api_token}`
  //     }

  //     return config
  //   },
  //   (err: any) => Promise.reject(err)
  // )
}

const fetchToken = async () => {
  try {
    const fpPromise = await FingerprintJS.load()
    const visitorId = (await fpPromise.get()).visitorId
    const deviceDetector = new DeviceDetector()
    const device = deviceDetector.parse(navigator.userAgent)

    const body: GenerateTokenRequest = {
      app_name: process.env.REACT_APP_SERVICE_NAME,
      app_key: process.env.REACT_APP_SECRET_KEY,
      device_id: `${device.client?.name}-${visitorId}`,
      device_type: device.client?.name,
      ip_address: '0.0.0.0',
    }

    const res = await generateToken(body)
    if (res?.data?.response?.code === RESP.SUCCESS) {
      const accessToken = res?.data?.response?.data?.token || ''
      setToken(accessToken)
      return accessToken
      // authHelper.setToken(res?.data?.response?.data?.token || '')
      // setToken(res?.data?.response?.data?.token || '')
    }

    return null
  } catch (error) {
    console.error(error)
    return null
  }
}

const removeToken = async () => {
  if (!localStorage) {
    return
  }

  localStorage.removeItem(TOKEN)
}

export {
  getAuth,
  setAuth,
  removeAuth,
  getToken,
  setToken,
  fetchToken,
  removeToken,
  AUTH_LOCAL_STORAGE_KEY,
  TOKEN,
}
