import { WithChildren } from '@/_metronic/helpers'
import { createContext, FC, useContext, useState } from 'react'
import { FilterReportSalesBudget, initialFilter } from './_models'

type ProductQueryRequestMonthlyContextProps = {
  state: FilterReportSalesBudget
  updateState: (updates: Partial<FilterReportSalesBudget>) => void
}

const initialQueryRequestMonthly = {
  state: initialFilter,
  updateState: () => {},
}

const QueryRequestMonthlyContext = createContext<ProductQueryRequestMonthlyContextProps>(initialQueryRequestMonthly)

const QueryRequestMonthlyProvider: FC<WithChildren> = ({children}) => {
  const [state, setState] = useState<FilterReportSalesBudget>(initialQueryRequestMonthly.state)

  const updateState = (updates: Partial<FilterReportSalesBudget>) => {
    const updatedState = {...state, ...updates} as FilterReportSalesBudget
    setState(updatedState)
  }

  return (
    <QueryRequestMonthlyContext.Provider value={{state, updateState}}>
      {children}
    </QueryRequestMonthlyContext.Provider>
  )
}

const useQueryRequestMonthly = () => useContext(QueryRequestMonthlyContext)
export { QueryRequestMonthlyProvider, useQueryRequestMonthly }

