import { BaseResponse } from '@/_metronic/helpers/crud-helper/base-list-response'
import { BaseRequest } from '@/_metronic/helpers/crud-helper/base-request'

export type FilterJob = {
  set_job_title?: boolean;
  job_title?: string;
  set_department_id?: boolean;
  department_id?: string;
  set_job_level_id?: boolean;
  job_level_id?: string;
  set_status?: boolean;
  status?: string;
}

export const initialFilterJob: BaseRequest<FilterJob> = {
  filter: {
    set_job_title: false,
    job_title: "",
    set_department_id: false,
    department_id: "",
    set_job_level_id: false,
    job_level_id: "",
    set_status: false,
    status: ""
  },
  limit: 50,
  page: 1,
  order: 'created_at',
  sort: 'ASC',
}

export type Job = {
  guid?: string;
  level?: Level;
  status?: string;
  job_title?: string;
  department?: Department;
}

export interface Department {
  guid?: string;
  department_name?: string;
}

export interface Level {
  guid?: string;
  level_name?: null;
}

export type JobResponse = BaseResponse<Array<Job>>