import { BaseRequest } from '@/_metronic/helpers/crud-helper/base-request'
import { BaseResponse } from '@/_metronic/helpers/crud-helper/base-list-response';

export interface PartnerOrganization {
  guid?: string;
  name?: string;
  email?: string;
  address?: string;
  phone_number?: string;
  brand_cluster?: string;
  organization_name?: string;
  pic_partner_organization?: PicPartnerOrganization;
}

export interface PicPartnerOrganization {
  guid?: string;
  surname?: string;
  full_name?: string;
  job_title?: string;
  partner_organization_guid?: string;
}

export interface FilterPartnerOrganization {
  set_organization_name?: boolean;
    organization_name?:     string;
    set_name?:              boolean;
    name?:                  string;
    set_phone_number?:      boolean;
    phone_number?:          string;
    set_email?:             boolean;
    email?:                 string;
    set_brand_cluster?:     boolean;
    brand_cluster?:         string;
}

export type PartnerOrganizationQueryResponse = BaseResponse<Array<PartnerOrganization>>

export const initialFilter: BaseRequest<FilterPartnerOrganization> = {
  filter: {
    set_organization_name: false,
    organization_name: "",
    set_name: false,
    name: "",
    set_phone_number: false,
    phone_number: "",
    set_email: false,
    email: "",
    set_brand_cluster: false,
    brand_cluster: ""
  },
  limit: 10,
  page: 1,
  order: 'created_at',
  sort: 'DESC',
}
