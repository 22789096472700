/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {FormattedNumber, IntlProvider} from 'react-intl'
import {useOutletQueryResponseData} from '../core/QueryResponseOutletProvider'

type Props = {
  className: string
}

const TopOutlet: React.FC<Props> = ({className}) => {
  const data = useOutletQueryResponseData()
  const firstFiveData = data && data.slice(0, 20)

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Top 20 Outlet</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        ></div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body shadow-wit-2 py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-30px'>No</th>
                <th className='min-w-150px'>Outlet</th>
                <th className='min-w-100px'>Total Transaction</th>
                <th className='min-w-100px'>Total Transaction Value</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody className='text-dark mb-1 fs-6'>
              {firstFiveData &&
                firstFiveData.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <a href='#' className='text-dark fw-semibold text-hover-primary d-block fs-6'>
                        {index + 1}
                      </a>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <span className='text-dark fw-bold fs-6'>{item.outlet_name}</span>
                        </div>
                      </div>
                    </td>
                    <td className='text-end'>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2'>
                          <span className='text-dark me-2 fs-7 fw-semibold'>
                            {item.total_transaction || 0}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className='text-end'>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2'>
                          <span className='text-dark me-2 fs-7 fw-semibold'>
                            Rp.{' '}
                            <IntlProvider locale='id'>
                              <FormattedNumber value={item.total_transaction_value || 0} />
                            </IntlProvider>{' '}
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {TopOutlet}
