import { FC } from 'react'
import { Modal } from 'react-bootstrap'
import PaidForm from './ChangePasswordForm'

type Props = {
  id: string
  show: boolean
  closeModal: () => void
}

const ChangePasswordModal: FC<Props> = ({ id, show, closeModal }) => {

  return (
    <Modal show={show} onHide={closeModal}>
      <div className='modal-content'>
        <div className='modal-body'>
          <PaidForm id={id} closeModal={closeModal} />
        </div>
      </div>
    </Modal>
  )
}

export { ChangePasswordModal }
