import { FC, createContext, useContext, useState } from 'react'
import { WithChildren } from '../../../../_metronic/helpers'
import { FilterReportRushHour, initialFilter } from './_models'

type ProductQueryRequestContextProps = {
  state: FilterReportRushHour
  updateState: (updates: Partial<FilterReportRushHour>) => void
}

const initialQueryRequest = {
  state: initialFilter,
  updateState: () => {},
}

const QueryRequestContext = createContext<ProductQueryRequestContextProps>(initialQueryRequest)

const QueryRequestProvider: FC<WithChildren> = ({children}) => {
  const [state, setState] = useState<FilterReportRushHour>(initialQueryRequest.state)

  const updateState = (updates: Partial<FilterReportRushHour>) => {
    const updatedState = {...state, ...updates} as FilterReportRushHour
    setState(updatedState)
  }

  return (
    <QueryRequestContext.Provider value={{state, updateState}}>
      {children}
    </QueryRequestContext.Provider>
  )
}

const useQueryRequest = () => useContext(QueryRequestContext)
export { QueryRequestProvider, useQueryRequest }

