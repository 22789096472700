import { WithChildren } from '@/_metronic/helpers'
import { createContext, FC, useContext, useState } from 'react'

type FilterReportSalesBudgetContextProps = {
  showFilterReportSalesBudgetMonthlyModal: boolean
  showFilterReportSalesBudgetDailyModal: boolean
  isMonthly: boolean
  filterReportSalesBudgetFormMonthlyValue: any
  filterReportSalesBudgetFormDailyValue: any
  updateFilterReportSalesBudgetFormMonthlyValue: (formValue: any) => void
  updateFilterReportSalesBudgetFormDailyValue: (formValue: any) => void
  handleShowMonthlyModal: () => void
  handleShowDailyModal: () => void
  closeModalMonthly: () => void
  closeModalDaily: () => void
  setMonthly: (monthly: boolean) => void
}

const filterReportSalesBudgetContextInitial: FilterReportSalesBudgetContextProps = {
  showFilterReportSalesBudgetMonthlyModal: false,
  showFilterReportSalesBudgetDailyModal: false,
  isMonthly: false,
  filterReportSalesBudgetFormMonthlyValue: null,
  filterReportSalesBudgetFormDailyValue: null,
  updateFilterReportSalesBudgetFormMonthlyValue: () => {},
  updateFilterReportSalesBudgetFormDailyValue: () => {},
  handleShowMonthlyModal: () => {},
  handleShowDailyModal: () => {},
  closeModalMonthly: () => {},
  closeModalDaily: () => {},
  setMonthly: () => {},
}

const FilterReportSalesBudgetContext = createContext<FilterReportSalesBudgetContextProps>(
  filterReportSalesBudgetContextInitial
)

const FilterReportSalesBudgetProvider: FC<WithChildren> = ({children}) => {
  const [showFilterReportSalesBudgetMonthlyModal, setShowFilterReportSalesBudgetMonthlyModal] =
    useState<boolean>(false)
  const [showFilterReportSalesBudgetDailyModal, setShowFilterReportSalesBudgetDailyModal] =
    useState<boolean>(false)
  const [isMonthly, setMonthly] =
    useState<boolean>(false)
  const [filterReportSalesBudgetFormMonthlyValue, setFilterReportSalesBudgetFormMonthlyValue] =
    useState<any>(null)
  const [filterReportSalesBudgetFormDailyValue, setFilterReportSalesBudgetFormDailyValue] =
    useState<any>(null)

  const updateFilterReportSalesBudgetFormMonthlyValue = (newValue) => {
    setFilterReportSalesBudgetFormMonthlyValue(newValue)
  }

  const updateFilterReportSalesBudgetFormDailyValue = (newValue) => {
    setFilterReportSalesBudgetFormDailyValue(newValue)
  }

  const handleShowMonthlyModal = () => {
    setShowFilterReportSalesBudgetMonthlyModal(true)
  }

  const handleShowDailyModal = () => {
    setShowFilterReportSalesBudgetDailyModal(true)
  }

  const closeModalMonthly = () => {
    setShowFilterReportSalesBudgetMonthlyModal(false)
  }

  const closeModalDaily = () => {
    setShowFilterReportSalesBudgetDailyModal(false)
  }

  return (
    <FilterReportSalesBudgetContext.Provider
      value={{
        showFilterReportSalesBudgetMonthlyModal,
        showFilterReportSalesBudgetDailyModal,
        isMonthly,
        filterReportSalesBudgetFormMonthlyValue,
        filterReportSalesBudgetFormDailyValue,
        updateFilterReportSalesBudgetFormMonthlyValue,
        updateFilterReportSalesBudgetFormDailyValue,
        handleShowMonthlyModal,
        handleShowDailyModal,
        closeModalMonthly,
        closeModalDaily,
        setMonthly,
      }}
    >
      {children}
    </FilterReportSalesBudgetContext.Provider>
  )
}

const useFilterReportSalesBudget = () => useContext(FilterReportSalesBudgetContext)

export { FilterReportSalesBudgetProvider, useFilterReportSalesBudget }

