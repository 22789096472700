import { toAbsoluteUrl } from '@/_metronic/helpers'
import { ChangeEventHandler, FC, FocusEventHandler, HTMLInputTypeAttribute } from 'react'
import SVG from 'react-inlinesvg'

type Props = {
  label?: string | undefined
  value?: string | number | readonly string[] | undefined
  onChange?: ChangeEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  placeholder?: string | undefined
  name?: string | undefined
  id?: string | undefined
  type?: HTMLInputTypeAttribute | undefined
  isValid?: boolean
  errorMessage?: string
  readOnly?: boolean
  disabled?: boolean
  required?: boolean
  icon?: string
}

const InputIconGroup: FC<Props> = ({
  label,
  value,
  id,
  onChange,
  placeholder,
  name,
  type,
  onBlur,
  isValid,
  errorMessage,
  readOnly,
  disabled = false,
  required = true,
  icon,
}) => {
  return (
    <>
      {label && <label className={`${required ? 'required' : ''} form-label`}>{label}</label>}
      <div className='col-md-12 position-relative'>
        <div className='position-absolute translate-middle-y top-50 start-0 me-3 ms-3'>
          {icon && <SVG src={toAbsoluteUrl(icon)} />}
        </div>
        <input
          type={type}
          id={id}
          name={name}
          className='form-control ps-12'
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          readOnly={readOnly}
          disabled={disabled}
        />
      </div>
      {!isValid && errorMessage && <span className='text-danger mt-2'>{errorMessage}</span>}
    </>
  )
}

export { InputIconGroup }

