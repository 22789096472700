import { FC } from 'react'
import { Modal } from 'react-bootstrap'
import { FilterReportRevenueSharingForm } from './FilterReportRevenueSharingForm'
import { FilterReportRevenueSharingModalHeader } from './FilterReportRevenueSharingModalHeader'

type Props = {
  show: boolean
  closeModal: () => void
}

const FilterReportRevenueSharingModal: FC<Props> = ({show, closeModal}) => {
  return (
    <>
      <Modal className='modal fade' dialogClassName='modal-xl' show={show} onHide={closeModal}>
        <div className='modal-content'>
          <FilterReportRevenueSharingModalHeader closeModal={closeModal} />
          <div className='modal-body scroll-y'>
            <FilterReportRevenueSharingForm closeModal={closeModal} />
          </div>
        </div>
      </Modal>
    </>
  )
}

export { FilterReportRevenueSharingModal }

