/* eslint-disable react/jsx-no-target-blank */
import {useAuth} from '@/app/modules/auth'
import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItemWrapper} from './AsideMenuItemWrapper'
import {useMemo} from 'react'
import {MenuAccess} from '@/app/modules/user-management/user-management-edit/core/_model'
import {useAsideMenu} from '../../core/AsideMenuProvider'

const findMenu = (access: MenuAccess, search: string): any => {
  if (access?.sub_menu && access?.sub_menu?.length) {
    const subMenu: any = findMenu(access, search)
    if (subMenu?.length) {
      return {
        ...access,
        sub_menu: subMenu,
      }
    }

    return null
  }

  if (access?.text?.toLowerCase().includes(search?.toLowerCase())) {
    return access
  }
}

export function AsideMenuMain() {
  const intl = useIntl()
  const {menuAccess: menus} = useAuth()
  const {search} = useAsideMenu()

  const menuAccess = useMemo<Array<MenuAccess>>(() => {
    if (search?.length) {
      const findMenu = (data, value) => {
        return data.reduce((r, e) => {
          const object = {...e}
          const result = findMenu(e.sub_menu || [], value)
          if (result.length) object.sub_menu = result
          if (e.text?.toLowerCase().includes(value?.toLowerCase()) || result.length) r.push(object)
          return r
        }, [])
      }

      return findMenu(menus, search)
    }

    return menus
  }, [menus, search])

  return (
    <>
      {menuAccess?.length &&
        menuAccess.map((menu) => (
          <AsideMenuItemWrapper key={`menuaccess-${menu.id}`} menu={menu} />
        ))}
    </>
  )

  // return (
  //   <>
  //     <AsideMenuItem
  //       to='/dashboard'
  //       icon='element-11'
  //       title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
  //     />

  //     <div className='menu-item'>
  //       <div className='menu-content pt-8 pb-2'>
  //         <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Report</span>
  //       </div>
  //     </div>
  //     <AsideMenuItemWithSub to='/report/sales' icon='chart-simple-3' title='Sales'>
  //       <AsideMenuItem to='/report/sales/revenue-sharing' icon='dollar' title='Revenue Sharing' />
  //       <AsideMenuItem to='/report/sales/sales-forecast' icon='dollar' title='Sales vs Budget' />
  //       <AsideMenuItem to='/report/sales/rush-hour' icon='dollar' title='Rush Hour' />
  //       <AsideMenuItem to='/report/sales/trend-analysis' icon='dollar' title='Trend Analysis' />
  //       <AsideMenuItem
  //         to='/report/sales/sales-by-product/list'
  //         icon='dollar'
  //         title='Sales By Product'
  //       />
  //       <AsideMenuItem
  //         to='/report/sales/sales-payment-channel/list'
  //         icon='dollar'
  //         title='Sales by Payment Channel'
  //       />
  //     </AsideMenuItemWithSub>
  //     <AsideMenuItemWithSub to='/report/rating-by-order/list' icon='star' title='Rating'>
  //       <AsideMenuItem to='/report/rating-by-order/list' icon='star' title='Rating by Order' />
  //       <AsideMenuItem
  //         to='/report/rating-by-therapist/list'
  //         icon='star'
  //         title='Rating by Therapist'
  //       />
  //     </AsideMenuItemWithSub>
  //     <AsideMenuItemWithSub to='/report/sales' icon='chart-simple-3' title='KPI'>
  //       <AsideMenuItem to='/apps/KPI/Home' title='KPI' icon='chart-line' />
  //       <AsideMenuItem to='/apps/KPI/therapist' title='KPI Therapist' icon='chart-line-up' />
  //       <AsideMenuItem to='/apps/KPI/manager' title='KPI Manager' icon='chart-line-down' />
  //     </AsideMenuItemWithSub>

  //     <div className='menu-item'>
  //       <div className='menu-content pt-8 pb-2'>
  //         <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Human Resources</span>
  //       </div>
  //       <AsideMenuItem to='/human-resources/employee/list' icon='profile-user' title='Employee' />
  //       <AsideMenuItem
  //         to='/human-resources/contract-employee/list'
  //         icon='book-open'
  //         title='Contract Employee'
  //       />
  //       <AsideMenuItem
  //         to='/human-resources/attendance/list'
  //         icon='delivery-door'
  //         title='Attendance'
  //       />
  //       <AsideMenuItem
  //         to='/user-management/assistance-therapist/list'
  //         icon='profile-user'
  //         title='Perbantuan Therapist'
  //       />
  //       <AsideMenuItem
  //         to='/user-management/mapping-outlet-pic/list'
  //         icon='setting'
  //         title='Mapping Outlet PIC'
  //       />
  //     </div>

  //     <div className='menu-item'>
  //       <div className='menu-content pt-8 pb-2'>
  //         <span className='menu-section text-muted text-uppercase fs-8 ls-1'>User Management</span>
  //       </div>
  //     </div>
  //     <AsideMenuItem
  //       to='/user-management/user-management/list'
  //       icon='setting'
  //       title='User management'
  //     />
  //     <AsideMenuItem
  //       to='/apps/user-management/approval-configuration/list'
  //       icon='setting'
  //       title='Approval Configuration'
  //     />

  //     <div className='menu-item'>
  //       <div className='menu-content pt-8 pb-2'>
  //         <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
  //           Outlet Management
  //         </span>
  //       </div>
  //     </div>
  //     <AsideMenuItemWithSub to='/master-data/outlet/list' icon='package' title='Outlet'>
  //       <AsideMenuItem to='/master-data/outlet/list' icon='shop' title='Outlet' />
  //       <AsideMenuItem
  //         to='/apps/partner-organization/partner/list'
  //         icon='delivery-door'
  //         title='Hotel'
  //       />
  //       <AsideMenuItem
  //         to='/master-data/outlet/partnership-contract/list'
  //         icon='book-open'
  //         title='Hotel Patnership Contract'
  //       />
  //     </AsideMenuItemWithSub>
  //     <AsideMenuItem to='/sales-marketing/cms/list' icon='book' title='CMS' />

  //     <div className='menu-item'>
  //       <div className='menu-content pt-8 pb-2'>
  //         <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Product</span>
  //       </div>
  //     </div>
  //     <AsideMenuItemWithSub to='/master-data/product/list' icon='package' title='Product'>
  //       <AsideMenuItem to='/master-data/product/list' icon='tag' title='Product' />
  //       <AsideMenuItem to='/master-data/product/category/list' icon='category' title='Category' />
  //       <AsideMenuItem
  //         to='/master-data/product/collection/list'
  //         icon='category'
  //         title='Collection'
  //       />
  //       <AsideMenuItem to='/master-data/product/product-price' icon='category' title='Price' />
  //     </AsideMenuItemWithSub>
  //     <AsideMenuItemWithSub
  //       to='/master-data/product/product-stock/list'
  //       icon='package'
  //       title='Stock Product'
  //     >
  //       <AsideMenuItem to='/master-data/product/product-stock/list' icon='category' title='Stock' />
  //       <AsideMenuItem
  //         to='/master-data/product/product-stock/stock-opname'
  //         icon='category'
  //         title='Stock Opname'
  //       />
  //     </AsideMenuItemWithSub>

  //     <div className='menu-item'>
  //       <div className='menu-content pt-8 pb-2'>
  //         <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Raw Material</span>
  //       </div>
  //     </div>
  //     <AsideMenuItemWithSub to='/master-data/raw-material/list' icon='package' title='Raw Material'>
  //       <AsideMenuItem to='/master-data/raw-material/list' icon='category' title='Raw Material' />
  //       <AsideMenuItem
  //         to='/master-data/raw-material/category/list'
  //         icon='category'
  //         title='Category'
  //       />
  //       <AsideMenuItem
  //         to='/master-data/raw-material/raw-material-price'
  //         icon='category'
  //         title='Price'
  //       />
  //     </AsideMenuItemWithSub>
  //     <AsideMenuItemWithSub
  //       to='/raw-material/stock-raw-material/list'
  //       icon='package'
  //       title='Stock Raw Material'
  //     >
  //       <AsideMenuItem
  //         to='/raw-material/stock-raw-material/stock/list'
  //         icon='category'
  //         title='Stock'
  //       />
  //       <AsideMenuItem
  //         to='/raw-material/stock-raw-material/stock-opname'
  //         icon='category'
  //         title='Stock Opname'
  //       />
  //       <AsideMenuItem
  //         to='/raw-material/stock-raw-material/approval/list'
  //         icon='category'
  //         title='Stock Opname Approval'
  //       />
  //     </AsideMenuItemWithSub>
  //     <AsideMenuItemWithSub
  //       to='/raw-material/purchase-order/list'
  //       icon='package'
  //       title='Purchase Order'
  //     >
  //       <AsideMenuItem
  //         to='/raw-material/purchase-order/supplier/list'
  //         icon='category'
  //         title='Supplier'
  //       />
  //       <AsideMenuItem
  //         to='/raw-material/purchase-order/list'
  //         icon='category'
  //         title='Purchase Order'
  //       />
  //       <AsideMenuItem
  //         to='/raw-material/purchase-order/approval/list'
  //         icon='category'
  //         title='Approval Purchase Order'
  //       />
  //       <AsideMenuItem
  //         to='/raw-material/purchase-order/receive/list'
  //         icon='category'
  //         title='Receive Purchase Order'
  //       />
  //     </AsideMenuItemWithSub>
  //     <AsideMenuItemWithSub to='/raw-material/transfer/list' icon='package' title='Transfer'>
  //       <AsideMenuItem to='/raw-material/transfer/request/list' icon='category' title='Request' />
  //       <AsideMenuItem to='/raw-material/transfer/list' icon='category' title='Transfer' />
  //       <AsideMenuItem to='/raw-material/transfer/receive/list' icon='category' title='Receive' />
  //     </AsideMenuItemWithSub>

  //     <div className='menu-item'>
  //       <div className='menu-content pt-8 pb-2'>
  //         <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
  //           Sales & Marketing
  //         </span>
  //       </div>
  //     </div>
  //     <AsideMenuItem to='/apps/store/pos' icon='finance-calculator' title='Point of Sale' />
  //     <AsideMenuItem to='/apps/transaction-history' icon='note-2' title='Transaction History' />
  //     <AsideMenuItem to='/apps/report-transaction' icon='book' title='Transaction Report' />
  //     <AsideMenuItem to='/apps/order/book-order' icon='tablet-book' title='Book Order' />
  //     <AsideMenuItem to='/apps/order/list' icon='notepad' title='Order List' />
  //     <AsideMenuItem to='/sales-marketing/customer/list' icon='people' title='Customer' />
  //     <AsideMenuItem
  //       to='/sales-marketing/forecast-target-sales/list'
  //       icon='people'
  //       title='Budget Configuration'
  //     />
  //     <div className='menu-item'>
  //       <div className='menu-content pt-8 pb-2'>
  //         <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
  //           Finance & Accounting
  //         </span>
  //       </div>
  //     </div>
  //     <AsideMenuItem
  //       to='/finance-of-accounting/chart-of-account/list'
  //       icon='book'
  //       title='Chart of Account'
  //     />
  //     <AsideMenuItem to='/finance-of-accounting/journal-entry' icon='book' title='Journal Entry' />
  //     <AsideMenuItem
  //       to='/finance-of-accounting/journal-history/list'
  //       icon='chart-simple-3'
  //       title='Journal History'
  //     />
  //     <AsideMenuItem
  //       to='/finance-of-accounting/general-ledger/list'
  //       icon='chart-simple-3'
  //       title='General Ledger'
  //     />
  //     <AsideMenuItem
  //       to='/finance-of-accounting/profit-loss/list'
  //       icon='chart-simple-3'
  //       title='Profit and Loss'
  //     />
  //     <AsideMenuItem
  //       to='/finance-of-accounting/balance-sheet/list'
  //       icon='chart-simple-3'
  //       title='Balance Sheet'
  //     />
  //     <AsideMenuItemWithSub to='/invoice' icon='dollar' title='Invoice'>
  //       <AsideMenuItem
  //         to='/invoice/purchase-order-invoice/list'
  //         icon='dollar'
  //         title='Purchase Order Invoice'
  //       />
  //       <AsideMenuItem to='/invoice/sales-invoice/list' icon='dollar' title='Sales Invoice' />
  //     </AsideMenuItemWithSub>
  //   </>
  // )
}
