import {axios} from '@/_metronic/helpers/network/Axios'
import {FilteReportBillingClient, ReportBillingClientData, ReportBillingClientResponse} from './_models'

const REPORT_URL = '/report-services'
const GET_REPORT_BILLING_CLIENT = '/billing/report'
const GET_REPORT_BILLING_CLIENT_DETAIL = '/billing'
const GET_STATUS_BILLING = '/billing/status'

const getReportByOutlets = (body: FilteReportBillingClient): Promise<ReportBillingClientResponse> => {
  return axios.post(`${REPORT_URL}${GET_REPORT_BILLING_CLIENT}`, body).then((d) => d.data)
}

const getReportBillingDetail = (guid: string): Promise<ReportBillingClientData> => {
  return axios.get(`${REPORT_URL}/${GET_REPORT_BILLING_CLIENT_DETAIL}/${guid}`).then((d) => d.data.response.data)
}

const getStatusBilling = (): Promise<ReportBillingClientData> => {
  return axios.get(`${REPORT_URL}/${GET_STATUS_BILLING}`).then((d) => d.data.response.data)
}


export {getReportByOutlets, getReportBillingDetail, getStatusBilling}
