import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {useQueryRequest} from '../../../core/QueryRequestProvider'
import {initialFilter} from '../../../core/_models'
import {KTIcon} from '@/_metronic/helpers'
import {DropdownSelect} from '@/_metronic/partials/content/dropdown-select/DropdownSelect'
import PropagateWrapper from '@/_metronic/partials/content/propagate/PropagateWrapper'
import { exportPartnerOrganization } from '../../../core/_requests'
import moment from 'moment'
import saveAs from 'file-saver'

const optionsBrandCluster = [
  {value: '1', label: '1'},
  {value: '2', label: '2'},
  {value: '3', label: '3'},
  {value: '4', label: '4'},
  {value: '5', label: '5'},
]

const PartnersListFilter = () => {
  const {updateState} = useQueryRequest()
  const [filterValue, setFilterValue] = useState(initialFilter.filter)

  useEffect(() => {
    MenuComponent.reinitialization()
    updateState.toString()
  }, [])

  const resetData = () => {
    setFilterValue(initialFilter.filter)
    updateState({filter: initialFilter.filter, page: 1})
  }

  const filterData = () => {
    updateState({
      filter: filterValue,
      page: 1,
      ...updateState,
    })
  }

  const handleExport = async () => {
    try {
      const response: any = await exportPartnerOrganization({
        ...filterValue,
      })

      const blob = new Blob([response.data], {type: response.headers['content-type']})
      saveAs(
        blob,
        `HOTEL - ${moment().valueOf()}.xlsx`
      )
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
     <div className='col-auto'>
          <button type='button' className='btn btn-secondary me-3 mt-1 fs-6' onClick={handleExport}>
            {/* <KTIcon iconName='filter' className='fs-2' /> */}
            Export
          </button>
        </div>
      {/* begin::Filter Button */}
      <button
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTIcon iconName='filter' className='fs-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div
        className='menu menu-sub menu-sub-dropdown w-300px w-md-325px scroll h-500px'
        data-kt-menu='true'
      >
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Advanced Filter</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          <PropagateWrapper>
            {/* begin::Input group */}
            <div className='mb-10'>
              <label className='form-label fs-6 fw-bold'>Name:</label>
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid'
                placeholder='Name'
                value={filterValue?.name}
                onChange={(e: any) =>
                  setFilterValue({
                    ...filterValue,
                    name: e.target.value,
                    set_name: e.target.value !== null,
                  })
                }
              />
            </div>
            {/* end::Input group */}
            {/* begin::Input group */}
            <div className='mb-10'>
              <label className='form-label fs-6 fw-bold'>Organization:</label>
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid'
                placeholder='Organization'
                value={filterValue?.organization_name}
                onChange={(e: any) =>
                  setFilterValue({
                    ...filterValue,
                    organization_name: e.target.value,
                    set_organization_name: e.target.value !== null,
                  })
                }
              />
            </div>
            {/* end::Input group */}
            {/* begin::Input group */}
            {/* <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Person In Charge:</label>
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid'
              placeholder='Person In Charge'
              value={filterValue?.fullname}
              onChange={(e: any) => setFilterValue({ ...filterValue, fullname: e.target.value, set_fullname: e.target.value !== null })}
            />
          </div> */}
            {/* end::Input group */}
            {/* begin::Input group */}
            <div className='mb-10'>
              <label className='form-label fs-6 fw-bold'>Brand Cluster:</label>
              <DropdownSelect
                options={optionsBrandCluster}
                placeholder='Brand Cluster'
                value={
                  filterValue?.brand_cluster
                    ? {label: filterValue.brand_cluster, value: filterValue.brand_cluster}
                    : null
                }
                onChange={(e: any) =>
                  setFilterValue({...filterValue, brand_cluster: e.label, set_brand_cluster: true})
                }
              />
            </div>
          </PropagateWrapper>
          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              // disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              // disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export {PartnersListFilter}
