import {FC} from 'react'
import {useQueryResponsePagination} from '../../../core/QueryResponseProvider'

type Props = {
  index: number
}

const IndexCell: FC<Props> = ({index}) => {
  const page = useQueryResponsePagination().page

  return (
    <>
      <span className='label'>{(page > 1 ? page * 10 - 10 : 0) + index}</span>
    </>
  )
}

export {IndexCell}
