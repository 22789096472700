import { WithChildren } from '@/_metronic/helpers'
import { createContext, FC, useContext, useState } from 'react'

type FilterReportTrendAnalysisContextProps = {
  showFilterReportTrendAnalysisModal: boolean
  filterReportTrendAnalysisFormValue: any
  updateFilterReportTrendAnalysisFormValue: (formValue: any) => void
  handleShowModal: () => void
  closeModal: () => void
}

const filterReportTrendAnalysisContextInitial: FilterReportTrendAnalysisContextProps = {
  showFilterReportTrendAnalysisModal: false,
  filterReportTrendAnalysisFormValue: null,
  updateFilterReportTrendAnalysisFormValue: () => {},
  handleShowModal: () => {},
  closeModal: () => {},
}

const FilterReportTrendAnalysisContext = createContext<FilterReportTrendAnalysisContextProps>(
  filterReportTrendAnalysisContextInitial
)

const FilterReportTrendAnalysisProvider: FC<WithChildren> = ({children}) => {
  const [showFilterReportTrendAnalysisModal, setShowFilterReportTrendAnalysisModal] =
    useState<boolean>(false)
  const [filterReportTrendAnalysisFormValue, setFilterReportTrendAnalysisFormValue] =
    useState<any>(null)

  const updateFilterReportTrendAnalysisFormValue = (newValue) => {
    setFilterReportTrendAnalysisFormValue(newValue)
  }

  const handleShowModal = () => {
    setShowFilterReportTrendAnalysisModal(true)
  }

  const closeModal = () => {
    setShowFilterReportTrendAnalysisModal(false)
  }

  return (
    <FilterReportTrendAnalysisContext.Provider
      value={{
        showFilterReportTrendAnalysisModal,
        filterReportTrendAnalysisFormValue,
        updateFilterReportTrendAnalysisFormValue,
        handleShowModal,
        closeModal,
      }}
    >
      {children}
    </FilterReportTrendAnalysisContext.Provider>
  )
}

const useFilterReportTrendAnalysis = () => useContext(FilterReportTrendAnalysisContext)

export { FilterReportTrendAnalysisProvider, useFilterReportTrendAnalysis }

