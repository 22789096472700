import {BaseRequest} from '@/_metronic/helpers/crud-helper/base-request'
import {
  DropdownSelectAsyncPaginate,
  OptionType,
} from '@/_metronic/partials/content/dropdown-select/DropdownSelectAsyncPaginate'
import {FilterMasterData} from '@/app/core/master-data/_models'
import {getMasterDataDropdown} from '@/app/core/master-data/_requests'
import {useAuth} from '@/app/modules/auth'
import {FC, useEffect, useState} from 'react'
import {MultiValue} from 'react-select'

type Props = {
  currentValue: OptionType | MultiValue<OptionType> | null
  onValueChange: any
}

const FilterDropdownBrand: FC<Props> = ({currentValue, onValueChange}) => {
  const [_, onChange] = useState<OptionType | MultiValue<OptionType> | null>(currentValue || null)
  const {currentUser} = useAuth()

  const loadOptionsBrands = async (search: string, page: number) => {
    try {
      const body = {
        filter: {
          set_parent_id: false,
          parent_id: '',
          set_category: true,
          category: 'Brand',
          set_value: search.length > 0 ? true : false,
          value: search,
        },
        page: page,
        limit: 10,
        sort: 'ASC',
        order: 'name',
      } as BaseRequest<FilterMasterData>

      const resp = await getMasterDataDropdown(body)
      if (resp?.response?.data) {
        const {data} = resp.response
        // const item = data.find((item) => item.value === currentUser?.detail_data?.outlet?.brand_name)
        // if (item) {
        //   const selected = {value: item.guid, label: item.value}
        //   onValueChange(selected)
        // }
        return {
          options: data?.map((x) => ({label: x.value, value: x.guid})),
          hasMore: page < (resp?.response?.total_page || 0),
        }
      }

      return {options: [], hasMore: false, additional: page}
    } catch (error) {
      return {
        options: [],
        hasMore: false,
      }
    }
  }

  const loadOptions: any = async (q, _, additional) => {
    const {page} = additional!
    const {options: responseOptions, hasMore} = await loadOptionsBrands(q, page)

    return {
      options: responseOptions,
      hasMore,
      additional: {
        page: page + 1,
      },
    }
  }

  useEffect(() => {
    onValueChange(currentValue)
  }, [currentValue])

  return (
    <DropdownSelectAsyncPaginate
      key={'brand'}
      placeholder='All Brand'
      loadOptions={loadOptions}
      onChange={(newValues) => onValueChange(newValues)}
      value={currentValue}
      isClearable
      isMulti
    />
  )
}

export {FilterDropdownBrand}
