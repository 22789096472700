import {DropdownIconSelect} from '@/_metronic/partials/content/dropdown-select/DropdownIconSelect'
import {OptionType} from '@/_metronic/partials/content/dropdown-select/DropdownSelectAsyncPaginate'
import {FC, FocusEventHandler} from 'react'

type Props = {
  value: any
  onChange: (value: any) => void
  isDisabled?: boolean
  options: Array<OptionType>
  onBlur: FocusEventHandler<HTMLInputElement>
}

const DropdownFacility: FC<Props> = ({value, onChange, onBlur, isDisabled = false, options}) => {
  return (
    <DropdownIconSelect
      // styles={{
      //   control: (provided) => ({
      //     ...provided,
      //     border: 'none',
      //   }),
      // }}
      // components={{
      //   IndicatorSeparator: null,
      //   DropdownIndicator: null,
      //   Control: (props) => (
      //     <components.Control
      //       {...props}
      //       className={clsx(props.className, 'form-select form-select-solid py-0 px-1 border-0')}
      //     />
      //   ),
      //   Placeholder: (props) => (
      //     <components.Placeholder {...props} className={clsx(props.className)} />
      //   ),
      //   SelectContainer: (props) => (
      //     <components.SelectContainer {...props} className={clsx(props.className, 'border-0')} />
      //   ),
      //   Input: (props) => <components.Input {...props} />,
      // }}
      value={value}
      onChange={onChange}
      isDisabled={isDisabled}
      placeholder='Tipe fasilitas'
      options={options}
      onBlur={onBlur}
      icon='/media/svg/orders/house.svg'
    />
  )
}

export default DropdownFacility
