import { toAbsoluteUrl } from '@/_metronic/helpers'
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import ForgotPasswordForm from './components/ForgotPasswordForm'
import { FormStateProvider, useBookingFormState } from './core/FormStateProvider'
import { ModalProvider, useSelectTreatmentModal } from './core/_ModalProvider'

const ForgotPasswordPage: FC = () => {
  const {id} = useParams()
  const {showSuccessModal} = useSelectTreatmentModal()
  const {setIsLoading} = useBookingFormState()

  const handleForgotPassword = async (body: any) => {
    showSuccessModal()
    // try {
    //   const res = await createOrder(body)
    //   if (res?.response?.code === RESP.SUCCESS) {
    //     showSuccessModal()
    //   }
    // } catch (error) {
    //   console.error(error)
    // } finally {
    //   setIsLoading(false)
    // }
  }

  return (
    <>
      <div className='container z-10'>
        <div className='row justify-content-center my-5 gap-5'>
          <div className='col-12 d-flex justify-content-center'>
            <img src={toAbsoluteUrl('/media/logos/logo-alaya.png')} className='w-200px img-fluid' />
          </div>
          <div className='col-12 col-md-8 col-lg-6'>
            <div className='card py-4 px-5 shadow-wit-2 bg-illustration-order'>
              <div className='text-center'>
                <h1 className='fw-bolder mt-4'>Forgot Password</h1>
              </div>
              <ForgotPasswordForm handleSubmit={handleForgotPassword} />
              <img
                src={toAbsoluteUrl('/media/illustrations/Ilustration_order.png')}
                className='w-200px img-fluid position-absolute backdrop-order bottom-0 z-9'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const ForgotPasswordWrapper: FC = () => {
  return (
    <>
      <FormStateProvider>
        <ModalProvider>
          <ForgotPasswordPage />
        </ModalProvider>
      </FormStateProvider>
    </>
  )
}

export default ForgotPasswordWrapper
