import {WithChildren} from '@/_metronic/helpers'
import {createContext, FC, useContext} from 'react'
import Swal from 'sweetalert2'
import withReactContent, {ReactSweetAlert} from 'sweetalert2-react-content'

type SwalContextProps = {
  swal?: ReactSweetAlert
}

const SwalContext = createContext<SwalContextProps>({
  swal: undefined,
})

const SwalProvider: FC<WithChildren> = ({children}) => {
  const swal = withReactContent(Swal)

  return <SwalContext.Provider value={{swal}}>{children}</SwalContext.Provider>
}

const useSwal = () => useContext(SwalContext)

export {SwalProvider, useSwal}
