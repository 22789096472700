import {MenuComponent} from '@/_metronic/assets/ts/components'
import {DropdownSelect} from '@/_metronic/partials/content/dropdown-select/DropdownSelect'
import PropagateWrapper from '@/_metronic/partials/content/propagate/PropagateWrapper'
import {SingleDatePicker} from '@/_metronic/partials/content/single-datepicker/SingleDatePicker'
import moment from 'moment'
import {useEffect, useRef, useState} from 'react'
import {useFilterReportRushHour} from '../../core/FilterReportRushHourProvider'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {initialFilter} from '../../core/_models'
import {FilterDropdownBrand} from './FilterDropdownBrand'
import {FilterDropdownCity} from './FilterDropdownCity'
import {FilterDropdownOutlet} from './FilterDropdownOutlet'
import {FilterDropdownRegion} from './FilterDropdownRegion'
import {FilterDropdownSubBrand} from './FilterDropdownSubBrand'

const optionsArea = [
  {value: 'WIB', label: 'WIB'},
  {value: 'WITA', label: 'WITA'},
  {value: 'WIT', label: 'WIT'},
]

const FilterReportRushHourForm = ({closeModal}) => {
  const {updateFilterReportRushHourFormValue, filterReportRushHourFormValue} =
    useFilterReportRushHour()
  const {updateState} = useQueryRequest()
  const [filterValue, setFilterValue] = useState(initialFilter)
  const [selectedArea, setSelectedArea] = useState<any>([])
  const [selectedOutlet, setSelectedOutlet] = useState<any>([])
  const [selectedRegion, setSelectedRegion] = useState<any>([])
  const [selectedCity, setSelectedCity] = useState<any>([])
  const [selectedBrand, setSelectedBrand] = useState<any>([])
  const [selectedSubBrand, setSelectedSubBrand] = useState<any>([])
  const requestDateRef = useRef<any>()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  useEffect(() => {
    setFilterValue({
      ...filterValue,
      start_date: moment().local().format('yyyy-MM-DD'),
      end_date: moment().local().format('yyyy-MM-DD'),
    })
    if (filterReportRushHourFormValue) {
      setSelectedArea(filterReportRushHourFormValue.area)
      setSelectedOutlet(filterReportRushHourFormValue.outlet)
      setSelectedBrand(filterReportRushHourFormValue.brand)
      setSelectedSubBrand(filterReportRushHourFormValue.sub_brand)
      setSelectedRegion(filterReportRushHourFormValue.region)
      setSelectedCity(filterReportRushHourFormValue.city)

      let areaList: string[] = []
      if (filterReportRushHourFormValue.area) {
        filterReportRushHourFormValue.area.forEach((element) => {
          areaList.push(element.value)
        })
      }
      let outletList: string[] = []
      if (filterReportRushHourFormValue.outlet) {
        filterReportRushHourFormValue.outlet.forEach((element) => {
          outletList.push(element.value)
        })
      }
      let brandList: string[] = []
      if (filterReportRushHourFormValue.brand) {
        filterReportRushHourFormValue.brand.forEach((element) => {
          brandList.push(element.value)
        })
      }
      let subBrandList: string[] = []
      if (filterReportRushHourFormValue.sub_brand) {
        filterReportRushHourFormValue.sub_brand.forEach((element) => {
          subBrandList.push(element.value)
        })
      }
      let regionList: string[] = []
      if (filterReportRushHourFormValue.region) {
        filterReportRushHourFormValue.region.forEach((element) => {
          regionList.push(element.value)
        })
      }
      let cityList: string[] = []
      if (filterReportRushHourFormValue.city) {
        filterReportRushHourFormValue.city.forEach((element) => {
          cityList.push(element.value)
        })
      }

      setFilterValue({
        ...filterValue,
        set_area: Boolean(filterReportRushHourFormValue.area),
        area: areaList,
        set_outlet_id: Boolean(filterReportRushHourFormValue.outlet),
        outlet_id: outletList,
        set_brand_id: Boolean(filterReportRushHourFormValue.brand),
        brand_id: brandList,
        set_sub_brand_id: Boolean(filterReportRushHourFormValue.sub_brand),
        sub_brand_id: subBrandList,
        set_region_id: Boolean(filterReportRushHourFormValue.region),
        region_id: regionList,
        set_city_id: Boolean(filterReportRushHourFormValue.city),
        city_id: cityList,
        start_date: filterReportRushHourFormValue.start_date,
        end_date: filterReportRushHourFormValue.end_date,
      })
    } else {
      updateFilterReportRushHourFormValue({
        ...filterReportRushHourFormValue,
        start_date: moment().local().format('yyyy-MM-DD'),
        end_date: moment().local().format('yyyy-MM-DD'),
      })
    }
  }, [])

  const resetData = () => {
    setSelectedOutlet([])
    setSelectedArea([])
    setSelectedBrand([])
    setSelectedSubBrand([])
    setSelectedRegion([])
    setSelectedCity([])
    setFilterValue({
      ...filterValue,
      start_date: moment().local().format('yyyy-MM-DD'),
      end_date: moment().local().format('yyyy-MM-DD'),
    })
    updateFilterReportRushHourFormValue({
      start_date: moment().local().format('yyyy-MM-DD'),
      end_date: moment().local().format('yyyy-MM-DD'),
    })
    updateState(initialFilter)
    closeModal()
  }

  const filterData = () => {
    filterValue.set_brand_id = filterValue.brand_id && filterValue.brand_id.length > 0
    filterValue.set_sub_brand_id = filterValue.sub_brand_id && filterValue.sub_brand_id.length > 0
    filterValue.set_region_id = filterValue.region_id && filterValue.region_id.length > 0
    filterValue.set_city_id = filterValue.city_id && filterValue.city_id.length > 0
    filterValue.set_area = filterValue.area && filterValue.area.length > 0
    filterValue.set_outlet_id = filterValue.outlet_id && filterValue.outlet_id.length > 0
    updateState(filterValue)
    closeModal()
  }

  const onChangeSelected = (name: string) => (value) => {
    if (name === 'area') {
      setSelectedArea(value)

      let areaList: string[] = []
      if (value) {
        value.forEach((element) => {
          areaList.push(element.value)
        })
      }
      setFilterValue({
        ...filterValue,
        set_area: Boolean(areaList),
        area: areaList,
      })
      updateFilterReportRushHourFormValue({
        ...filterReportRushHourFormValue,
        area: value,
      })
    }
  }

  return (
    <>
      <div className='px-7 py-5' data-kt-user-table-filter='form'>
        <PropagateWrapper>
          <div className='row'>
            <div className='col-6'>
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>Brand:</label>
                <FilterDropdownBrand
                  currentValue={selectedBrand || null}
                  onValueChange={(value) => {
                    setSelectedBrand(value)
                    let brandList: string[] = []
                    if (value) {
                      value.forEach((element) => {
                        brandList.push(element.value)
                      })
                    }
                    setFilterValue({
                      ...filterValue,
                      set_brand_id: Boolean(selectedBrand),
                      brand_id: brandList,
                    })
                    updateFilterReportRushHourFormValue({
                      ...filterReportRushHourFormValue,
                      brand: selectedBrand,
                    })
                  }}
                />
              </div>
            </div>
            <div className='col-6'>
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>Sub Brand:</label>
                <FilterDropdownSubBrand
                  currentValue={selectedSubBrand || null}
                  onValueChange={(value) => {
                    setSelectedSubBrand(value)
                    let subBrandList: string[] = []
                    if (value) {
                      value.forEach((element) => {
                        subBrandList.push(element.value)
                      })
                    }
                    setFilterValue({
                      ...filterValue,
                      set_sub_brand_id: Boolean(selectedSubBrand),
                      sub_brand_id: subBrandList,
                    })
                    updateFilterReportRushHourFormValue({
                      ...filterReportRushHourFormValue,
                      sub_brand: selectedSubBrand,
                    })
                  }}
                />
              </div>
            </div>
            <div className='col-6'>
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>Region:</label>
                <FilterDropdownRegion
                  currentValue={selectedRegion || null}
                  onValueChange={(value) => {
                    setSelectedRegion(value)
                    let regionList: string[] = []
                    if (value) {
                      value.forEach((element) => {
                        regionList.push(element.value)
                      })
                    }
                    setFilterValue({
                      ...filterValue,
                      set_region_id: Boolean(selectedRegion),
                      region_id: regionList,
                    })
                    updateFilterReportRushHourFormValue({
                      ...filterReportRushHourFormValue,
                      region: selectedRegion,
                    })
                  }}
                />
              </div>
            </div>
            <div className='col-6'>
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>City:</label>
                <FilterDropdownCity
                  currentValue={selectedCity || null}
                  onValueChange={(value) => {
                    setSelectedCity(value)
                    let cityList: string[] = []
                    if (value) {
                      value.forEach((element) => {
                        cityList.push(element.value)
                      })
                    }
                    setFilterValue({
                      ...filterValue,
                      set_city_id: Boolean(selectedCity),
                      city_id: cityList,
                    })
                    updateFilterReportRushHourFormValue({
                      ...filterReportRushHourFormValue,
                      city: selectedCity,
                    })
                  }}
                />
              </div>
            </div>
            <div className='col-6'>
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>Area:</label>
                <DropdownSelect
                  options={optionsArea}
                  value={selectedArea}
                  onChange={onChangeSelected('area')}
                  placeholder='All Area'
                  isMulti
                  isClearable
                />
              </div>
            </div>

            <div className='col-6'>
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>Outlet:</label>
                <FilterDropdownOutlet
                  placeholder='All Outlet'
                  currentValue={selectedOutlet || null}
                  onValueChange={(value) => {
                    setSelectedOutlet(value)
                    let outletList: string[] = []
                    if (value) {
                      value.forEach((element) => {
                        outletList.push(element.value)
                      })
                    }
                    setFilterValue({
                      ...filterValue,
                      set_outlet_id: Boolean(selectedOutlet),
                      outlet_id: outletList,
                    })
                    updateFilterReportRushHourFormValue({
                      ...filterReportRushHourFormValue,
                      outlet: selectedOutlet,
                    })
                  }}
                  isDisabled={false}
                />
              </div>
            </div>
            <div className='col-6'>
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>Start Date:</label>
                <SingleDatePicker
                  pickerRef={requestDateRef}
                  startDate={
                    filterReportRushHourFormValue && filterReportRushHourFormValue.start_date
                      ? moment(filterReportRushHourFormValue.start_date).format('MM/DD/yyyy')
                      : moment().format('MM/DD/yyyy')
                  }
                  onCallback={(start_date) => {
                    const outputDateString = moment(start_date).format('yyyy-MM-DD')
                    setFilterValue({
                      ...filterValue,
                      start_date: outputDateString,
                    })
                    updateFilterReportRushHourFormValue({
                      ...filterReportRushHourFormValue,
                      start_date: outputDateString,
                    })
                  }}
                  disabled={false}
                  placeholder='Select end date'
                />
              </div>
            </div>
            <div className='col-6'>
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>End Date:</label>
                <SingleDatePicker
                  pickerRef={requestDateRef}
                  startDate={
                    filterReportRushHourFormValue && filterReportRushHourFormValue.end_date
                      ? moment(filterReportRushHourFormValue.end_date).format('MM/DD/yyyy')
                      : moment().format('MM/DD/yyyy')
                  }
                  onCallback={(end_date) => {
                    const outputDateString = moment(end_date).format('yyyy-MM-DD')
                    setFilterValue({
                      ...filterValue,
                      end_date: outputDateString,
                    })
                    updateFilterReportRushHourFormValue({
                      ...filterReportRushHourFormValue,
                      end_date: outputDateString,
                    })
                  }}
                  disabled={false}
                  placeholder='Select end date'
                />
              </div>
            </div>
          </div>
        </PropagateWrapper>
        <div className='d-flex justify-content-end'>
          <button
            type='button'
            // disabled={isLoading}
            onClick={resetData}
            className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
            data-kt-menu-dismiss='true'
            data-kt-user-table-filter='reset'
          >
            Reset
          </button>
          <button
            // disabled={isLoading}
            type='button'
            onClick={filterData}
            className='btn btn-primary fw-bold px-6'
            data-kt-menu-dismiss='true'
            data-kt-user-table-filter='filter'
          >
            Apply
          </button>
        </div>
      </div>
    </>
  )
}

export {FilterReportRushHourForm}
