import {WithChildren} from '@/_metronic/helpers'
import {FC, createContext, useContext, useState} from 'react'

type AsideMenuContextProps = {
  search: string
  setSearch: (search: string) => void
}

const initialValue: AsideMenuContextProps = {
  search: '',
  setSearch: () => {},
}

const AsideMenuContext = createContext<AsideMenuContextProps>(initialValue)

const AsideMenuProvider: FC<WithChildren> = ({children}) => {
  const [search, setSearch] = useState<string>('')
  return (
    <AsideMenuContext.Provider value={{search, setSearch}}>{children}</AsideMenuContext.Provider>
  )
}

const useAsideMenu = () => useContext(AsideMenuContext)

export {AsideMenuProvider, useAsideMenu}
