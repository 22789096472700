import {FormattedNumber} from 'react-intl'

const TopOutlets = ({data}) => {
  return (
    <div className='card card-flush shadow-wit-2 h-xl-100'>
      <div className='card-header pt-7'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-gray-800'>Top Outlets</span>

          <span className='text-gray-400 mt-1 fw-semibold fs-6'>Top 10</span>
        </h3>

        {/* <div className='card-toolbar'>
          <div className='card-toolbar'>
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-250px ps-14'
              placeholder='Search user'
              value=''
            />
          </div>
        </div> */}
      </div>

      <div className='card-body pt-3 pb-4'>
        <div className='table-responsive'>
          <table className='table table-row-dashed align-middle gs-0 gy-4 my-0'>
            <thead>
              <tr className='fs-7 fw-bold text-gray-500 border-bottom-0'>
                <th className='p-0 min-w-20px'></th>
                <th className='p-0 min-w-200px'></th>
                <th className='p-0 min-w-150px'></th>
                <th className='p-0 min-w-150px'></th>
                <th className='p-0 min-w-150px'></th>
                <th className='p-0 min-w-150px'></th>
                <th className='p-0 min-w-150px'></th>
              </tr>
            </thead>
            <tbody>
              {data !== undefined ? (
                data?.map((item, index) => (
                  <tr key={index}>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='text-gray-800 fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {index + 1}
                      </a>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a
                            href='#'
                            className='text-gray-800 fw-bold text-hover-primary mb-1 fs-6'
                          >
                            {item && item.outlet_name}
                          </a>
                          <span className='text-gray-400 fw-semibold d-block fs-7'>
                            {item && item.outlet_area} - {item && item.region_name}
                          </span>
                        </div>
                      </div>
                    </td>

                    <td className='text-end'>
                      <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                        Rp. <FormattedNumber value={item && item.revenue_after_tax_service} />
                      </span>
                      <span className='fw-semibold text-gray-400 d-block'>
                        Revenue after Tax & Service
                      </span>
                    </td>

                    <td className='text-end'>
                      <a
                        href='#'
                        className='text-gray-800 fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        Rp. <FormattedNumber value={item && item.revenue_share_outlet} />
                      </a>
                      <span className='text-gray-400 fw-semibold d-block fs-7'>
                        Revenue Sharing Outlet
                      </span>
                    </td>

                    <td className='text-end'>
                      <a
                        href='#'
                        className='text-gray-800 fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        Rp. <FormattedNumber value={item && item.service_share_outlet} />
                      </a>
                      <span className='text-gray-400 fw-semibold d-block fs-7'>
                        Service Sharing Outlet
                      </span>
                    </td>

                    <td className='text-end'>
                      <a
                        href='#'
                        className='text-gray-800 fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        Rp. <FormattedNumber value={item && item.revenue_share_partner} />
                      </a>
                      <span className='text-gray-400 fw-semibold d-block fs-7'>
                        Revenue Sharing Hotel
                      </span>
                    </td>

                    <td className='text-end'>
                      <a
                        href='#'
                        className='text-gray-800 fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        Rp. <FormattedNumber value={item && item.service_share_partner} />
                      </a>
                      <span className='text-gray-400 fw-semibold d-block fs-7'>
                        Service Sharing Hotel
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default TopOutlets
