import { KTIcon } from '@/_metronic/helpers'
import { FC, useState } from 'react'
import { useFilterReportSalesBudget } from '../../core/FilterReportSalesBudgetProvider'

type Props = {
  monthly: boolean
  closeModal: () => void
}

const FilterReportSalesBudgetModalHeader: FC<Props> = ({closeModal, monthly}) => {
  const {updateFilterReportSalesBudgetFormMonthlyValue, updateFilterReportSalesBudgetFormDailyValue, filterReportSalesBudgetFormMonthlyValue, filterReportSalesBudgetFormDailyValue} =
    useFilterReportSalesBudget()
  const [filterValueTemp, setFilterValueTemp] = useState(monthly? filterReportSalesBudgetFormMonthlyValue : filterReportSalesBudgetFormDailyValue)

  const cancelModal = () => {
    if(monthly){
      updateFilterReportSalesBudgetFormMonthlyValue(filterValueTemp)
    }else {
      updateFilterReportSalesBudgetFormDailyValue(filterValueTemp)
    }
    closeModal()
    
  }

  // const cancelModalDaily = () => {
  //   updateFilterReportSalesBudgetFormDailyValue(filterValueTemp)
  //   closeModalDaily()
  // }

  return (
    <div className='modal-header'>
      <h2 className='fw-bolder'>Advanced Filter</h2>

      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={cancelModal}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>
    </div>
  )
}

export { FilterReportSalesBudgetModalHeader }

