import {MenuAccess} from '@/app/modules/user-management/user-management-edit/core/_model'
import {FC} from 'react'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import parse from 'html-react-parser'

type Props = {
  menu: MenuAccess
}

const AsideMenuItemWrapper: FC<Props> = ({menu}) => {
  if (menu?.level === 1 && !menu?.url_path) {
    return (
      <>
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
              {parse(menu?.text || '')}
            </span>
          </div>
        </div>
        {(menu?.sub_menu || []).length > 0 &&
          menu?.sub_menu?.map((sub) => <AsideMenuItemWrapper key={sub?.id} menu={sub} />)}
      </>
    )
  }

  if (menu?.sub_menu && menu?.sub_menu?.length) {
    return (
      <AsideMenuItemWithSub to={menu?.url_path || '#'} icon={menu?.icon} title={menu?.text || ''}>
        {menu?.sub_menu?.map((sub) => (
          <AsideMenuItemWrapper key={sub?.id} menu={sub} />
        ))}
      </AsideMenuItemWithSub>
    )
  }
  return <AsideMenuItem to={menu?.url_path || '#'} icon={menu?.icon} title={menu?.text || ''} />
}

export {AsideMenuItemWrapper}
