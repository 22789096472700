import { KTCard } from '@/_metronic/helpers/components/KTCard'
import { FC, useState } from 'react'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { Detail } from './Detail'
import Overview from './Overview'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { FilterReportBookingTypeProvider, useFilterReportBookingType } from './core/FilterReportBookingTypeProvider'
import { ReportBookingTypeListHeader } from './components/filter/ReportBookingTypeListHeader'
import { FilterReportBookingTypeModal } from './components/filter/FilterReportBookingTypeModal'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Report',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Sales',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ReportBookingTypePage = () => {
  const [activeTab, setActiveTab] = useState<string>('overview')
  const {closeModal, showFilterReportBookingTypeModal} = useFilterReportBookingType()

  return (
    <>
      <PageTitle breadcrumbs={[]}>Dashboard Report Booking Type</PageTitle>

      <KTCard className='py-4 shadow-wit-2 mb-10'>
        <ReportBookingTypeListHeader />
      </KTCard>

      {activeTab === 'overview' ? <Overview /> : <Detail />}
      <FilterReportBookingTypeModal
        show={showFilterReportBookingTypeModal}
        closeModal={() => closeModal()}
      />
    </>
  )
}

const ReportBookingTypeWrapper: FC = () => {
  return (
    <>
      <QueryRequestProvider>
        <QueryResponseProvider>
          <FilterReportBookingTypeProvider>
            <ReportBookingTypePage />
          </FilterReportBookingTypeProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  )
}

export { ReportBookingTypeWrapper }

