import { KTIcon } from '@/_metronic/helpers'
import { FC, useState } from 'react'
import { useFilterReportRevenueSharing } from '../../core/FilterReportRevenueSharingProvider'

type Props = {
  closeModal: () => void
}

const FilterReportRevenueSharingModalHeader: FC<Props> = ({closeModal}) => {
  const {updateFilterReportRevenueSharingFormValue, filterReportRevenueSharingFormValue} = useFilterReportRevenueSharing()
  const [filterValueTemp, setFilterValueTemp] = useState(filterReportRevenueSharingFormValue)

  const cancelModal = () => {
    updateFilterReportRevenueSharingFormValue(filterValueTemp)
    closeModal()
  }

  return (
    <div className='modal-header'>
      <h2 className='fw-bolder'>Advanced Filter</h2>

      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={cancelModal}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>
    </div>
  )
}

export { FilterReportRevenueSharingModalHeader }

