import { axios } from '@/_metronic/helpers/network/Axios'
import { AxiosResponse } from 'axios'
import {
  FilterTop,
  RevenueStreamAreaQueryResponse,
  RevenueStreamCityQueryResponse,
  RevenueStreamOutletQueryResponse,
} from './_models'

const REPORT_API_URL = '/report-services'
const GET_REVENUE_STREAM_OUTLET_LIST = '/dashboard/revenue-stream/outlet'
const GET_REVENUE_STREAM_CITY_LIST = '/dashboard/revenue-stream/city'
const GET_REVENUE_STREAM_AREA_LIST = '/dashboard/revenue-stream/area'

const getRevenueTopOutlet = (body: FilterTop): Promise<RevenueStreamOutletQueryResponse> => {
  return axios
    .post(`${REPORT_API_URL}${GET_REVENUE_STREAM_OUTLET_LIST}`, body)
    .then((d: AxiosResponse<RevenueStreamOutletQueryResponse>) => d.data)
}
const getRevenueTopCity = (body: FilterTop): Promise<RevenueStreamCityQueryResponse> => {
  return axios
    .post(`${REPORT_API_URL}${GET_REVENUE_STREAM_CITY_LIST}`, body)
    .then((d: AxiosResponse<RevenueStreamCityQueryResponse>) => d.data)
}
const getRevenueTopArea = (body: FilterTop): Promise<RevenueStreamAreaQueryResponse> => {
  return axios
    .post(`${REPORT_API_URL}${GET_REVENUE_STREAM_AREA_LIST}`, body)
    .then((d: AxiosResponse<RevenueStreamAreaQueryResponse>) => d.data)
}

export { getRevenueTopArea, getRevenueTopCity, getRevenueTopOutlet }

