import {isNotEmpty, QUERIES, WithChildren} from '@/_metronic/helpers'
import {FC, createContext, useContext} from 'react'
import {useQuery} from 'react-query'
import {useParams} from 'react-router-dom'
import {PartnerOrganization} from './_models'
import {getPartnerOrganizationById} from './_requests'

type DetailResponseContextProps = {
  data?: PartnerOrganization
  isFetching: boolean
  refetch: () => void
}

const DetailResponseContext = createContext<DetailResponseContextProps>({
  data: undefined,
  isFetching: false,
  refetch: () => {},
})

const DetailResponseProvider: FC<WithChildren> = ({children}) => {
  const {id} = useParams()
  const enabledQuery: boolean = isNotEmpty(id)

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.PARTNER_ORGANIZER_LIST}-partner-organizer-${id}`,
    () => {
      return getPartnerOrganizationById(id)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        console.error(err)
      },
    }
  )

  return (
    <DetailResponseContext.Provider value={{data: response?.response?.data, isFetching, refetch}}>
      {children}
    </DetailResponseContext.Provider>
  )
}

const useDetailResponse = () => useContext(DetailResponseContext)

export {DetailResponseProvider, useDetailResponse}
