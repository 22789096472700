/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTCard } from '@/_metronic/helpers/components/KTCard'
import { FC } from 'react'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { TrendAnalysisOverview } from './TrendAnalysisOverview'
import { ReportTrendAnalysisListHeader } from './components/filter/ReportTrendAnalysisListHeader'
import { FilterReportTrendAnalysisProvider } from './core/FilterReportTrendAnalysisProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Report',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Sales',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const TrendAnalysisPage: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>Trend Analysis</PageTitle>
      <KTCard className='py-4 shadow-wit-2'>
        <ReportTrendAnalysisListHeader />
      </KTCard>
      <TrendAnalysisOverview />
    </>
  )
}

const TrendAnalysisWrapper: FC = () => {
  return (
    <>
      <QueryRequestProvider>
        <QueryResponseProvider>
          <FilterReportTrendAnalysisProvider>
            <PageTitle breadcrumbs={[]}>Trend Analysis</PageTitle>
            <TrendAnalysisPage />
          </FilterReportTrendAnalysisProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  )
}

export { TrendAnalysisWrapper }

