import { FC } from 'react'
import { Modal } from 'react-bootstrap'
import { useSelectTreatmentModal } from '../core/_ModalProvider'

type Props = {
  onLogin: () => void
}

const ForgotPasswordSuccessModal: FC<Props> = ({onLogin}) => {
  const {showSuccess, closeSuccessModal} = useSelectTreatmentModal()

  return (
    <Modal className='modal fade' onHide={closeSuccessModal} show={showSuccess}>
      <div className='modal-content'>
        <div className='modal-body'>
          <div className='row text-center'>
            <div className='col-12'>
              <div className='d-flex justify-content-center p-7'>
                <i className='bi bi-check-circle-fill text-success' style={{fontSize: 96}}></i>
              </div>
            </div>
            <div className='col-12'>
              <h3>Password Berhasil Diubah</h3>
            </div>
            <div className='col-12'>
              <b>Silahkan login kembali</b>
            </div>
            <div className='col-12'>
              <button className='btn btn-primary btn-sm mt-4 w-75' type='button' onClick={onLogin}>
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export { ForgotPasswordSuccessModal }

