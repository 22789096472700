import { WithChildren } from '@/_metronic/helpers'
import { createContext, FC, useContext, useState } from 'react'
import { FilterReportRevenueSharing, initialFilter } from './_models'

type ProductQueryRequestContextProps = {
  state: FilterReportRevenueSharing
  updateState: (updates: Partial<FilterReportRevenueSharing>) => void
}

const initialQueryRequest = {
  state: initialFilter,
  updateState: () => {},
}

const QueryRequestContext = createContext<ProductQueryRequestContextProps>(initialQueryRequest)

const QueryRequestProvider: FC<WithChildren> = ({children}) => {
  const [state, setState] = useState<FilterReportRevenueSharing>(initialQueryRequest.state)

  const updateState = (updates: Partial<FilterReportRevenueSharing>) => {
    const updatedState = {...state, ...updates} as FilterReportRevenueSharing
    setState(updatedState)
  }

  return (
    <QueryRequestContext.Provider value={{state, updateState}}>
      {children}
    </QueryRequestContext.Provider>
  )
}

const useQueryRequest = () => useContext(QueryRequestContext)
export { QueryRequestProvider, useQueryRequest }

