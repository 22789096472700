import { FC, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import ForecastOverviewMonthly from "../../ForecastOverviewMonthly"
import ForecastOverviewDaily from "../../ForecastOverviewDaily"
import { useFilterReportSalesBudget } from "../../core/FilterReportSalesBudgetProvider"
import { FilterReportSalesBudgetModal } from "../filter/FilterReportSalesBudgetModal"
import { KTCard } from "@/_metronic/helpers"
import { ReportSalesBudgetListHeader } from "../filter/ReportSalesBudgetListHeader"

type Props = {
}

const DashboardForecastBody: FC<Props> = () => {

  const location = useLocation()
  const { closeModalMonthly, closeModalDaily, showFilterReportSalesBudgetMonthlyModal, showFilterReportSalesBudgetDailyModal } = useFilterReportSalesBudget()
  const [selectedTabIndex, setSelectedTabIndex] = useState(0); // Initial selected tab

  const handleTabClick = (index) => {
    setSelectedTabIndex(index);
  };


  return (
    <div id='kt_content_container' className='flex-lg-row-fluid mb-10 mb-xl-0'>
      {/*begin::Form*/}
      <form
        id='kt_ecommerce_add_product_form'
        className='form d-flex flex-column flex-lg-row'
        data-kt-redirect='/metronic8/demo8/../demo8/apps/ecommerce/catalog/products.html'
      >
        {/*begin::Main column*/}
        <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
          <KTCard className='py-4 shadow-wit-2 mb-10'>
            <div className="d-flex row">
              <div className="col-lg-3 d-flex align-items-center">
                {/*begin:::Tabs*/}
                <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2 ms-8'>
                  {/*begin:::Tab item*/}
                  <li className='nav-item'>
                    <a
                      className={`nav-link text-active-primary pb-4 ${selectedTabIndex === 0 ? 'active' : ''}`}
                      data-bs-toggle='tab'
                      href='#kt_ecommerce_monthly'
                      onClick={() => handleTabClick(0)}
                    >
                      Monthly
                    </a>
                  </li>
                  {/*end:::Tab item*/}
                  {/*begin:::Tab item*/}
                  {!location?.pathname?.includes('/add') && <li className='nav-item'>
                    <a
                      className={`nav-link text-active-primary pb-4 ${selectedTabIndex === 1 ? 'active' : ''}`}
                      data-bs-toggle='tab'
                      href='#kt_ecommerce_daily'
                      onClick={() => handleTabClick(1)}
                    >
                      Daily
                    </a>
                  </li>}
                  {/*end:::Tab item*/}
                </ul>
                {/*end:::Tabs*/}
              </div>
              <div className="col-lg-9">
                {selectedTabIndex === 0 &&
                  <>
                    <ReportSalesBudgetListHeader monthly />
                  </>}
                {selectedTabIndex === 1 &&
                  <>
                    <ReportSalesBudgetListHeader monthly={false} />
                  </>}

              </div>
            </div>
          </KTCard>
          {/*begin::Tab content*/}
          <div className='tab-content'>
            {/*begin::Tab pane*/}
            <div
              className='tab-pane fade show active'
              id='kt_ecommerce_monthly'
            >
              {/* <KTCard className='py-4 shadow-wit-2 mb-10'>
                <ReportSalesBudgetListHeader monthly />
              </KTCard>
              <FilterReportSalesBudgetModal
                monthly={true}
                show={showFilterReportSalesBudgetMonthlyModal}
                closeModal={() => closeModalMonthly()}
              /> */}
              <ForecastOverviewMonthly />
            </div>
            {/*end::Tab pane*/}
            {/*begin::Tab pane*/}
            {!location?.pathname?.includes('/add') && <div
              className='tab-pane fade'
              id='kt_ecommerce_daily'
            >
              {/* <KTCard className='py-4 shadow-wit-2 mb-10'>
                <ReportSalesBudgetListHeader monthly={false} />
              </KTCard>
              <FilterReportSalesBudgetModal
                monthly={false}
                show={showFilterReportSalesBudgetDailyModal}
                closeModal={() => closeModalDaily()}
              /> */}
              <ForecastOverviewDaily />
            </div>}
            {/*end::Tab pane*/}
          </div>
          <FilterReportSalesBudgetModal
            monthly={selectedTabIndex === 0}
            show={selectedTabIndex === 0 ? showFilterReportSalesBudgetMonthlyModal : showFilterReportSalesBudgetDailyModal}
            closeModal={() => selectedTabIndex === 0 ? closeModalMonthly() : closeModalDaily()}
          />
          {/*end::Tab content*/}
        </div>
        {/*end::Main column*/}
      </form>
      {/*end::Form*/}
    </div>
  )
}

export { DashboardForecastBody }
