import {isNotEmpty, QUERIES} from '@/_metronic/helpers'
import {InputGroup} from '@/_metronic/partials/content/input-group/InputGroup'
import {useFormik} from 'formik'
import {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import * as Yup from 'yup'
import {
  createPartnerOrganizationPic,
  getPartnerOrganizationById,
  updatePartnerOrganization,
  updatePartnerOrganizationPic,
} from '../../../core/_requests'
import {getJobDropdown} from '@/app/core/master-data/job/_requests'
import {initialFilterJob} from '@/app/core/master-data/job/_models'
import {DropdownAsyncSelect} from '@/_metronic/partials/content/dropdown-select/DropdownAsyncSelect'
import {forecaseTargetSalesColumns} from '@/app/modules/forecast-target-sales/forecast-target-sales-list/table/columns/_columns'
import {DropdownSelectAsyncPaginate} from '@/_metronic/partials/content/dropdown-select/DropdownSelectAsyncPaginate'

const partnerOrganizationScheme = Yup.object().shape({
  surname: Yup.string().required('Name tidak boleh kosong'),
  full_name: Yup.string().required('Address tidak boleh kosong'),
  job_title: Yup.string().required('Job title tidak boleh kosong'),
})

const partnerOrganizationInitialValue = {
  partner_organization_guid: '',
  surname: 'Mr.',
  full_name: '',
  job_title: '',
  guid: '',
}

const PersoninChargeForm = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const {id} = useParams()
  const enabledQuery: boolean = isNotEmpty(id)

  const [isReadOnly, setIsReadOnly] = useState<boolean>(false)

  const {data: response} = useQuery(
    `${QUERIES.PARTNER_ORGANIZER_LIST}-partner-organizer-${id}`,
    () => {
      return getPartnerOrganizationById(id)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
    }
  )

  const loadJobs = async (inputValue: any, page: number) => {
    try {
      const requestBody = initialFilterJob
      requestBody!.filter!.set_job_title = inputValue !== ''
      requestBody!.filter!.job_title = inputValue
      requestBody!.limit = 10
      requestBody!.page = page
      const data = await getJobDropdown(requestBody)

      // Transform data to options format expected by react-select
      const options =
        data.response?.data?.map((item) => ({
          value: item.guid,
          label: item.job_title,
          job_level: 'Job 1',
          // job_level: item?.level?.guid,
        })) || []

      return {
        options,
        hasMore: page < (data?.response?.total_page || 1),
        additional: {
          page: page + 1,
        },
      }
    } catch (error) {
      console.error(error)
      return {
        options: [],
        hasMore: false,
      }
    }
  }

  const loadOptionsJob: any = async (q, _, additional) => {
    const {page} = additional!
    const {options: responseOptions, hasMore} = await loadJobs(q, page)

    return {
      options: responseOptions,
      hasMore,
      additional: {
        page: page + 1,
      },
    }
  }

  const {handleChange, handleBlur, values, errors, handleSubmit, setValues} = useFormik({
    validationSchema: partnerOrganizationScheme,
    initialValues: partnerOrganizationInitialValue,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      if (!values.guid) {
        createPartnerOrganizationPic(values)
          .then((data) => {
            navigate('/apps/partner-organization/partner/list')
          })
          .catch((err) => console.error(err))
        return
      }

      updatePartnerOrganizationPic(values.guid, values)
        .then((data) => {
          navigate('/apps/partner-organization/partner/list')
        })
        .catch((err) => console.error(err))
    },
  })

  useEffect(() => {
    if (response) {
      const data = response?.response?.data
      setValues({
        partner_organization_guid: data?.guid || '',
        surname: data?.pic_partner_organization?.surname || 'Mr.',
        full_name: data?.pic_partner_organization?.full_name || '',
        job_title: data?.pic_partner_organization?.job_title || '',
        guid: data?.pic_partner_organization?.guid || '',
      })
    }
  }, [response])

  useEffect(() => {
    if (location?.pathname?.includes('/detail')) {
      setIsReadOnly(true)
    }
  }, [location])

  return (
    <form className='form d-flex flex-column flex-lg-row' onSubmit={handleSubmit}>
      <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
        <div className='card card-flush py-4'>
          <div className='card-header'>
            <div className='card-title'>
              <h2>General</h2>
            </div>
          </div>
          <div className='card-body pt-0'>
            <div className='row'>
              <div className='col-lg-12 fv-row  mb-10'>
                <div className='d-flex align-items-center mt-3'>
                  <label className='form-check form-check-inline form-check-solid me-5'>
                    <input
                      className='form-check-input'
                      name='communication[]'
                      type='radio'
                      checked={values.surname === 'Mr.'}
                      disabled={isReadOnly}
                      onChange={() => setValues({...values, surname: 'Mr.'})}
                    />
                    <span className='fw-bold ps-2 fs-6'>Mr</span>
                  </label>
                  <label className='form-check form-check-inline form-check-solid'>
                    <input
                      className='form-check-input'
                      name='communication[]'
                      type='radio'
                      checked={values.surname === 'Mrs.'}
                      disabled={isReadOnly}
                      onChange={() => setValues({...values, surname: 'Mrs.'})}
                    />
                    <span className='fw-bold ps-2 fs-6'>Mrs</span>
                  </label>
                </div>
              </div>
              <div className='col-6 mb-10'>
                <InputGroup
                  label='Full Name'
                  isValid={!Boolean(errors?.full_name)}
                  errorMessage={errors?.full_name}
                  value={values.full_name}
                  onChange={handleChange('full_name')}
                  onBlur={handleBlur('full_name')}
                  disabled={isReadOnly}
                />
              </div>
              <div className='col-6 mb-10'>
                <label className='required form-label'>Job Title</label>
                <DropdownSelectAsyncPaginate
                  loadOptions={loadOptionsJob}
                  placeholder='Select Job Title'
                  // isValid={!Boolean(errors?.job_title)}
                  // errorMessage={errors?.job_title}
                  isDisabled={isReadOnly}
                  value={
                    values.job_title ? {label: values.job_title, value: values.job_title} : null
                  }
                  onChange={(e: any) => setValues({...values, job_title: e.label})}
                />
                {Boolean(errors?.job_title) && (
                  <span className='text-danger mt-2'>{errors?.job_title}</span>
                )}
              </div>
            </div>
          </div>
        </div>
        {!isReadOnly && (
          <div className='d-flex justify-content-end'>
            <Link to='/apps/partner-organization/partner/list'>
              <a href='#' className='btn btn-light me-5'>
                Cancel
              </a>
            </Link>
            <button type='button' className='btn btn-primary' onClick={() => handleSubmit()}>
              Save Changes
            </button>
          </div>
        )}
      </div>
    </form>
  )
}

export {PersoninChargeForm}
