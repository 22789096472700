import { FC } from 'react'
import * as Yup from 'yup'
import { FormikProvider, useFormik } from 'formik'
import { RESP } from '@/_metronic/helpers/network/network-consts'
import { toast } from 'react-toastify'
import { Spinner } from 'react-bootstrap'
import { SingleDatePicker } from '@/_metronic/partials/content/single-datepicker/SingleDatePicker'
import moment from 'moment'
import clsx from 'clsx'
// import { useReportTransaction } from '../core/RequestProvider'
// import { closingReportTransaction } from '../core/_requests'
import { useSwal } from '@/app/core/SwalProvider'
import { ModalProvider } from '@/app/modules/report-transaction/core/_ModalProvider'
import { InputGroup } from '@/_metronic/partials/content/input-group/InputGroup'
import { isAxiosError } from 'axios'
import { changePassword } from '@/app/modules/auth/core/_requests'

type Props = {
  id: string
  closeModal: () => void
}


const changePasswordScheme = Yup.object().shape({
  old_password: Yup.string().required('Kata sandi lama tidak boleh kosong'),
  password: Yup.string().required('Kata sandi baru tidak boleh kosong'),
  password_confirmation: Yup.string().required('Konfirmasi kata sandi baru tidak boleh kosong'),
})

const changePasswordInitialValue = {
  old_password: '',
  password: '',
  password_confirmation: '',
}

const ChangePasswordForm: FC<Props> = ({ id, closeModal }) => {

  // const { start, setStart, end, setEnd, reportTransactionResponse } = useReportTransaction()
  const { swal } = useSwal()

  const formik = useFormik({
    validationSchema: changePasswordScheme,
    initialValues: changePasswordInitialValue,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {

      if(values.password.length<6){
        toast.error('Password baru minimal 6 huruf!')
        return
      }

      if(values.password!==values.password_confirmation){
        toast.error('Konfirmasi password tidak sama!')
        return
      }

      setSubmitting(true)
      try {
        const res = await changePassword(values)
        if (res?.data?.response?.code === RESP.SUCCESS) {
          closeModal()
          toast.success(res?.data?.response?.message_en)
          return
        }
  
        toast.error(res?.data?.response?.message_id)
      } catch (error) {
        if (isAxiosError(error)) {
          toast.error(error?.response?.data?.response?.message_id)
          return
        }
  
        toast.error('Server error. Please try again later')
      } finally {
        setSubmitting(false)
      }
    },
  })

  const {
    handleChange,
    handleBlur,
    values,
    touched,
    isValid,
    errors,
    handleSubmit,
    setValues,
    setFieldValue,
    isSubmitting,
    setSubmitting,
  } = formik

  return (
    <ModalProvider>
      <FormikProvider value={formik}>
        <form className='form d-flex flex-column flex-row' onSubmit={handleSubmit}>
          <div className='row'>
            <div className='col-12 mb-5'>
              <InputGroup
                label='Old Password'
                type='password'
                placeholder='Old Password'
                isValid={Boolean(values.old_password)}
                errorMessage={errors?.old_password}
                value={values.old_password}
                onChange={handleChange('old_password')}
                onBlur={handleBlur('old_password')}
              // icon='/media/svg/orders/user.svg'
              />
            </div>
            <div className='col-12 mb-5'>
              <InputGroup
                label='New Password'
                type='password'
                placeholder='New Password'
                isValid={Boolean(values.password)}
                errorMessage={errors?.password}
                value={values.password}
                onChange={handleChange('password')}
                onBlur={handleBlur('password')}
              // icon='/media/svg/orders/user.svg'
              />
            </div>
            <div className='col-12 mb-5'>
              <InputGroup
                label='Confirm New Password'
                type='password'
                placeholder='Confirm New Password'
                isValid={Boolean(values.password_confirmation)}
                errorMessage={errors?.password_confirmation
                }
                value={values.password_confirmation}
                onChange={handleChange('password_confirmation')}
                onBlur={handleBlur('password_confirmation')}
              // icon='/media/svg/orders/user.svg'
              />
            </div>
            <div className='col-12'>
              <div className='row'>
                <div className='col-4' />
                <div className='col-8'>
                  <div className='d-flex justify-content-end gap-3'>
                    <button className='btn btn-primary' type='submit' disabled={isSubmitting}>
                      {isSubmitting ? (
                        <Spinner animation='border' color='white' size='sm' />
                      ) : (
                        'Change Password'
                      )}</button>
                    <button className='btn btn-secondary' type='button' onClick={closeModal}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormikProvider>
    </ModalProvider>
  )
}

export default ChangePasswordForm
