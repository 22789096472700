import {toAbsoluteUrl} from '@/_metronic/helpers'
import clsx from 'clsx'
import {FC, useState} from 'react'
import SVG from 'react-inlinesvg'
import GuestForm from './GuestForm'
import WalkInForm from './WalkInForm'
import {useParams} from 'react-router-dom'
import {useBookOrder} from '../../book-order/core/BookOrderProvider'

type Props = {
  outlet_id: string
  handleSubmit: (value: any) => void
}
const BookingForm: FC<Props> = ({outlet_id, handleSubmit}) => {
  const [bookingType, setBookingType] = useState('guest')
  const {outlet} = useBookOrder()

  return (
    <>
      <div className='row mt-2'>
        <div className='col-12 mb-8'>
          <h2>{outlet?.outlet_name}</h2>
        </div>
        <div className='col-lg-6'>
          <div
            className='position-relative overflow-hidden cursor-pointer'
            onClick={() => Boolean(outlet_id) && setBookingType('walkin')}
          >
            <label
              className={clsx(
                'btn btn-outline btn-outline btn-outline-default p-4 d-flex align-items-center justify-content-center mb-10',
                {
                  'border-info text-info': bookingType === 'walkin',
                  bi: bookingType !== 'walkin',
                }
              )}
              htmlFor='kt_create_account_form_account_type_personal'
            >
              <SVG
                className='fs-3x me-3'
                src={toAbsoluteUrl(
                  bookingType === 'walkin'
                    ? '/media/svg/orders/ri_hotel_enable.svg'
                    : '/media/svg/orders/ri_hotel_disable.svg'
                )}
              />

              <span className='d-block fw-bold text-start'>
                <span className='d-block fs-4'>Walk In</span>
              </span>
            </label>
          </div>
        </div>

        <div className='col-lg-6'>
          <div
            className='position-relative overflow-hidden cursor-pointer'
            onClick={() => Boolean(outlet_id) && setBookingType('guest')}
          >
            <label
              className={clsx(
                'btn btn-outline btn-outline btn-outline-default p-4 d-flex align-items-center justify-content-center mb-10',
                {
                  'border-info text-info': bookingType === 'guest',
                  bi: bookingType !== 'guest',
                }
              )}
              htmlFor='kt_create_account_form_account_type_personal'
            >
              <SVG
                className='fs-3x me-3'
                src={toAbsoluteUrl(
                  bookingType === 'guest'
                    ? '/media/svg/orders/unlock_enable.svg'
                    : '/media/svg/orders/unlock_disable.svg'
                )}
              />

              <span className='d-block fw-bold text-start'>
                <span className='d-block fs-4'>Tamu Hotel</span>
              </span>
            </label>
          </div>
        </div>
      </div>
      {bookingType === 'guest' && (
        <GuestForm key='guest' outlet_id={outlet_id} handleSubmit={handleSubmit} />
      )}
      {bookingType === 'walkin' && (
        <WalkInForm key='walkin' outlet_id={outlet_id} handleSubmit={handleSubmit} />
      )}
    </>
  )
}

export default BookingForm
