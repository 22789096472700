import {BaseResponse} from '@/_metronic/helpers/crud-helper/base-list-response'

export interface FilterReportRevenueSharing {
  set_sub_brand_id?: boolean
  sub_brand_id?: string[]
  set_brand_id?: boolean
  brand_id?: string[]
  set_area?: boolean
  area?: string[]
  set_region_id?: boolean
  region_id?: string[]
  set_city_id?: boolean
  city_id?: string[]
  set_outlet_id?: boolean
  outlet_id?: string[]
  set_month?: boolean
  month?: string[]
  set_year?: boolean
  year?: string | null
}

export interface ReportRevenueSharing {
  card?: Card
  card_share?: CardShare
  revenue_sharing?: CardShare[]
  revenue_sharing_wib?: CardShare[]
  revenue_sharing_wita?: CardShare[]
  revenue_sharing_wit?: CardShare[]
  revenue_top_outlet?: RevenueTopOutlet[]
  revenue_top_city?: RevenueTopCity[]
}

export interface Card {
  revenue?: number
  tax?: number
  service?: number
  revenue_after_tax_service?: number
  grandtotal?: number
}

export interface CardShare {
  revenue_share_outlet?: number
  revenue_share_partner?: number
  service_share_outlet?: number
  service_share_partner?: number
  periode?: string
}

export interface RevenueTopCity {
  city_name?: string
  outlet_area?: string
  revenue_after_tax_service?: number
  revenue_share_outlet?: number
  revenue_share_partner?: number
  service_share_outlet?: number
  service_share_partner?: number
}

export interface RevenueTopOutlet {
  outlet_guid?: string
  outlet_name?: string
  outlet_area?: string
  region_name?: string
  revenue_after_tax_service?: number
  revenue_share_outlet?: number
  revenue_share_partner?: number
  service_share_outlet?: number
  service_share_partner?: number
}

export type ReportRevenueSharingQueryResponse = BaseResponse<ReportRevenueSharing>

const currentDate = new Date()
export const initialFilter: FilterReportRevenueSharing = {
  set_sub_brand_id: false,
  sub_brand_id: [],
  set_brand_id: false,
  brand_id: [],
  set_area: false,
  area: [],
  set_region_id: false,
  region_id: [],
  set_city_id: false,
  city_id: [],
  set_outlet_id: false,
  outlet_id: [],
  set_year: true,
  year: currentDate.getFullYear().toString(),
  set_month: false,
  month: [],
}
