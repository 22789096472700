import {AxiosResponse} from 'axios'
import {axios} from '@/_metronic/helpers/network/Axios'
import {BaseRequest} from '@/_metronic/helpers/crud-helper/base-request'
import { FilterJob, JobResponse } from './_models'

const HR_API_URL = '/hr-services'
const GET_JOB_LIST = '/job/list'

const getJobDropdown = (
  body: BaseRequest<FilterJob>
): Promise<JobResponse> => {
  return axios
    .post(`${HR_API_URL}${GET_JOB_LIST}`, body)
    .then((d: AxiosResponse<JobResponse>) => d.data)
}

export {getJobDropdown}