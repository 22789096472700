import {axios} from '@/_metronic/helpers/network/Axios'
import {AxiosResponse} from 'axios'
import {
  CheckoutBookingResponse,
  DetailBookingResponse,
  EmployeeWithAvailabilityResponse,
  FilterEmployeeWithAvailability,
  OutletWithStatusResponse,
  UpdateBookingResponse,
} from './_models'
import {BaseRequest} from '@/_metronic/helpers/crud-helper/base-request'

const BOOKING_URL = '/booking-services'
const BOOKING_ID_URL = `${BOOKING_URL}/booking`
const BOOKING_CHECKOUT_URL = `${BOOKING_URL}/booking/checkout`
const GET_EMPLOYEE = `${BOOKING_URL}/booking/employee-list`

export const getBookingDetail = (id: string): Promise<DetailBookingResponse> => {
  return axios
    .get<DetailBookingResponse>(`${BOOKING_ID_URL}/${id}`)
    .then((d: AxiosResponse<DetailBookingResponse>) => d.data)
}

export const updateBooking = (id: string, body: any): Promise<UpdateBookingResponse> => {
  return axios
    .put<UpdateBookingResponse>(`${BOOKING_ID_URL}/${id}`, body)
    .then((d: AxiosResponse<UpdateBookingResponse>) => d.data)
}

export const assignTherapist = (id: string, body: any): Promise<UpdateBookingResponse> => {
  return axios
    .put<UpdateBookingResponse>(`${BOOKING_ID_URL}/${id}/assigned`, body)
    .then((d: AxiosResponse<UpdateBookingResponse>) => d.data)
}

export const updateTherapist = (id: string, body: any): Promise<UpdateBookingResponse> => {
  return axios
    .put<UpdateBookingResponse>(`${BOOKING_ID_URL}/${id}/assigned/update`, body)
    .then((d: AxiosResponse<UpdateBookingResponse>) => d.data)
}

export const addTreatment = (id: string, body: any): Promise<UpdateBookingResponse> => {
  return axios
    .post<UpdateBookingResponse>(`${BOOKING_ID_URL}/${id}/product`, body)
    .then((d: AxiosResponse<UpdateBookingResponse>) => d.data)
}

export const deleteTreatment = (id: string, body: any): Promise<UpdateBookingResponse> => {
  return axios
    .delete<UpdateBookingResponse>(`${BOOKING_ID_URL}/${id}/product`, {data: body})
    .then((d: AxiosResponse<UpdateBookingResponse>) => d.data)
}

export const getOutletWithStatus = (body: any): Promise<OutletWithStatusResponse> => {
  return axios
    .post<OutletWithStatusResponse>(`${BOOKING_ID_URL}/list/outlet-status`, body)
    .then((d) => d.data)
}

export const cancelBooking = (id: string): Promise<UpdateBookingResponse> => {
  return axios
    .put<UpdateBookingResponse>(`${BOOKING_ID_URL}/${id}/cancel`)
    .then((d: AxiosResponse<UpdateBookingResponse>) => d.data)
}

export const getEmployeeWithAvailability = (
  body: FilterEmployeeWithAvailability
): Promise<EmployeeWithAvailabilityResponse> => {
  return axios
    .post(`${GET_EMPLOYEE}`, body)
    .then((d: AxiosResponse<EmployeeWithAvailabilityResponse>) => d.data)
}

export const checkoutBooking = (body: any): Promise<CheckoutBookingResponse> => {
  return axios
    .post<CheckoutBookingResponse>(`${BOOKING_CHECKOUT_URL}`, body)
    .then((d: AxiosResponse<CheckoutBookingResponse>) => d.data)
}
