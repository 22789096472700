import { WithChildren } from '@/_metronic/helpers'
import { createContext, FC, useContext, useState } from 'react'

type FormStateProps = {
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
}

const initialValues: FormStateProps = {isLoading: false, setIsLoading: () => {}}

const FormStateContext = createContext<FormStateProps>(initialValues)

const FormStateProvider: FC<WithChildren> = ({children}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  return (
    <FormStateContext.Provider value={{isLoading, setIsLoading}}>
      {children}
    </FormStateContext.Provider>
  )
}

const useBookingFormState = () => useContext(FormStateContext)

export { FormStateProvider, useBookingFormState }
