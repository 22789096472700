import {toAbsoluteUrl} from '@/_metronic/helpers/AssetHelpers'
import {RESP} from '@/_metronic/helpers/network/network-consts'
import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {BookingOrderDetail} from '../book-order/core/_models'
import {getBookingDetail} from '../book-order/core/_requests'
import {Rating as RatingInput} from '@smastrom/react-rating'
import {Modal} from 'react-bootstrap'
import {createRating} from './core/_requests'
import {isAxiosError} from 'axios'
import {toast} from 'react-toastify'
import {useSwal} from '@/app/core/SwalProvider'

const initialRatingFormValue = {
  rating: 5,
  feedback: '',
}

const Rating = () => {
  const {id} = useParams()
  const [bookingDetail, setBookingDetail] = useState<BookingOrderDetail | undefined>()
  const [form, setForm] = useState<any>(initialRatingFormValue)
  const [showSuccessRate, setShowSuccessRate] = useState<boolean>(false)
  const {swal} = useSwal()

  const fetchBookingDetail = async () => {
    try {
      const res = await getBookingDetail(id || '')
      if (res?.response?.code === RESP.SUCCESS) {
        if (res?.response?.data?.rating?.guid) {
          swal
            ?.fire({
              html: 'Order sudah diberi rating',
              icon: 'warning',
              buttonsStyling: false,
              // confirmButtonText: 'Tutup',
              // cancelButtonText: 'Tidak, batal',
              showConfirmButton: false,
              showCancelButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              // customClass: {
              //   confirmButton: 'btn fw-bold btn-danger',
              //   cancelButton: 'btn fw-bold btn-active-light-primary',
              // },
            })
            .then(async (value) => {
              if (value.isConfirmed) {
                setTimeout(() => {
                  window.close()
                }, 1000)
              }
            })
        }
        setBookingDetail(res?.response?.data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchBookingDetail()
  }, [id])

  useEffect(() => {
    if (!showSuccessRate) {
      setForm(initialRatingFormValue)
    }
  }, [showSuccessRate])

  const handleCreateRating = () => {
    const body = {
      book_order_guid: id,
      rating: form.rating,
      comment: form.feedback,
    }

    createRating(body)
      .then((res) => {
        if (res?.response?.code === RESP.SUCCESS) {
          setShowSuccessRate(true)
          setForm(initialRatingFormValue)
          return
        }

        toast.error(res?.response?.message_id || '')
      })
      .catch((err) => {
        if (isAxiosError(err)) {
          toast.error(err?.response?.data?.response?.message_id || '')
        }
      })
  }

  return (
    <div className='container'>
      <div className='row justify-content-center my-5 gap-5'>
        <div className='col-12 d-flex justify-content-center'>
          <img src={toAbsoluteUrl('/media/logos/logo-alaya.png')} className='w-200px img-fluid' />
        </div>
        <div className='col-12 col-md-6'>
          <div className='card py-4 px-5'>
            <div className='text-center'>
              <h1 className='fw-bolder mt-4'>Rating Order</h1>
              <p className='text-gray-600'>
                Anda akan melakukan rating order{' '}
                <b className='text-gray-800'>{bookingDetail?.book_order_number}</b>
              </p>
            </div>
            {/* {Boolean(id) && <BookingForm outlet_id={id || ''} handleSubmit={handleCreateOrder} />} */}
            <div className='row mt-10 pb-10'>
              <div className='col-12 d-flex flex-row justify-content-center'>
                <RatingInput
                  style={{maxWidth: 250}}
                  value={form.rating}
                  onChange={(value) => {
                    setForm({...form, rating: value})
                  }}
                />
              </div>
              <div className='col-12 mt-10'>
                <label htmlFor='feedback' className='form-label'>
                  Feedback <i className='text-muted'>(Optional)</i>
                </label>
                <textarea
                  value={form.feedback}
                  id='feedback'
                  className='form-control form-control-solid'
                  onChange={(e) => setForm({...form, feedback: e.target.value})}
                />
              </div>
              <div className='col-12 mt-10'>
                <button className='btn btn-sm btn-primary w-100' onClick={handleCreateRating}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal className='modal fade' onHide={() => setShowSuccessRate(false)} show={showSuccessRate}>
        <div className='modal-content'>
          <div className='modal-body'>
            <div className='row text-center'>
              <div className='col-12'>
                <div className='d-flex justify-content-center p-7'>
                  <i className='bi bi-check-circle-fill text-success' style={{fontSize: 96}}></i>
                </div>
              </div>
              <div className='col-12'>
                <h3>Rating Terkirim</h3>
              </div>
              <div className='col-12'>
                <p>
                  Terima kasih sudah memberikan rating untuk order{' '}
                  <b>{bookingDetail?.book_order_number}</b>.
                </p>
              </div>

              <div className='col-12'>
                <button
                  className='btn btn-primary btn-sm mt-4 w-75'
                  type='button'
                  onClick={() => setShowSuccessRate(false)}
                >
                  Tutup
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

const RatingWrapper = () => {
  return (
    <>
      <Rating />
    </>
  )
}

export default RatingWrapper
