import {FC} from 'react'
import {Modal} from 'react-bootstrap'
import {useSelectTreatmentModal} from '../core/_ModalProvider'

type Props = {
  onReserve: () => void
}

const BookingSuccessModal: FC<Props> = ({onReserve}) => {
  const {showSuccess, closeSuccessModal} = useSelectTreatmentModal()

  return (
    <Modal className='modal fade' onHide={closeSuccessModal} show={showSuccess}>
      <div className='modal-content'>
        <div className='modal-body'>
          <div className='row text-center'>
            <div className='col-12'>
              <div className='d-flex justify-content-center p-7'>
                <i className='bi bi-check-circle-fill text-success' style={{fontSize: 96}}></i>
              </div>
            </div>
            <div className='col-12'>
              <h3>Reservasi Order Berhasil Terkirim</h3>
            </div>
            <div className='col-12'>
              <p>
                Terima kasih sudah melakukan Reservasi secara online, kami akan melakukan konfirmasi
                kembali kepada anda melalui Telepon.
              </p>
            </div>
            <div className='col-12'>
              <b>Untuk melakukan reservasi lagi, silahkan klik tombol dibawah ini</b>
            </div>
            <div className='col-12'>
              <button
                className='btn btn-primary btn-sm mt-4 w-75'
                type='button'
                onClick={onReserve}
              >
                Reservasi
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export {BookingSuccessModal}
