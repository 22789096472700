import {BaseResponse} from '@/_metronic/helpers/crud-helper/base-list-response'
import {axios as customAxios} from '@/_metronic/helpers/network/Axios'
import axios from 'axios'
import {axios as axios2} from '@/_metronic/helpers/network/Axios'
import {
  AuthModel,
  GenerateTokenModel,
  GenerateTokenRequest,
  ProfileModel,
  UserModel,
} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const AUTH_URL = '/authorization'
export const GENERATE_TOKEN = `${AUTH_URL}/token/auth`
export const LOGIN_URL = `${AUTH_URL}/authentication/login`
export const LOGOUT_URL = `${AUTH_URL}/authentication/logout`
export const PROFILE_URL = `${AUTH_URL}/authentication/profile`
export const IAM_USER_URL = `${AUTH_URL}/authentication/iam-access`
export const RESET_PASSWORD_URL = `${AUTH_URL}/authentication/forgot-password-submit`
export const CHANGE_PASSWORD_URL = `${AUTH_URL}/authentication/change-password`

export function generateToken(body: GenerateTokenRequest) {
  return customAxios.post<BaseResponse<GenerateTokenModel>>(GENERATE_TOKEN, body)
}

// Server should return AuthModel
export function login(username: string, password: string) {
  return customAxios.post<BaseResponse<AuthModel>>(LOGIN_URL, {
    username,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

export function doLogout() {
  return customAxios.post(LOGOUT_URL)
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return customAxios.get<BaseResponse<ProfileModel>>(PROFILE_URL, {
    headers: {
      token,
    },
  })
}

export function getIamUserAccess() {
  return customAxios.get(IAM_USER_URL)
}

export function resetPasswordSubmit(token, body) {
  return customAxios.post(`${RESET_PASSWORD_URL}/${token}`, body)
}

export function changePassword(body) {
  return axios2.post(`${CHANGE_PASSWORD_URL}`, body)
}
