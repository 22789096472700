import { KTIcon } from '@/_metronic/helpers'
import { FC, useState } from 'react'
import { useFilterReportTrendAnalysis } from '../../core/FilterReportTrendAnalysisProvider'

type Props = {
  closeModal: () => void
}

const FilterReportTrendAnalysisModalHeader: FC<Props> = ({closeModal}) => {
  const {updateFilterReportTrendAnalysisFormValue, filterReportTrendAnalysisFormValue} =
    useFilterReportTrendAnalysis()
  const [filterValueTemp, setFilterValueTemp] = useState(filterReportTrendAnalysisFormValue)

  const cancelModal = () => {
    updateFilterReportTrendAnalysisFormValue(filterValueTemp)
    closeModal()
  }

  return (
    <div className='modal-header'>
      <h2 className='fw-bolder'>Advanced Filter</h2>

      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={cancelModal}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>
    </div>
  )
}

export { FilterReportTrendAnalysisModalHeader }

