import {DropdownIconSelect} from '@/_metronic/partials/content/dropdown-select/DropdownIconSelect'
import {FC, FocusEventHandler} from 'react'

type Props = {
  currentValue: any
  onChange: (newValue: any) => void
  onBlur?: FocusEventHandler<HTMLInputElement>
  isDisabled?: boolean
}

const options = [
  {label: 'Laki-Laki', value: 'Laki-Laki'},
  {label: 'Perempuan', value: 'Perempuan'},
]

const DropdownGender: FC<Props> = ({currentValue, onChange, onBlur, isDisabled}) => {
  return (
    <>
      <DropdownIconSelect
        // styles={{
        //   control: (provided) => ({
        //     ...provided,
        //     border: 'none',
        //   }),
        // }}
        // components={{
        //   IndicatorSeparator: null,
        //   DropdownIndicator: null,
        //   Control: (props) => (
        //     <components.Control
        //       {...props}
        //       className={clsx(props.className, 'form-select form-select-solid py-0 px-1 border-0')}
        //     />
        //   ),
        //   Placeholder: (props) => (
        //     <components.Placeholder {...props} className={clsx(props.className)} />
        //   ),
        //   SelectContainer: (props) => (
        //     <components.SelectContainer {...props} className={clsx(props.className, 'border-0')} />
        //   ),
        //   Input: (props) => <components.Input {...props} />,
        // }}
        defaultValue={currentValue}
        options={options}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={<span className='custom-dropdown-placeholder fw-semibold'>Jenis Kelamin</span>}
        value={currentValue}
        isClearable={false}
        isDisabled={isDisabled}
        icon='/media/svg/orders/user.svg'
      />
    </>
  )
}

export {DropdownGender}
