import { BarChart } from '@/_metronic/partials/content/chart/BarChart'
import { useEffect, useState } from 'react'
import { AchievementProgress } from './components/AchievementProgress'
import { useQueryResponseDaily, useQueryResponseDailyData, useQueryResponseDailyLoading } from './core/QueryResponseDailyProvider'
import Maps from '../maps/Maps'
import { FormattedNumber, IntlProvider } from 'react-intl'

const ForecastOverviewDaily = () => {
  const data = useQueryResponseDailyData()
  const response = useQueryResponseDaily()
  const isLoading = useQueryResponseDailyLoading()
  const [arrayOfSeriesRecentStatisticsSales, setArrayOfSeriesRecentStatisticsSales] = useState<
    number[]
  >([])
  const [arrayOfSeriesRecentStatisticsSalesTarget, setArrayOfSeriesRecentStatisticsSalesTarget] =
    useState<number[]>([])
  const [arrayOfLabelsRecentStatistics, setArrayOfLabelsRecentStatistics] = useState<string[]>([])
  const [arrayOfSeriesRecentStatisticsTreatment, setArrayOfSeriesRecentStatisticsTreatment] =
    useState<number[]>([])
  const [
    arrayOfSeriesRecentStatisticsTreatmentTarget,
    setArrayOfSeriesRecentStatisticsTreatmentTarget,
  ] = useState<number[]>([])

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.')
  }

  function numberFormatWithCommas(x) {
    return Intl.NumberFormat('in-ID', {
      style: 'currency',
      currency: 'IDR',
      maximumFractionDigits: 2,
    })
      .format(parseFloat(x || '0'))
      .replace('Rp', '')
      .trim()
  }

  useEffect(() => {
    if (data) {
      setArrayOfSeriesRecentStatisticsSales([])
      setArrayOfSeriesRecentStatisticsSalesTarget([])
      setArrayOfLabelsRecentStatistics([])
      setArrayOfSeriesRecentStatisticsTreatment([])
      setArrayOfSeriesRecentStatisticsTreatmentTarget([])
      if (data?.monthly_sales_vs_budget) {
        const arrayOfSeriesSalesString = data?.daily_sales_vs_budget?.map((obj) =>
          obj.actual_sales ? obj.actual_sales : 0
        )
        setArrayOfSeriesRecentStatisticsSales(arrayOfSeriesSalesString ?? [])
        const arrayOfSeriesSalesTargetString = data?.daily_sales_vs_budget?.map((obj) =>
          obj.sales_target ? obj.sales_target : 0
        )
        setArrayOfSeriesRecentStatisticsSalesTarget(arrayOfSeriesSalesTargetString ?? [])
        const arrayOfSeriesTreatmetnString = data?.daily_sales_vs_budget?.map((obj) =>
          obj.actual_treatment ? obj.actual_treatment : 0
        )
        setArrayOfSeriesRecentStatisticsTreatment(arrayOfSeriesTreatmetnString ?? [])
        const arrayOfSeriesTreatmetnTargetString = data?.daily_sales_vs_budget?.map((obj) =>
          obj.treatment_target ? obj.treatment_target : 0
        )
        setArrayOfSeriesRecentStatisticsTreatmentTarget(arrayOfSeriesTreatmetnTargetString ?? [])

        const arrayOfLabelsString = data?.daily_sales_vs_budget?.map((obj, index) => {
          return obj.day ? obj?.day.toString() : ''
        })
        setArrayOfLabelsRecentStatistics(arrayOfLabelsString ?? [])
      }
    }
  }, [data])

  return (
    <>
      <div className='col-xl-12 mt-10'>
        <BarChart
          className='shadow-wit'
          color='primary'
          title='Actual vs Target (Revenue)'
          subtitle=''
          height='260px'
          series={[
            {
              name: 'Actual',
              data: data?.daily_sales_vs_budget?.map((obj) =>
                obj.actual_sales ? obj.actual_sales : 0
              ),
            },
            {
              name: 'Target',
              data: data?.daily_sales_vs_budget?.map((obj) =>
                obj.sales_target ? obj.sales_target : 0
              ),
            },
          ]}
          categories={arrayOfLabelsRecentStatistics}
          total={0}
          isTotal={false}
          data={data?.daily_sales_vs_budget?.map((x) => ({
            month: `${x.day} ${x.month_value} ${(data?.monthly_sales_vs_budget ?? []).filter((item) => x.month !== item.month)
                .length > 0
                ? data?.monthly_sales_vs_budget![0]?.year
                : ''
              }`,
            actual_sales: x.actual_sales,
            sales_target: x.sales_target,
          }))}
          headers={['Bulan', 'Revenue', 'Revenue Target']}
          formatCurrency={['actual_sales', 'sales_target']}
          isLoading={isLoading || (response.response?.response as any) === 'no result data'}
        />
      </div>

      <div className='col-xl-12 mt-10 mb-3'>
        <BarChart
          className='shadow-wit'
          color='success'
          title='Actual vs Target (Treatment)'
          subtitle=''
          height='260px'
          series={[
            {
              name: 'Actual',
              data: data?.daily_sales_vs_budget?.map((obj) =>
                obj.actual_treatment ? obj.actual_treatment : 0
              ),
            },
            {
              name: 'Target',
              data: data?.daily_sales_vs_budget?.map((obj) =>
                obj.treatment_target ? obj.treatment_target : 0
              ),
            },
          ]}
          categories={arrayOfLabelsRecentStatistics}
          total={0}
          isCurrency={false}
          isTotal={false}
          data={data?.daily_sales_vs_budget?.map((x) => ({
            month: `${x.day} ${x.month_value} ${(data?.monthly_sales_vs_budget ?? []).filter((item) => x.month !== item.month)
                .length > 0
                ? data?.monthly_sales_vs_budget![0]?.year
                : ''
              }`,
            actual_treatment: x.actual_treatment,
            treatment_target: (
              <IntlProvider locale='id'>
                <FormattedNumber value={x.treatment_target ?? 0} maximumFractionDigits={2} />
              </IntlProvider>
            ),
          }))}
          headers={['Bulan', 'Treatment', 'Treatment Target']}
          isLoading={isLoading || (response.response?.response as any) === 'no result data'}
        />
      </div>
    </>
  )
}

export default ForecastOverviewDaily
