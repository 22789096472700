import { axios } from '@/_metronic/helpers/network/Axios'
import { AxiosResponse } from 'axios'
import { FilterReportRushHour, ReportRushHourQueryResponse } from './_models'

const REPORT_API_URL = '/report-services'
const GET_REPORT_RUSH_HOUR_LIST = '/dashboard/rush-hours'

const getReportRushHour = (
  body: FilterReportRushHour
): Promise<ReportRushHourQueryResponse> => {
  return axios
    .post(`${REPORT_API_URL}${GET_REPORT_RUSH_HOUR_LIST}`, body)
    .then((d: AxiosResponse<ReportRushHourQueryResponse>) => d.data)
}

export { getReportRushHour }

