import {RESP} from '@/_metronic/helpers/network/network-consts'
import {FC, useEffect, useState} from 'react'
import SVG from 'react-inlinesvg'
import {Link, useParams} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Outlet, initialFilterOutlet} from '../outlet/core/_models'
import {getOutletById, getOutlets} from '../outlet/core/_requests'
import BookingForm from './components/BookingForm'
import {FormStateProvider, useBookingFormState} from './core/FormStateProvider'
import {ModalProvider, useSelectTreatmentModal} from './core/_ModalProvider'
import {createOrder} from './core/_requests'
import {DropdownOutletOrder} from './components/DropdownOutletOrder'

const OrderSelectOutletPage: FC = () => {
  const [outlet, setOutlet] = useState<any | undefined>(undefined)
  const {showSuccessModal} = useSelectTreatmentModal()
  const {setIsLoading} = useBookingFormState()

  const loadOutlet = async (q: string, page: number) => {
    try {
      const body = {
        ...initialFilterOutlet,
        filter: {
          ...initialFilterOutlet.filter,
          set_name: Boolean(q),
          name: q,
        },
        page,
        limit: 10,
      }
      const res = await getOutlets(body)
      if (res?.response?.code === RESP.SUCCESS) {
        const options =
          res?.response?.data?.map((outlet) => ({
            label: outlet?.name,
            value: outlet?.guid,
          })) || []

        return {
          options,
          hasMore: page < (res?.response?.total_page || 1),
          additional: {
            page: page + 1,
          },
        }
      }

      return {
        options: [],
        hasMore: false,
      }
    } catch (error) {
      return {
        options: [],
        hasMore: false,
      }
    }
  }

  const loadOptionsOutlet: any = async (q, _, additional) => {
    const {page} = additional!
    const {options: responseOptions, hasMore} = await loadOutlet(q, page)

    return {
      options: responseOptions,
      hasMore,
      additional: {
        page: page + 1,
      },
    }
  }

  const handleCreateOrder = async (body: any) => {
    try {
      const res = await createOrder(body)
      if (res?.response?.code === RESP.SUCCESS) {
        showSuccessModal()
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <div className='container z-10'>
        <div className='row justify-content-center my-5 gap-5'>
        <div className="col-12 col-md-8 col-lg-6">
            <div className="row">
              <div className="col">
                <img src={toAbsoluteUrl('/media/logos/logo-annathaya-black-pink.png')} className='img-fluid' />
              </div>
              <div className="col">
                <img src={toAbsoluteUrl('/media/logos/logo-square-fitness.png')} className='img-fluid' />
              </div>
              <div className="col">
                <img src={toAbsoluteUrl('/media/logos/logo-alaya-new.png')} className='img-fluid' />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-8 col-lg-6'>
            <div className='card py-4 px-5 shadow-wit-2 bg-illustration-order'>
              <div className='text-center'>
                <h1 className='fw-bolder mt-4'>Booking Reservation</h1>
                <p className='text-gray-600 mt-8'>Pilih Outlet</p>
                <DropdownOutletOrder
                  loadOptions={loadOptionsOutlet}
                  value={outlet}
                  placeholder='Pilih Outlet'
                  onChange={(newValue) => {
                    setOutlet(newValue)
                  }}
                />
              </div>
              <BookingForm outlet_id={outlet?.value || ''} handleSubmit={handleCreateOrder} />
              <Link to='/privacy-policy' className='w-100 text-center text-info mt-3 fs-5 fw-bold'>
                Privacy Policy
              </Link>
              <img
                src={toAbsoluteUrl('/media/illustrations/Ilustration_order.png')}
                className='w-200px img-fluid position-absolute backdrop-order bottom-0 z-9'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const OrderSelectOutletWrapper: FC = () => {
  return (
    <>
      <FormStateProvider>
        <ModalProvider>
          <OrderSelectOutletPage />
        </ModalProvider>
      </FormStateProvider>
    </>
  )
}

export default OrderSelectOutletWrapper
