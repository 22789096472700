import {PartnersListToolbar} from './PartnersListToolbar'
import {PartnersListGrouping} from './PartnersListGrouping'
import {PartnersListSearchComponent} from './PartnersListSearchComponent'
import { useListView } from '../../../core/ListViewProvider'

const PartnersListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <PartnersListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <PartnersListGrouping /> : <PartnersListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {PartnersListHeader}
