/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

type Props = {
  className: string
  color: string
  title: string
  titleColor?: string
  subtitle?: string
  description: string
  descriptionColor?: string
}

const AverageCard: React.FC<Props> = ({
  className,
  color,
  title,
  titleColor,
  subtitle,
  description,
  descriptionColor,
}) => {
  return (
    <a href='#' className={`card bg-${color} hoverable ${className}`}>
      <div className='card-body d-flex flex-column '>
        <div className="flex-fill">
          <h3 className={`text-${titleColor} fw-bold fs-2`}>{title}</h3>
          <h5 className='text-gray-400'>{subtitle}</h5>
        </div>

        <div>
          <h3 className={`fw-semibold fs-2 text-${descriptionColor}`}>{description}</h3>
        </div>
      </div>
    </a>
  )
}

export {AverageCard}
