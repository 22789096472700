import { KTCard } from '@/_metronic/helpers/components/KTCard'
import { FC, useState } from 'react'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { Detail } from './Detail'
import Overview from './Overview'
import { FilterReportRevenueSharingModal } from './components/filter/FilterReportRevenueSharingModal'
import { ReportRevenueSharingListHeader } from './components/filter/ReportRevenueSharingListHeader'
import {
  FilterReportRevenueSharingProvider,
  useFilterReportRevenueSharing,
} from './core/FilterReportRevenueSharingProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Report',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Sales',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const RevenueSharingPage = () => {
  const [activeTab, setActiveTab] = useState<string>('overview')
  const {closeModal, showFilterReportRevenueSharingModal} = useFilterReportRevenueSharing()

  return (
    <>
      <PageTitle breadcrumbs={[]}>Dashboard Revenue Sharing</PageTitle>
      {/* <div className="bg-white px-8">
        <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
          <li className="nav-item mt-2">
            <a onClick={() => setActiveTab("overview")} className={`cursor-pointer nav-link text-active-primary ms-0 me-10 py-5 ${activeTab === 'overview' ? "active" : ""}`}>
              Overview
            </a>
          </li>
          <li className="nav-item mt-2">
            <a onClick={() => setActiveTab("detail")} className={`cursor-pointer nav-link text-active-primary ms-0 me-10 py-5 ${activeTab === 'detail' ? "active" : ""}`}>
              Detail
            </a>
          </li>
        </ul>
      </div> */}

      <KTCard className='py-4 shadow-wit-2 mb-10'>
        <ReportRevenueSharingListHeader />
      </KTCard>

      {activeTab === 'overview' ? <Overview /> : <Detail />}
      <FilterReportRevenueSharingModal
        show={showFilterReportRevenueSharingModal}
        closeModal={() => closeModal()}
      />
    </>
  )
}

const RevenueSharingWrapper: FC = () => {
  return (
    <>
      <QueryRequestProvider>
        <QueryResponseProvider>
          <FilterReportRevenueSharingProvider>
            <RevenueSharingPage />
          </FilterReportRevenueSharingProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  )
}

export { RevenueSharingWrapper }

