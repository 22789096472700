import {PartnersListHeader} from './components/header/PartnersListHeader'
import {PartnersTable} from './table/PartnersTable'
import {KTCard} from '../../../../_metronic/helpers'
import { useListView, ListViewProvider } from '../core/ListViewProvider'
import { QueryRequestProvider } from '../core/QueryRequestProvider'
import { QueryResponseProvider } from '../core/QueryResponseProvider'

const PartnersList = () => {

  return (
    <>
      <KTCard>
        <PartnersListHeader />
        <PartnersTable />
      </KTCard>
    </>
  )
}

const PartnerListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <PartnersList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {PartnerListWrapper}
