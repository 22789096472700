import {FC, createContext, useContext, useState} from 'react'
import {WithChildren} from '../../../../_metronic/helpers'

type ModalContextModel = {
  show: boolean
  showSuccess: boolean
  closeModal: () => void
  showModal: () => void
  closeSuccessModal: () => void
  showSuccessModal: () => void
}

const ModalContext = createContext<ModalContextModel>({
  show: false,
  showSuccess: false,
  closeModal: () => {},
  showModal: () => {},
  closeSuccessModal: () => {},
  showSuccessModal: () => {},
})

const ModalProvider: FC<WithChildren> = ({children}) => {
  const [show, setShow] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  const showModal = () => {
    setShow(true)
  }

  const closeModal = () => {
    setShow(false)
  }

  const showSuccessModal = () => setShowSuccess(true)

  const closeSuccessModal = () => setShowSuccess(false)

  const value: ModalContextModel = {
    show,
    showSuccess,
    showModal,
    closeModal,
    showSuccessModal,
    closeSuccessModal,
  }

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
}

export {ModalContext, ModalProvider}

export function useSelectTreatmentModal() {
  return useContext(ModalContext)
}
