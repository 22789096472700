/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {MenuComponent} from '../../../assets/ts/components'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {HeaderToolbar} from './HeaderToolbar'
import {MenuInner} from './MenuInner'
import {Navbar} from './Navbar'
import { useAuth } from '@/app/modules/auth'
import { getStatusBilling } from '@/app/modules/billing-client/core/_requests'

export function HeaderWrapper() {
  const {config, classes, attributes} = useLayout()
  const {aside, header} = config
  const {currentUser} = useAuth()
  const location = useLocation()

  // TEMPORARY FOR MEETING REQUEST
  const [statusBilling, setStatusBilling] = useState<any>()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [location])

  // TEMPORARY FOR MEETING REQUEST
  useEffect(() => {
    const fetchBillingStatus = async () => {
      const data = await getStatusBilling()
      if (data) {
        setStatusBilling(data)
      }
    }

    fetchBillingStatus()
  }, [])

  if (header?.posPage) {
    return (
      <div
        id='kt_header'
        className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      >
        <div
          className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15'
          style={{
            paddingLeft: '25px',
          }}
        >
          <Link to='/dashboard'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/logo-alaya.png')}
              className='h-20px h-lg-30px app-sidebar-logo-default'
            />
          </Link>
        </div>
        <div
          id='kt_app_header_wrapper'
          className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'
          style={{
            paddingRight: '25px',
          }}
        >
          <div
            className='
              menu
              menu-rounded
              menu-column
              menu-lg-row
              my-5
              my-lg-0
              align-items-stretch
              fw-semibold
              px-2
              px-lg-0'
          >
            <MenuInner />
          </div>
          <Navbar />
        </div>
      </div>
    )
  }

  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      {...attributes.headerMenu}
    >
      {/* begin::Brand */}
      <div className='header-brand'>
        {/* begin::Logo */}
        <Link to='/'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/logo-alaya.png')}
            className='h-25px h-lg-25px'
            style={{filter: 'brightness(0) invert(1)'}}
          />
        </Link>
        {/* end::Logo */}

        {aside.minimize && (
          <div
            id='kt_aside_toggle'
            className='btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize'
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
          >
            <KTIcon iconName='exit-left' className='fs-1 me-n1 minimize-default' />
            <KTIcon iconName='entrance-left' className='fs-1 minimize-active' />
          </div>
        )}

        {/* begin::Aside toggle */}
        <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-30px h-30px'
            id='kt_aside_mobile_toggle'
          >
            <KTIcon iconName='abstract-14' className='fs-1' />
          </div>
        </div>
        {/* end::Aside toggle */}
      </div>
      {/* end::Brand */}
      
      <div className='w-100'>
        {/* TEMPORARY FOR MEETING REQUEST */}
        {
          statusBilling?.awaiting_payment > 0 &&
          (currentUser?.detail_data?.guid === "53b329de-dce6-41b9-9a68-b4eef99dc116" ||
          currentUser?.detail_data?.guid === "d0551492-caed-49dd-868b-25c65b2bb464" ||
          currentUser?.detail_data?.guid === "93f832c3-5f4e-415f-b1e6-7901109f39e4") ? (
            <div className='position-absolute start-0 py-2 bg-danger w-100 text-white text-center z-index-2 fs-5 fw-bolder'>
              Anda memiliki billing yang belum dibayar. <Link to="/report/sales/outlet/list?executive=1"><span className='ms-2 text-white text-decoration-underline cursor-pointer'>klik lebih detail</span></Link>
            </div>
          ) : ''
        }
        <HeaderToolbar />
      </div>
    </div>
  )
}
