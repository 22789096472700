import { BaseResponse } from '@/_metronic/helpers/crud-helper/base-list-response'
import { Response } from '../../../../_metronic/helpers'
import moment from 'moment'

export interface FilterReportRushHour {
  set_sub_brand_id?: boolean
  sub_brand_id?: string[]
  set_brand_id?: boolean
  brand_id?: string[]
  set_area?: boolean
  area?: string[]
  set_region_id?: boolean
  region_id?: string[]
  set_city_id?: boolean
  city_id?: string[]
  set_outlet_id?: boolean
  outlet_id?: string[]
  start_date?: string
  end_date?: string
}

export interface ReportRushHour {
  summary?: Summary
  bar_summary?: BarSummary[]
  rush_hour_summary?: RushHourSummary[]
}

export interface Summary {
  total_qtys?: number
  total_sales?: number
  total_transaction?: number
}

export interface BarSummary {
  times?: string
  total_qtys?: number
  total_sales?: number
  total_transaction?: number
}

export interface RushHourSummary {
  times?: string
  total_qtys?: number
  total_sales?: number
  total_transaction?: number
  percentage_total_qtys?: number
  percentage_total_sales?: number
  percentage_total_transaction?: number
}

export type ReportRushHourQueryResponse = BaseResponse<ReportRushHour>

const currentDate = new Date()
export const initialFilter: FilterReportRushHour = {
  set_sub_brand_id: false,
  sub_brand_id: [],
  set_brand_id: false,
  brand_id: [],
  set_area: false,
  area: [],
  set_region_id: false,
  region_id: [],
  set_city_id: false,
  city_id: [],
  set_outlet_id: false,
  outlet_id: [],
  start_date: moment().local().format('yyyy-MM-DD'),
  end_date: moment().local().format('yyyy-MM-DD'),
}

export type IRevenueList = {
  time: string
  qty: number
  qty_perc: number
  transaction: number
  transaction_perc: number
  sales: number
  sales_perc: number
}

export type UsersQueryResponse = Response<Array<IRevenueList>>

export const IRevenueListDummyState: IRevenueList[] = [
  {
    time: '00:00',
    qty: 0,
    qty_perc: 0,
    transaction: 0,
    transaction_perc: 0,
    sales: 0,
    sales_perc: 0,
  },
  {
    time: '01:00',
    qty: 0,
    qty_perc: 0,
    transaction: 0,
    transaction_perc: 0,
    sales: 0,
    sales_perc: 0,
  },
  {
    time: '02:00',
    qty: 0,
    qty_perc: 0,
    transaction: 0,
    transaction_perc: 0,
    sales: 0,
    sales_perc: 0,
  },
  {
    time: '03:00',
    qty: 0,
    qty_perc: 0,
    transaction: 0,
    transaction_perc: 0,
    sales: 0,
    sales_perc: 0,
  },
  {
    time: '04:00',
    qty: 0,
    qty_perc: 0,
    transaction: 0,
    transaction_perc: 0,
    sales: 0,
    sales_perc: 0,
  },
  {
    time: '05:00',
    qty: 0,
    qty_perc: 0,
    transaction: 0,
    transaction_perc: 0,
    sales: 0,
    sales_perc: 0,
  },
  {
    time: '06:00',
    qty: 3,
    qty_perc: 3,
    transaction: 3,
    transaction_perc: 3,
    sales: 300000,
    sales_perc: 3,
  },
  {
    time: '07:00',
    qty: 0,
    qty_perc: 0,
    transaction: 0,
    transaction_perc: 0,
    sales: 0,
    sales_perc: 0,
  },
  {
    time: '08:00',
    qty: 5,
    qty_perc: 5,
    transaction: 5,
    transaction_perc: 5,
    sales: 500000,
    sales_perc: 5,
  },
  {
    time: '09:00',
    qty: 5,
    qty_perc: 5,
    transaction: 5,
    transaction_perc: 5,
    sales: 500000,
    sales_perc: 5,
  },
]
