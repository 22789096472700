import { Column } from 'react-table'
import { PartnerOrganization } from '../../../core/_models'
import { IndexCell } from './IndexCell'
import { PartnerActionsCell } from './PartnerActionsCell'
import { PartnerCustomHeader } from './PartnerCustomHeader'
import { PartnerInfoCell } from './PartnerInfoCell'
import { PartnerPersonInChargeCell } from './PartnerPersonInChargeCell'

const partnerOrganizerColumns: ReadonlyArray<Column<PartnerOrganization>> = [
  {
    Header: (props) => <PartnerCustomHeader tableProps={props} title='No' className='min-w-50px' />,
    id: 'no',
    Cell: (props) => <IndexCell index={props.row.index + 1} />,
  },
  {
    Header: (props) => (
      <PartnerCustomHeader tableProps={props} title='Name' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => <PartnerInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <PartnerCustomHeader tableProps={props} title='Organization' className='min-w-125px' />
    ),
    accessor: 'organization_name',
  },
  {
    Header: (props) => (
      <PartnerCustomHeader tableProps={props} title='Person in Charge' className='min-w-125px' />
    ),
    id: 'person-in-charger',
    Cell: ({...props}) => (
      <PartnerPersonInChargeCell partnerOrganization={props.data[props.row.index]} />
    ),
  },
  {
    Header: (props) => (
      <PartnerCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <PartnerActionsCell id={props.data[props.row.index].guid!} />,
  },
]

export { partnerOrganizerColumns }

