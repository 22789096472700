import {RESP} from '@/_metronic/helpers/network/network-consts'
import {FC} from 'react'
import {Modal} from 'react-bootstrap'
import {FilterReportTrendAnalysisModalHeader} from './FilterReportTrendAnalysisModalHeader'
import {FilterReportTrendAnalysisForm} from './FilterReportTrendAnalysisForm'

type Props = {
  show: boolean
  closeModal: () => void
}

const FilterReportTrendAnalysisModal: FC<Props> = ({show, closeModal}) => {
  return (
    <>
      <Modal className='modal fade' dialogClassName='modal-xl' show={show} onHide={closeModal}>
        <div className='modal-content'>
          <FilterReportTrendAnalysisModalHeader closeModal={closeModal} />
          <div className='modal-body scroll-y'>
            <FilterReportTrendAnalysisForm closeModal={closeModal} />
          </div>
        </div>
      </Modal>
    </>
  )
}

export {FilterReportTrendAnalysisModal}
